import { type Personnel } from "../missions/api";

export type Props = { personnel: Personnel };

export default function PersonnelLabel({
  personnel: { counts, totalCount },
}: Props) {
  if (!counts?.length) return <>{totalCount}</>;

  const values = ["fuehrer", "unterfuehrer", "helfer"].map(
    (type) => counts?.find((c) => c.type === type)?.count ?? 0,
  );
  const agt = counts?.find((c) => c.type === "agt");

  return (
    <>
      {values.join("/")}/<u>{totalCount}</u>
      {agt ? `, ${agt.count} AGT` : ""}
    </>
  );
}
