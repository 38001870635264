import { Badge, ListItemIcon, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import ListItemLink from "../../components/ListItemLink";
import { useCurrentMission } from "../../missions";
import { Icon, label, route } from "./config";

export default function PatientenMenuItem() {
  const { t } = useTranslation();
  const activeMission = useCurrentMission();
  const active =
    activeMission?.patients?.filter((p) => p.status === "active")?.length ?? 0;

  return (
    <ListItemLink to={activeMission ? `${route}/${activeMission.id}` : route}>
      <ListItemIcon>
        <Badge badgeContent={active} color="secondary">
          <Icon />
        </Badge>
      </ListItemIcon>
      <ListItemText primary={t(label)} />
    </ListItemLink>
  );
}
