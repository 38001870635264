import L from "leaflet";
import "proj4leaflet";

const config: { [id: string]: any } = {
  get "EPSG:25832"() {
    return new (L as any).Proj.CRS(
      "EPSG:25832",
      "+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs",
      new L.Transformation(1, 0, -1, 0),
    );
  },
};

export default config;
