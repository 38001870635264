import {
  Checker,
  array,
  bool,
  number,
  object,
  string,
  stringLiterals,
  voidable,
} from "@recoiljs/refine";
import { useCallback, useMemo } from "react";
import {
  DefaultValue,
  atom,
  selectorFamily,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import { syncEffect } from "recoil-sync";
import { platform } from "../config";
import { databaseStore } from "../db/recoil";
import { trackEvent } from "../tracking";

export const colorSchemeOptions = ["default", "dark", "light"] as const;
export type ColorScheme = (typeof colorSchemeOptions)[number];

export const fontSizeOptions = [
  { id: "small", fontSize: 14 },
  { id: "medium", fontSize: 16 },
  { id: "large", fontSize: 20 },
] as const;
export type FontSize = (typeof fontSizeOptions)[number]["id"];

export const coordinatesDisplayOptions = [
  { id: "wgs-decimal" },
  { id: "wgs-degrees" },
  { id: "utm" },
  { id: "mgrs" },
  { id: "what3words" },
];
export type CoordinatesDisplayType =
  (typeof coordinatesDisplayOptions)[number]["id"];

export const deviceLabelOptions = ["name", "identifier"] as const;
export type DeviceLabelOption = (typeof deviceLabelOptions)[number];

export const resourceListDisplayOptions = ["cards", "table"] as const;
export type ResourceListDisplay = (typeof resourceListDisplayOptions)[number];

export const navigationTypeOptions = ["google-maps", "apple-maps"] as const;
export type NavigationType = (typeof navigationTypeOptions)[number];

export type Settings = {
  readonly colorScheme: ColorScheme;
  readonly fontSize: FontSize;
  readonly playAlarmSound: boolean;
  readonly screensaverEnabled: boolean;
  readonly screensaverTimeout: number;
  readonly coordinatesDisplayTypes: ReadonlyArray<CoordinatesDisplayType>;
  readonly speechVoiceURI?: string;
  readonly speechRate: number;
  readonly announceNewMission: boolean;
  readonly announceNewMissionResource: boolean;
  readonly announceMissionResourceStatusChange: boolean;
  readonly announceNewMissionReport: boolean;
  readonly announceNewMissionPatient: boolean;
  readonly deviceLabel: DeviceLabelOption;
  readonly tableauResourceListDisplay: ResourceListDisplay;
  readonly experiments: ReadonlyArray<string>;
  readonly navigationType: NavigationType;
};

const settingsChecker: Checker<Settings> = object({
  colorScheme: stringLiterals(
    Object.fromEntries(colorSchemeOptions.map((o) => [o, o])),
  ),
  fontSize: stringLiterals(
    Object.fromEntries(fontSizeOptions.map((o) => [o.id, o.id])),
  ),
  playAlarmSound: bool(),
  screensaverEnabled: bool(),
  screensaverTimeout: number(),
  coordinatesDisplayTypes: array(
    stringLiterals(
      Object.fromEntries(coordinatesDisplayOptions.map((o) => [o.id, o.id])),
    ),
  ),
  speechVoiceURI: voidable(string()),
  speechRate: number(),
  announceNewMission: bool(),
  announceNewMissionResource: bool(),
  announceMissionResourceStatusChange: bool(),
  announceNewMissionReport: bool(),
  announceNewMissionPatient: bool(),
  deviceLabel: stringLiterals(
    Object.fromEntries(deviceLabelOptions.map((o) => [o, o])),
  ),
  tableauResourceListDisplay: stringLiterals(
    Object.fromEntries(resourceListDisplayOptions.map((o) => [o, o])),
  ),
  experiments: array(string()),
  navigationType: stringLiterals(
    Object.fromEntries(navigationTypeOptions.map((o) => [o, o])),
  ),
});

type KeysOfValue<T, TCondition> = {
  [K in keyof T]: T[K] extends TCondition ? K : never;
}[keyof T];

export type BooleanSettingKey = NonNullable<KeysOfValue<Settings, boolean>>;

const defaultSettings: Settings = {
  colorScheme: "default",
  fontSize: "medium",
  playAlarmSound: true,
  screensaverEnabled: true,
  screensaverTimeout: 300000,
  coordinatesDisplayTypes: ["wgs-decimal", "wgs-degrees", "utm", "mgrs"],
  speechRate: 1,
  announceNewMission: true,
  announceNewMissionResource: true,
  announceMissionResourceStatusChange: true,
  announceNewMissionReport: true,
  announceNewMissionPatient: true,
  deviceLabel: "name",
  tableauResourceListDisplay: "cards",
  experiments: [],
  navigationType: platform === "ios" ? "apple-maps" : "google-maps",
};

export function getDefaultSetting<K extends keyof Settings>(
  key: K,
): Settings[K] {
  return defaultSettings[key];
}

const settingsAtom = atom<Settings>({
  key: "settings",
  effects: [
    syncEffect({
      refine: settingsChecker,
      storeKey: databaseStore,
      read: async ({ read }) => {
        const settings = await read("config/settings");
        return settings instanceof DefaultValue
          ? defaultSettings
          : { ...defaultSettings, ...(settings as Partial<Settings>) };
      },
    }),
  ],
});

const settingSelector = selectorFamily<any, keyof Settings>({
  key: "settings",
  get:
    (param) =>
    ({ get }) =>
      get(settingsAtom)[param],
  set:
    (param) =>
    ({ set }, value) => {
      set(settingsAtom, (values) => ({
        ...values,
        [param]:
          value instanceof DefaultValue ? getDefaultSetting(param) : value,
      }));
    },
});

/**
 * Get a user setting. Warning, this method will throw if used outside of a Suspense!
 */
export function useSetting<K extends keyof Settings>(key: K): Settings[K] {
  return useRecoilValue(settingSelector(key));
}

/**
 * Get a user setting, falling back to default settings if the user settings have not been initialized yet.
 * It is safe to call this method outside of a Suspense.
 */
export function useSettingOrDefault<K extends keyof Settings>(
  key: K,
): Settings[K] {
  const value = useRecoilValueLoadable(settingSelector(key));
  return useMemo(
    () =>
      value.state === "hasValue" ? value.contents : getDefaultSetting(key),
    [key, value],
  );
}

export function useSetSetting<K extends keyof Settings>(
  key: K,
): (value: Settings[K]) => Promise<void> {
  const update = useUpdateSetting(key);
  return useCallback(async (value) => update(() => value), [update]);
}

export function useUpdateSetting<K extends keyof Settings>(
  key: K,
): (updater: (current: Settings[K]) => Settings[K]) => Promise<void> {
  const set = useSetRecoilState(settingSelector(key));

  return useCallback(
    async (updater) => {
      set((current: Settings[K]) => {
        const updated = updater(current);
        trackEvent(`Setting: ${key}`, {
          category: "Settings",
          label: updated?.toString(),
        });
        return updated;
      });
    },
    [key, set],
  );
}
