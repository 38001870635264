import { FirebaseFirestore } from "@capacitor-firebase/firestore";
import { type DocumentData } from "firebase/firestore";
import { logger } from "../log";

const log = logger("firestore");

export async function onSnapshotWithBackoff<T extends DocumentData>(
  ref: string,
  onSnapshotCallback: (snapshot: { id: string; data: T | null }) => void,
  {
    maxAttempts,
    initialDelay = 1000,
    maxDelay,
  }: { maxAttempts?: number; initialDelay?: number; maxDelay?: number } = {},
): Promise<() => void> {
  let resolved = false;

  const connect = () =>
    new Promise<() => void>(async (resolve, reject) => {
      const callbackId = await FirebaseFirestore.addDocumentSnapshotListener<T>(
        { reference: ref },
        (snapshot, error) => {
          if (error) return reject(error);
          if (snapshot) onSnapshotCallback(snapshot.snapshot);

          if (!resolved) {
            resolved = true;
            resolve(() =>
              FirebaseFirestore.removeSnapshotListener({ callbackId }),
            );
          }
        },
      );
    });

  const start = Date.now();
  let attempts = 1;
  let delay = initialDelay;

  while (true) {
    try {
      const result = await connect();
      log.verbose(
        `Successfully subscribed to Firestore document ${
          ref
        } after ${attempts} attempts and ${Date.now() - start} ms.`,
      );
      return result;
    } catch (error) {
      if (maxAttempts !== undefined && attempts >= maxAttempts) {
        log.warn(
          `Error subscribing to Firestore document ${
            ref
          }. Giving up after ${attempts} attempts and ${Date.now() - start} ms.`,
        );
        throw error;
      }

      log.warn(
        `Error subscribing to Firestore document ${
          ref
        } (${attempts} of ${maxAttempts ?? "unlimited"} attempts so far, ${
          Date.now() - start
        } ms elapsed). Trying again in ${delay} ms.`,
      );
      await wait(delay);
      attempts++;
      delay = maxDelay ? Math.min(delay * 2, maxDelay) : delay * 2;
    }
  }
}

function wait(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
