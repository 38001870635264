import { MyLocation as LocationIcon } from "@mui/icons-material";
import {
  DialogActions,
  DialogContent,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
} from "@mui/material";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { Select, TextField } from "formik-mui";
import { useCallback, useId, useState } from "react";
import { useTranslation } from "react-i18next";
import { number, object, string } from "yup";
import { geocodeAddress, geocodeWhat3words } from "../../data/geocode";
import { useCapitalize } from "../../i18n";
import { useTrackEvent } from "../../tracking";
import Dialog from "../dialog";
import FormActions from "../dialog/FormActions";
import ErrorMessage from "../ErrorMessage";
import WhatThreeWordsLogo from "../WhatThreeWordsLogo";
import { useMapCenter } from "./MapCenter";

const types = ["utm", "mgrs", "wgs", "wgshex", "address", "w3w"] as const;

type HexagesimalValue = {
  degrees?: number;
  minutes?: number;
  seconds?: number;
};

type Values = {
  type: (typeof types)[number];
  utm: { zone?: string; easting?: number; northing?: number };
  mgrs: { zone?: string; box?: string; easting?: number; northing?: number };
  wgs: { latitude?: number; longitude?: number };
  wgshex: { latitude: HexagesimalValue; longitude: HexagesimalValue };
  address: string;
  w3w: string;
};

const initialValues: Values = {
  type: "utm",
  utm: { zone: "32U" },
  mgrs: { zone: "32U" },
  wgs: {},
  wgshex: { latitude: {}, longitude: {} },
  address: "",
  w3w: "",
};

const numberSchema = number().transform(function (value, orig) {
  if (this.isType(value)) return value;
  return parseFloat(orig.replace(",", "."));
});

const hexagesimalSchema = object({
  degrees: numberSchema.required(),
  minutes: numberSchema.required(),
  seconds: numberSchema.required(),
});

const schema = object({
  type: string()
    .required()
    .oneOf(types as unknown as string[]) as any,
  utm: object().when("type", {
    is: (type: string) => type === "utm",
    then: (s) =>
      s.shape({
        zone: string().required(),
        easting: numberSchema.required(),
        northing: numberSchema.required(),
      }),
  }),
  mgrs: object().when("type", {
    is: (type: string) => type === "mgrs",
    then: (s) =>
      s.shape({
        zone: string().required(),
        box: string().required(),
        easting: numberSchema.required(),
        northing: numberSchema.required(),
      }),
  }),
  wgs: object().when("type", {
    is: (type: string) => type === "wgs",
    then: (s) =>
      s.shape({
        latitude: numberSchema.required(),
        longitude: numberSchema.required(),
      }),
  }),
  wgshex: object().when("type", {
    is: (type: string) => type === "wgshex",
    then: (s) =>
      s.shape({
        latitude: hexagesimalSchema.required(),
        longitude: hexagesimalSchema.required(),
      }),
  }),
  address: string().when("type", {
    is: (type: string) => type === "address",
    then: (s) => s.required(),
  }),
  w3w: string().when("type", {
    is: (type: string) => type === "w3w",
    then: (s) => s.required(),
  }),
});

export function MapCenterControl() {
  const { t } = useTranslation("karte");
  const trackEvent = useTrackEvent();
  const [open, setOpen] = useState(false);
  const onOpen = useCallback(() => {
    trackEvent("Open map center dialog");
    setOpen(true);
  }, [trackEvent]);

  return (
    <div className="leaflet-control leaflet-bar">
      <IconButton
        title={t("enter-coordinates.title")}
        onClick={onOpen}
        size="large"
      >
        <LocationIcon />
      </IconButton>
      <MapCenterDialog open={open} onClose={() => setOpen(false)} />
    </div>
  );
}

export function MapCenterDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose(): void;
}) {
  const { t } = useTranslation("karte");
  const { setCenter } = useMapCenter();
  const trackEvent = useTrackEvent();

  const onSubmit = useCallback(
    async (values: Values, { setStatus }: FormikHelpers<Values>) => {
      setStatus(null);

      try {
        const vals = schema.cast(values);
        trackEvent("Set map center", { label: vals.type });
        if (vals.type === "utm") {
          const utm = vals.utm as any;
          setCenter({
            type: "utm",
            zone: utm.zone,
            easting: utm.easting,
            northing: utm.northing,
          });
        } else if (vals.type === "mgrs") {
          const mgrs = vals.mgrs as any;
          setCenter({
            type: "mgrs",
            zone: mgrs.zone,
            box: mgrs.box,
            easting: mgrs.easting,
            northing: mgrs.northing,
          });
        } else if (vals.type === "wgs") {
          const wgs = vals.wgs as any;
          setCenter({
            type: "wgs",
            latitude: wgs.latitude,
            longitude: wgs.longitude,
          });
        } else if (vals.type === "wgshex") {
          const wgs = vals.wgshex as any;
          setCenter({
            type: "wgs",
            latitude: parseHexagesimal(wgs.latitude),
            longitude: parseHexagesimal(wgs.longitude),
          });
        } else if (vals.type === "address") {
          const result = await geocodeAddress({ address: values.address });
          setCenter({ type: "manual", ...result.location });
        } else if (vals.type === "w3w") {
          const result = await geocodeWhat3words({ words: values.w3w });
          setCenter({ type: "manual", ...result.location });
        }

        onClose();
      } catch (e) {
        console.error("Error setting map center:", e);
        setStatus(e);
      }
    },
    [trackEvent, setCenter, onClose],
  );

  const formId = useId();

  return open ? (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      isInitialValid={false}
      onSubmit={onSubmit}
    >
      {({ values, status }) => (
        <Dialog
          title={t("enter-coordinates.title")}
          open={open}
          onClose={onClose}
        >
          <DialogContent>
            <Form id={formId} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    name="type"
                    component={Select}
                    fullWidth
                    margin="normal"
                    label={t("type")}
                    required
                  >
                    {types.map((type) => (
                      <MenuItem key={type} value={type}>
                        {t(`coordinates.${type}.label`)}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                {values.type === "utm" && <UTMForm />}
                {values.type === "mgrs" && <MGRSForm />}
                {values.type === "wgs" && <WGSForm />}
                {values.type === "wgshex" && <WGSHexForm />}
                {values.type === "address" && <AddressForm />}
                {values.type === "w3w" && <W3WForm />}
              </Grid>
              {status && <ErrorMessage error={status} />}
            </Form>
          </DialogContent>
          <DialogActions>
            <FormActions
              form={formId}
              onClose={onClose}
              submitLabel={t("enter-coordinates.submit")}
            />
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  ) : null;
}

function UTMForm() {
  const { t } = useTranslation("karte");

  return (
    <>
      <Grid item xs={12} sm={2}>
        <Field
          name="utm.zone"
          component={TextField}
          fullWidth
          margin="normal"
          label={t("zone")}
          required
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <Field
          name="utm.easting"
          component={TextField}
          fullWidth
          margin="normal"
          label={t("easting")}
          inputMode="numeric"
          required
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <Field
          name="utm.northing"
          component={TextField}
          fullWidth
          margin="normal"
          label={t("northing")}
          inputMode="numeric"
          required
        />
      </Grid>
    </>
  );
}

function MGRSForm() {
  const { t } = useTranslation("karte");

  return (
    <>
      <Grid item xs={6} sm={2}>
        <Field
          name="mgrs.zone"
          component={TextField}
          fullWidth
          margin="normal"
          label={t("zone")}
          required
        />
      </Grid>
      <Grid item xs={6} sm={2}>
        <Field
          name="mgrs.box"
          component={TextField}
          fullWidth
          margin="normal"
          label={t("coordinates.mgrs.square")}
          required
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Field
          name="mgrs.easting"
          component={TextField}
          fullWidth
          margin="normal"
          label={t("easting")}
          inputMode="numeric"
          required
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Field
          name="mgrs.northing"
          component={TextField}
          fullWidth
          margin="normal"
          label={t("northing")}
          inputMode="numeric"
          required
        />
      </Grid>
    </>
  );
}

function WGSForm() {
  const { t } = useTranslation("karte");

  return (
    <>
      <Grid item xs={6}>
        <Field
          name="wgs.latitude"
          component={TextField}
          fullWidth
          margin="normal"
          label={t("latitude")}
          inputMode="numeric"
          required
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          name="wgs.longitude"
          component={TextField}
          fullWidth
          margin="normal"
          label={t("longitude")}
          inputMode="numeric"
          required
        />
      </Grid>
    </>
  );
}

function WGSHexForm() {
  const { t } = useTranslation("karte");

  return (
    <>
      <Grid item xs={12}>
        <FormLabel>{t("latitude")}</FormLabel>
      </Grid>
      <Grid item xs={12}>
        <HexagesimalForm name="wgshex.latitude" />
      </Grid>
      <Grid item xs={12}>
        <FormLabel>{t("longitude")}</FormLabel>
      </Grid>
      <Grid item xs={12}>
        <HexagesimalForm name="wgshex.longitude" />
      </Grid>
    </>
  );
}

function HexagesimalForm({ name }: { name: string }) {
  const { t } = useTranslation("karte");
  const capitalize = useCapitalize();

  return (
    <Stack direction="row" spacing={2}>
      <Field
        name={`${name}.degrees`}
        component={TextField}
        fullWidth
        label={capitalize(t("degree_other"))}
        inputMode="numeric"
        required
      />
      <Field
        name={`${name}.minutes`}
        component={TextField}
        fullWidth
        label={capitalize(t("minute_other"))}
        inputMode="numeric"
        required
      />
      <Field
        name={`${name}.seconds`}
        component={TextField}
        fullWidth
        label={capitalize(t("second_other"))}
        inputMode="numeric"
        required
      />
    </Stack>
  );
}

function AddressForm() {
  const { t } = useTranslation("karte");

  return (
    <Grid item xs={12}>
      <Field
        name="address"
        component={TextField}
        fullWidth
        margin="normal"
        label={t("address")}
        required
      />
    </Grid>
  );
}

function W3WForm() {
  const { t } = useTranslation("karte");

  return (
    <Grid item xs={12}>
      <Field
        name="w3w"
        component={TextField}
        fullWidth
        margin="normal"
        label={t("w3w-address")}
        required
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <WhatThreeWordsLogo />
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
}

function parseHexagesimal({
  degrees = 0,
  minutes = 0,
  seconds = 0,
}: HexagesimalValue): number {
  return degrees + minutes / 60 + seconds / 3600;
}
