import { atom, useRecoilValue } from "recoil";

const nowAtom = atom({
  key: "now",
  default: new Date(),
  effects: [
    ({ setSelf }) => {
      const timer = setInterval(() => setSelf(new Date()), 1000);
      return () => clearInterval(timer);
    },
  ],
});

export function useNow() {
  return useRecoilValue(nowAtom);
}
