import {
  array,
  bool,
  mixed,
  number,
  object,
  or,
  string,
  stringLiterals,
  tuple,
  voidable,
  type Checker,
} from "@recoiljs/refine";
import { useCallback } from "react";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { syncEffect } from "recoil-sync";
import { databaseStore } from "./db/recoil";
import type { Koordinaten, Point, Polygon } from "./types";

export type Einheit = {
  readonly id: string;
  readonly name: string;
};

const einheitChecker: Checker<Einheit> = object({
  id: string(),
  name: string(),
});

export type Untereinheit = {
  readonly id: string;
  readonly name: string;
};

const untereinheitChecker: Checker<Untereinheit> = object({
  id: string(),
  name: string(),
});

export type Geraet = {
  readonly id: string;
  readonly name: string;
  readonly positionName: string;
  readonly untereinheit?: Untereinheit;
  readonly einheit: Einheit;
  readonly homebase?: Koordinaten;
};

const koordinatenChecker: Checker<Koordinaten> = object({
  latitude: number(),
  longitude: number(),
});

export const moduleOptions = [
  "dashboard",
  "checklisten",
  "gefahrgut",
  "gefahrgutlight",
  "rettungskarten",
  "kartenmaterial",
  "navigation",
  "kontakte",
  "dokumente",
  "warnkarte",
  "extnavigation",
  "verzeichnis",
  "formulare",
  "patienten",
  "atemschutz",
  "info",
  "meldungen",
  "meldungenadmin",
  "statistics",
  "whiteboard",
] as const;

export type Modul = (typeof moduleOptions)[number];

const modulChecker = string() as Checker<Modul>;

export type MapLayerProviderData = {
  readonly id: string;
  readonly label: string;
};

export type HTTPAuth =
  | { type: "basic"; username: string; password: string }
  | { type: "bearerToken"; token: string };

export type WMSMapLayerProvider = MapLayerProviderData & {
  readonly type: "wms";
  readonly url: string;
  readonly auth?: HTTPAuth;
  readonly crs?: string;
  readonly layers: ReadonlyArray<WMSMapLayer>;
};

const wmsMapLayerProviderChecker = mixed() as Checker<WMSMapLayerProvider>;

export type WMSMapLayer = {
  readonly id: string;
  readonly label: string;
  readonly layer: string;
  readonly opacity?: number;
  readonly attribution?: string;
};

export type ShapeMapLayerProvider = MapLayerProviderData & {
  readonly type: "shape";
  readonly layers: ReadonlyArray<ShapeMapLayer>;
};

export type ShapeMapLayer = {
  readonly id: string;
  readonly label: string;
  readonly url: string;
  readonly color?: string;
  readonly opacity?: number;
  readonly attribution?: string;
};
const shapeMapLayerProviderChecker = mixed() as Checker<ShapeMapLayerProvider>;

export type MapLayerProvider = WMSMapLayerProvider | ShapeMapLayerProvider;

const mapLayerProviderChecker: Checker<MapLayerProvider> = or(
  wmsMapLayerProviderChecker,
  shapeMapLayerProviderChecker,
);

export type ExternalPage = {
  readonly label: string;
  readonly url: string;
  readonly target?: "app" | "external";
  readonly showInMenu?: boolean;
};

const externalPageChecker: Checker<ExternalPage> = object({
  label: string(),
  url: string(),
  target: voidable(
    stringLiterals({ app: "app", external: "external" } as const),
  ),
  showInMenu: voidable(bool()),
});

export type LicenseData = Geraet & {
  readonly portalKey: string;
  readonly alarmKey: string;
  readonly missionApiKey?: string;
  readonly fireboardKey?: string;
  readonly module: ReadonlyArray<Modul>;
  readonly warnkarte: ReadonlyArray<string>;
  readonly gueltigkeit?: number;
  readonly positionSenden: boolean;
  readonly positionSehen: boolean;
  readonly freeversion: boolean;
  readonly demo: boolean;
  readonly offlineArea?: Polygon;
  readonly mapLayers?: ReadonlyArray<MapLayerProvider>;
  readonly features?: ReadonlyArray<string>;
  readonly externalPages?: ReadonlyArray<ExternalPage>;
};

const pointChecker: Checker<Point> = tuple(
  or(number(), string()),
  or(number(), string()),
);
const polygonChecker: Checker<Polygon> = array(pointChecker);

const licenseChecker: Checker<LicenseData> = object({
  id: string(),
  name: string(),
  positionName: string(),
  untereinheit: voidable(untereinheitChecker),
  einheit: einheitChecker,
  homebase: voidable(koordinatenChecker),
  portalKey: string(),
  alarmKey: string(),
  missionApiKey: voidable(string()),
  fireboardKey: voidable(string()),
  module: array(modulChecker),
  warnkarte: array(string()),
  gueltigkeit: voidable(number()),
  positionSenden: bool(),
  positionSehen: bool(),
  freeversion: bool(),
  demo: bool(),
  offlineArea: voidable(polygonChecker),
  mapLayers: voidable(array(mapLayerProviderChecker)),
  features: voidable(array(string())),
  externalPages: voidable(array(externalPageChecker)),
});

export const licenseAtom = atom<LicenseData | undefined>({
  key: "license",
  default: undefined,
  effects: [
    syncEffect({
      refine: voidable(licenseChecker),
      storeKey: databaseStore,
      itemKey: "config/license",
    }),
  ],
});

export function useLicense(): LicenseData | undefined {
  return useRecoilValue(licenseAtom);
}

export function useSetLicense(): (license?: LicenseData) => Promise<void> {
  const setLicense = useSetRecoilState(licenseAtom);
  return useCallback(async (license) => setLicense(license), [setLicense]);
}
