import { type AtomEffect } from "recoil";

export type Arguments<T> = [T] extends [(...args: infer U) => any]
  ? U
  : [T] extends [void]
    ? []
    : [T];

export type AsyncEffect<T> = (
  ...param: Arguments<AtomEffect<T>>
) => Promise<void | (() => void)>;

export function asyncEffect<T>(effect: AsyncEffect<T>): AtomEffect<T> {
  return (...param) => {
    const cleanups: Array<() => void> = [];

    effect(...param)
      .then((cleanup) => cleanup && cleanups.push(cleanup))
      .catch((error) =>
        console.error("Uncaught error in async effect:", error),
      );

    return () => {
      if (cleanups.length) {
        cleanups.forEach((c) => c());
      }
    };
  };
}
