export const alarmSound = "/alarm.798a570.mp3";
export const pdfWorker = "/pdf.worker.1001f17.mjs";
export const atemschutzAlarmSound = "/eieruhr.66cef4e.mp3";
export const shapefileUrl = "/shapefile.44316d9.js";
export const resetPage = "/reset.1af3d5e.html";

export const heroImages = [
  { type: "image/avif", src: "/hero.0b37a27.avif" },
  { type: "image/webp", src: "/hero.157f025.webp" },
];
