import { FirebaseFirestore } from "@capacitor-firebase/firestore";
import { useCallback, useMemo } from "react";
import {
  atom,
  useRecoilCallback,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { firebaseContextAtom, type FirebaseContext } from "../firebase";
import { asyncEffect } from "../lib/recoil";
import type {
  Action,
  MapElement,
  Mission,
  Patient,
  Report,
  Resource,
  Section,
  UserMissionGroup,
} from "./api";
import { createMissionsApi } from "./client";
import { log, statePrefix } from "./config";
import { type ManagedMission } from "./manager";
import { MissionsClient } from "./missions";
import type {
  FullMission,
  SectionResources,
  SectionWithResources,
} from "./types";

export const missionIdsAtom = atom<ReadonlyArray<string>>({
  key: `${statePrefix}:missionIds`,
  effects: [
    asyncEffect(async ({ getPromise, setSelf }) => {
      log.info("Subscribing to user missions");
      const context = await getPromise(firebaseContextAtom);

      const callbackId = await FirebaseFirestore.addDocumentSnapshotListener(
        { reference: `users/${context.user.id}` },
        (snapshot, error) => {
          if (error) {
            log.error(`Error subscribing to user missions: ${error}`);
          }

          setSelf(
            Object.keys(snapshot?.snapshot.data?.missions ?? {}).toSorted(),
          );
        },
      );

      return () => {
        FirebaseFirestore.removeSnapshotListener({ callbackId });
      };
    }),
  ],
});

export const missionsAtom = atom<ReadonlyArray<ManagedMission>>({
  key: `${statePrefix}:missions`,
  default: [],
});

export function useMissionsClient(): MissionsClient {
  const { apiKey } = useRecoilValue(firebaseContextAtom);
  return useMemo(() => new MissionsClient(createMissionsApi(apiKey)), [apiKey]);
}

export function useMissionGroups(): ReadonlyArray<UserMissionGroup> {
  const { user } = useRecoilValue(firebaseContextAtom);
  return user.groups ?? [];
}

export function useAddMission() {
  const context = useRecoilValue(firebaseContextAtom);

  return useRecoilCallback(
    ({ set }) =>
      ({ id }: any) => {
        if (!context) throw new Error("Mission context not initialized");
        set(missionIdsAtom, (existing) => [...existing, id]);
      },
    [context],
  );
}

export function useRemoveMission(): (id: string) => void {
  return useRecoilCallback(
    ({ set, reset }) =>
      (id) => {
        set(missionIdsAtom, (existing) => existing.filter((m) => m !== id));
      },
    [],
  );
}

export function useUpdateMissionById(): (
  missionId: string,
  updater: (mission: Mission) => Mission,
) => void {
  const toMission = useToMission();
  const setMissions = useSetRecoilState(missionsAtom);

  return useCallback(
    (missionId, updater) => {
      setMissions((missions) =>
        missions.map((m) =>
          m.id === missionId
            ? {
                id: missionId,
                state: "ready",
                mission: toMission(updater((m as any).mission)),
              }
            : m,
        ),
      );
    },
    [setMissions, toMission],
  );
}

export type FirebaseMissionData = Omit<
  Mission,
  | "id"
  | "resources"
  | "actions"
  | "reports"
  | "mapElements"
  | "sections"
  | "patients"
> & {
  resources?: Record<string, Omit<Resource, "id">>;
  actions?: Record<string, Omit<Action, "id">>;
  reports?: Record<string, Omit<Report, "id">>;
  mapElements?: Record<string, Omit<MapElement, "id">>;
  patients?: Record<string, Omit<Patient, "id">>;
  sections?: Record<string, Omit<Section, "id">>;
  patientIds?: Array<string>;
};

export function fromFirebaseMission(
  context: FirebaseContext,
  id: string,
  data: FirebaseMissionData,
  stale?: boolean,
): FullMission {
  return toMission(
    context,
    {
      ...data,
      id,
      resources: Object.entries(data.resources ?? {}).map(
        ([id, resource]: [string, any]) => ({ ...resource, id }),
      ),
      actions: Object.entries(data.actions ?? {}).map(
        ([id, action]: [string, any]) => ({ ...action, id }),
      ),
      reports: Object.entries(data.reports ?? {}).map(
        ([id, report]: [string, any]) => ({ ...report, id }),
      ),
      patients: Object.entries(data.patients ?? {}).map(
        ([id, report]: [string, any]) => ({ ...report, id }),
      ),
      mapElements: Object.entries(data.mapElements ?? {}).map(
        ([id, element]: [string, any]) => ({ id, ...element }),
      ),
      sections: Object.entries(data.sections ?? {}).map(
        ([id, element]: [string, any]) => ({ id, ...element }),
      ),
    },
    stale,
  );
}

function useToMission(): (data: Mission, stale?: boolean) => FullMission {
  const context = useRecoilValue(firebaseContextAtom);

  return useCallback(
    (data, stale = false) => {
      if (!context) throw new Error("Mission context not initialized!");
      return toMission(context, data, stale);
    },
    [context],
  );
}

function toMission(
  context: FirebaseContext,
  data: Mission,
  stale = false,
): FullMission {
  const editable = data.groupIds?.some((groupId) =>
    context.user.groups.some(
      (g) => g.groupId === groupId && g.permissions.includes("update"),
    ),
  );

  const mission: FullMission = {
    ...data,
    editable: editable && data.editable,
    stale,
    sections: [],
  };

  mission.sections = data.sections.map((section) =>
    createSection(section, mission),
  );

  return mission;
}

function createSection(
  section: Omit<Section, "resources">,
  { resources, actions, reports }: SectionResources,
): SectionWithResources {
  return {
    ...section,
    resources: resources.filter((e) => e.sections?.includes(section.id)),
    actions: actions.filter((e) => e.sections?.includes(section.id)),
    reports: reports.filter((e) => e.sections?.includes(section.id)),
  };
}
