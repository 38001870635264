import { LatLngExpression } from "leaflet";
import { useCallback } from "react";
import { useMapBounds } from "./MapBounds";

export function useMapBoundsFilter<T>(
  getCandidates: (obj: T) => Array<LatLngExpression>,
): (objekt: T) => boolean {
  const bounds = useMapBounds()?.pad(0.5);

  return useCallback(
    (objekt) => {
      if (!bounds) return true;
      const candidates = getCandidates(objekt);
      return (
        candidates.length === 0 ||
        candidates.some((coords) => bounds.contains(coords))
      );
    },
    [bounds, getCandidates],
  );
}
