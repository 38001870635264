import { ListItemIcon, ListItemText } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import ListItemLink from "./ListItemLink";

export type Props = {
  route: string;
  label: string;
  icon: typeof SvgIcon;
};

export default function SimpleMenuItem({ route, label, icon: Icon }: Props) {
  return (
    <ListItemLink to={route}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItemLink>
  );
}
