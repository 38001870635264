import { useEffect, useRef } from "react";
import {
  getNextExecutionTimestamp,
  TaskObserver,
  useRegisterTask,
} from "../../../background";
import { enablePrecache } from "../../../config";
import { precacheResources } from "../../../db/resource";
import { id, label } from "../config";
import { useDokumente } from "../data";
import { Document, File } from "../types";

export function DokumentePrecache() {
  const dokumente = useRef(useDokumente());
  const registerTask = useRegisterTask();

  useEffect(() => {
    registerTask({
      id,
      label,
      getNextExecutionTimestamp: getNextExecutionTimestamp(
        24 * 60 * 60 * 1000,
        enablePrecache,
      ),
      execute: (observer: TaskObserver) =>
        precacheDokumente(dokumente.current, observer),
    });
  }, [registerTask]);

  return null;
}

async function precacheDokumente(dokumente: File[], observer: TaskObserver) {
  const urls = dokumente
    .filter((d): d is Document => d.type === "file")
    .map((doc) => doc.url);
  await precacheResources(urls, observer);
}
