import {
  Add as AddIcon,
  KeyboardArrowDown as CaretIcon,
  Share,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  CardContent,
  Chip,
  DialogActions,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Skeleton,
  Stack,
} from "@mui/material";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import MissionForm from "../components/MissionForm";
import { useCapitalize } from "../i18n";
import {
  type ManagedMission,
  useActiveMission,
  useMission,
  useMissionGroups,
  useMissions,
  useSetActiveMission,
} from "../missions";
import { missionComparator } from "../missions/utils";
import { id as tableauId } from "../modules/tableau/config";
import CloseButton from "./CloseButton";
import ListItemLink from "./ListItemLink";
import { useMissionsRoute, useModule } from "./ModuleContext";
import Timestamp from "./Timestamp";
import Dialog from "./dialog";

export default function MissionSelectorTitle() {
  const activeMission = useActiveMission();
  const mission = useMission(activeMission?.missionId);

  return <MissionSelector mission={mission} />;
}

function MissionSelector({
  mission,
}: {
  mission?: ManagedMission | undefined;
}) {
  const { t } = useTranslation(tableauId);
  const popupState = usePopupState({
    variant: "popover",
    popupId: "mission-selector",
  });

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          {...bindTrigger(popupState)}
        >
          {mission ? (
            <ActiveMissionLabel mission={mission} />
          ) : (
            <span>{t("no-active-mission")}</span>
          )}
          <CaretIcon />
        </Box>
        <CreateMissionButton />
      </Box>
      {popupState.isOpen && (
        <MissionSelectorDialog {...bindPopover(popupState)} />
      )}
    </>
  );
}

function ActiveMissionLabel({ mission }: { mission: ManagedMission }) {
  return (
    <>
      {mission?.state === "ready" ? (
        <span>
          {[mission.mission.keyword, mission.mission.message]
            .filter(Boolean)
            .join(" ")}
        </span>
      ) : (
        <Skeleton />
      )}
    </>
  );
}

function CreateMissionButton() {
  const { t } = useTranslation(tableauId);
  const capitalize = useCapitalize();
  const prefix = useMissionsRoute();
  const popupState = usePopupState({
    variant: "popover",
    popupId: "create-mission",
  });
  const navigate = useNavigate();
  const canCreate = useMissionGroups().some((g) =>
    g.permissions.includes("create"),
  );

  return canCreate ? (
    <>
      <IconButton
        color="inherit"
        title={capitalize(t("create-mission.title"))}
        {...bindTrigger(popupState)}
      >
        <AddIcon />
      </IconButton>
      {popupState.isOpen && (
        <MissionForm
          open={popupState.isOpen}
          handleClose={popupState.close}
          handleSubmit={(m) => navigate(`${prefix}/${m.id}`)}
        />
      )}
    </>
  ) : null;
}

function MissionSelectorDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation(tableauId);

  return (
    <Dialog title={t("select.title")} open={open} onClose={onClose}>
      <MissionSelectorContent onClose={onClose} />
      <DialogActions>
        <CloseButton intent="cancel" onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
}

function MissionSelectorContent({ onClose }: { onClose: () => void }) {
  const missions = useMissions();
  const { t } = useTranslation(tableauId);

  if (!Object.keys(missions).length) {
    return (
      <CardContent>
        <Alert severity="info">{t("no-active-mission")}</Alert>
      </CardContent>
    );
  }

  return (
    <List sx={{ overflowY: "auto", flex: "1 1 auto" }}>
      {Object.values(missions)
        .toSorted(missionComparator)
        .map((mission) => (
          <MissionItem key={mission.id} mission={mission} onClose={onClose} />
        ))}
    </List>
  );
}

function MissionItem({
  mission,
  onClose,
}: {
  mission: ManagedMission;
  onClose: () => void;
}) {
  const selected = mission.id === useActiveMission()?.missionId;
  const setActiveMission = useSetActiveMission();

  const content = (
    <ListItemText
      primary={
        mission.state === "ready" ? (
          <Stack spacing={1} direction="row" alignItems="baseline">
            {mission.mission.keyword && (
              <Chip label={mission.mission.keyword} color="secondary" />
            )}
            <span>{mission.mission.message}</span>
            {mission.mission.shared === true && (
              <Share sx={{ fontSize: "1em" }} />
            )}
          </Stack>
        ) : (
          <Skeleton />
        )
      }
      secondary={
        mission.state === "ready" ? (
          <>
            {mission.mission.address}
            {mission.mission.address && <br />}
            <Timestamp target={mission.mission.createdAt} />
          </>
        ) : (
          <>
            <Skeleton />
            <Skeleton />
          </>
        )
      }
    />
  );

  const module = useModule();

  const selectMission = useCallback(() => {
    setActiveMission({ missionId: mission.id });
    onClose();
  }, [mission.id, onClose, setActiveMission]);

  return module?.supportsMissions ? (
    <ListItemLink
      selected={selected}
      divider
      to={`${module?.route}/${mission.id}`}
      onClick={onClose}
    >
      {content}
    </ListItemLink>
  ) : (
    <ListItemButton divider selected={selected} onClick={selectMission}>
      {content}
    </ListItemButton>
  );
}
