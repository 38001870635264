import { Badge, ListItemIcon, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useModulEnabled } from "../../auth";
import ListItemLink from "../../components/ListItemLink";
import { Icon, label, route } from "./config";
import { useMeldungen } from "./data";

export default function MeldungenMenuItem() {
  const enabled = useModulEnabled("meldungenadmin");
  const meldungen = useMeldungen().length;
  const { t } = useTranslation();

  return enabled ? (
    <ListItemLink to={route}>
      <ListItemIcon>
        <Badge badgeContent={meldungen} color="secondary">
          <Icon />
        </Badge>
      </ListItemIcon>
      <ListItemText primary={t(label)} />
    </ListItemLink>
  ) : null;
}
