import { route } from "./config";

export function einsatzPath(einsatzId: string) {
  return `${route}/e/${einsatzId}`;
}

export function truppPath(einsatzId: string, truppId?: string) {
  return truppId
    ? `${einsatzPath(einsatzId)}/t/${truppId}`
    : einsatzPath(einsatzId);
}
