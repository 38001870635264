import { useTranslation } from "react-i18next";
import { useDuration, useSameDay } from "../utils";

export type Props = {
  target: string | Date | number;
  skipPrefix?: boolean;
  skipDate?: boolean;
  lineBreak?: boolean;
};

export default function Timestamp({
  target,
  skipPrefix,
  skipDate,
  lineBreak,
}: Props) {
  const { t } = useTranslation("duration");
  const timestamp = toNumber(target);
  const duration = useDuration(timestamp);
  const sameDay = useSameDay(timestamp);

  const parts = [];
  if (skipPrefix) {
    parts.push(duration);
  } else {
    parts.push(
      timestamp > Date.now() ? t("in", { duration }) : t("ago", { duration }),
    );
  }

  if (!skipDate) {
    parts.push(
      sameDay
        ? new Date(timestamp).toLocaleTimeString()
        : new Date(timestamp).toLocaleString(),
    );
  }

  return lineBreak ? (
    <>
      {parts[0]}{" "}
      {parts.length > 1 ? (
        <>
          <br />
          {parts[1]}
        </>
      ) : null}
    </>
  ) : (
    <>
      {parts[0]} {parts.length > 1 ? <>({parts[1]})</> : null}
    </>
  );
}

function toNumber(target: string | Date | number) {
  if (typeof target === "string") return new Date(target).getTime();
  if (target instanceof Date) return target.getTime();
  return target;
}
