import { useMemo, type ReactNode } from "react";
import { useAuthenticated } from "../auth";
import { DbProvider } from "../db";
import { useHydranten } from "../db/hydranten";
import { useKartenDetailobjekte } from "../db/kartendetailobjekte";
import { useKartenObjekte } from "../db/kartenobjekte";
import { Geraet } from "../license";
import { DokumentePrecache } from "../modules/dokumente/routes/dokumente-precache";
import { RettungskartenPrecache } from "../modules/rettungskarten/routes/rettungskarten-precache";
import { LocationProvider } from "./location";
import LocationSubmitter from "./location-submitter";
import { LocationsProvider } from "./locations";
import { MapTilePrecache } from "./map-precache";

export * from "./geocode";
export * from "./infos";
export type { InfoAction, InfoData, InfoLevel } from "./infos";
export {
  currentLocationSelector,
  useCurrentLocation,
  useLocation,
} from "./location";
export * from "./settings";
export * from "./speech";
export { useWetter } from "./wetter";

export function DataProvider({ children }: { children: ReactNode }) {
  return (
    <DbProvider>
      <LocationProvider />
      <MapTilePrecache />
      <DokumentePrecache />
      <RettungskartenPrecache />
      <LocationSubmitter />
      <LocationsProvider />
      {children}
    </DbProvider>
  );
}

export function useGeraet(): Geraet {
  return useAuthenticated();
}

export function useKartenobjekte() {
  const hydranten = useHydranten();
  const kartenobjekte = useKartenObjekte();
  const kartendetailobjekte = useKartenDetailobjekte();

  return useMemo(
    () => [...hydranten, ...kartenobjekte, ...kartendetailobjekte],
    [hydranten, kartenobjekte, kartendetailobjekte],
  );
}
