import { type LatLngBounds } from "leaflet";
import {
  createContext,
  useContext,
  useEffect,
  useState,
  type ReactNode,
} from "react";
import { useMap } from "react-leaflet";

const Context = createContext<LatLngBounds | undefined>(undefined);

export function MapBoundsProvider({ children }: { children: ReactNode }) {
  const map = useMap();
  const [bounds, setBounds] = useState<LatLngBounds>();

  useEffect(() => {
    if (!map) return;
    const handler = () => setBounds(map.getBounds());

    map.whenReady(handler);
    map.on("dragend moveend zoomend", handler);

    return () => {
      map.off("dragend moveend zoomend", handler);
    };
  }, [map, setBounds]);

  return <Context.Provider value={bounds}>{children}</Context.Provider>;
}

export function useMapBounds() {
  return useContext(Context);
}
