import { Box } from "@mui/material";

export default function WhatThreeWordsLogo(props: any) {
  return (
    <Box
      component="svg"
      viewBox="0 0 32 32"
      {...props}
      sx={{ width: "1em", height: "1em" }}
    >
      <path
        fill="currentColor"
        d="M10.7 4h2L4 28H2l8.7-24zm9 0h2L13 28h-2l8.7-24zm9 0h2L22 28h-2l8.7-24z"
      />
    </Box>
  );
}
