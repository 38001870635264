import { lazy, useMemo } from "react";
import { type RouteObject } from "react-router";
import ModuleContextProvider from "../../../components/ModuleContext";
import type { ModuleInfo } from "../../types";
import { route } from "../config";

const Whiteboard = lazy(() => import("./Whiteboard"));

export function useWhiteboardRoutes(module: ModuleInfo): Array<RouteObject> {
  return useMemo(
    () => [
      {
        path: route,
        element: <ModuleContextProvider module={module} />,
        children: [
          { index: true, element: <Whiteboard /> },
          { path: ":boardId", element: <Whiteboard /> },
        ],
      },
    ],
    [module],
  );
}
