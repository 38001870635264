import { useCallback } from "react";
import {
  atom,
  RecoilState,
  selector,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { portalServer } from "../../config";
import { FetchProvider, providerValueAtom, useData } from "../../data-provider";
import { daily, DataProvider, StorageCache } from "../../db/provider";
import { activeMissionAtom } from "../../missions";
import { id, label } from "./config";
import type {
  RettungskarteHersteller,
  RettungskarteKategorie,
  RettungskarteModell,
} from "./types";

const dataProvider: DataProvider<RettungskarteKategorie[]> = ({
  demo,
  portalKey,
}) => ({
  id,
  label,
  provider: new FetchProvider({
    url: `${portalServer}/get-rescuecardsv2.php?ORG_ACCESSKEY=${encodeURIComponent(
      portalKey,
    )}`,
    transform: (data: any) => {
      const kategorien: RettungskarteKategorie[] = (data.kategorien || []).map(
        (k: any) => ({ ...k, id: k.name }),
      );
      return demo
        ? kategorien
            .slice(0, 1)
            .map((k) => ({ ...k, hersteller: k.hersteller.slice(0, 3) }))
        : kategorien;
    },
  }),
  cache: new StorageCache(id),
  getStatistics: async (value) => ({
    count: value
      .flatMap((k) => k.hersteller)
      .reduce((a, h) => a + h.modelle.length, 0),
    totalSize: JSON.stringify(value).length,
  }),
  updateInterval: daily,
});

export const dataProviders = [dataProvider];

export const rettungskartenAtom = providerValueAtom(id) as RecoilState<
  RettungskarteKategorie[]
>;

export const rettungskartenSucheAtom = atom<string>({
  key: "rettungskarten:suche",
  default: "",
});

const aktiveRettungskarteAtom = atom<
  Record<string, { herstellerId: string; modellId: string } | undefined>
>({
  key: "rettungskarten:aktiv",
  default: {},
});

const aktiveRettungskarteSelector = selector<
  | { hersteller: RettungskarteHersteller; modell: RettungskarteModell }
  | undefined
>({
  key: "rettungskarten:aktiv:selector",
  get: ({ get }) => {
    const missionId = get(activeMissionAtom)?.missionId ?? "_none_";
    const active = get(aktiveRettungskarteAtom)[missionId];
    if (!active) return;

    const hersteller = get(rettungskartenAtom)
      .flatMap((k) => k.hersteller)
      .find((h) => h.id === active.herstellerId);
    if (!hersteller) return;

    const modell = hersteller.modelle.find((m) => m.id === active.modellId);
    if (!modell) return;

    return { hersteller, modell };
  },
});

export function useAktiveRettungskarte() {
  return useRecoilValue(aktiveRettungskarteSelector);
}

export function useSetAktiveRettungskarte() {
  const missionId = useRecoilValue(activeMissionAtom)?.missionId ?? "_none_";
  const set = useSetRecoilState(aktiveRettungskarteAtom);

  return useCallback(
    (value?: { herstellerId: string; modellId: string }) =>
      set((s) => ({ ...s, [missionId]: value })),
    [set, missionId],
  );
}

export function useRettungskarten() {
  return useData<RettungskarteKategorie[]>(id, []);
}
