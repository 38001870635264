import get from "lodash/get";
import { nanoid } from "nanoid";
import { useCallback } from "react";
import { RecoilState, useSetRecoilState } from "recoil";
import { useAuthenticated } from "../../auth";
import { portalServer } from "../../config";
import { useGeraet } from "../../data";
import {
  DataProvider as Provider,
  providerValueAtom,
} from "../../data-provider";
import { DataProvider, storage } from "../../db/provider";
import {
  FullMission,
  useCurrentMission,
  useMissionsClient,
} from "../../missions";
import { FeldData, Formular, FormularData, FormularFeld } from "./types";

const id = "formular-data";

async function readStorage(): Promise<FormularData[]> {
  const data = await (await storage).readData(id);
  return typeof data === "string" ? JSON.parse(data) : [];
}

async function writeStorage(data: FormularData[]) {
  await (await storage).writeData(id, JSON.stringify(data));
}

export const formularDataDataProvider: DataProvider<FormularData[]> = () => {
  const provider: Provider<FormularData[]> = {
    getData: readStorage,
  };

  return {
    id,
    label: "formulare:label-data",
    provider,
    getStatistics: async (value) => ({
      count: value.length,
      totalSize: JSON.stringify(value).length,
    }),
  };
};

export const formularDataAtom = providerValueAtom(id) as RecoilState<
  FormularData[]
>;

export function useCreateFormular(): (
  formular: Formular,
) => Promise<FormularData> {
  const mission = useCurrentMission();
  const setFormularData = useSetRecoilState(formularDataAtom);

  return useCallback(
    async (formular) => {
      const data: FormularData = {
        id: nanoid(),
        formularId: formular.id,
        formularName: formular.title,
        email: formular.email,
        values: createDefaultValues(formular, mission),
        status: "draft",
        createdAt: Date.now(),
        updatedAt: Date.now(),
      };

      const datas = await readStorage();
      await writeStorage([...datas, data]);
      setFormularData([...datas, data]);

      return data;
    },
    [mission, setFormularData],
  );
}

export function useDeleteFormular() {
  const setFormularData = useSetRecoilState(formularDataAtom);

  return useCallback(
    async (...ids: string[]) => {
      const datas = await readStorage();
      const updated = datas.filter((c) => !ids.includes(c.id));
      await writeStorage(updated);
      setFormularData(updated);
    },
    [setFormularData],
  );
}

export function useSetFormularData(id: string) {
  const update = useUpdateFormularData();

  return useCallback(
    async (values: Array<FeldData>) => {
      const updatedAt = Date.now();
      await update(id, (c) => ({ ...c, values, updatedAt }));
    },
    [id, update],
  );
}

function createDefaultValues(
  formular: Formular,
  mission?: FullMission,
): Array<FeldData> {
  return formular.fields.map((field) => ({
    id: field.id,
    label: field.label,
    type: field.type,
    value: createDefaultValue(field, mission),
  }));
}

function createDefaultValue(field: FormularFeld, mission?: FullMission): any {
  const configuredDefault = getDefaultValue(field, mission);
  if (configuredDefault !== undefined) return configuredDefault;

  switch (field.type) {
    case "checkbox":
      return false;
    case "image":
      return [];
    default:
      return "";
  }
}

function getDefaultValue(field: FormularFeld, mission?: FullMission): any {
  const defaultValue = "default" in field ? field.default : undefined;
  if (defaultValue === undefined) return;

  const match = /^\{mission\.(.+)\}$/.exec(defaultValue);
  if (match && mission) return get(mission, match[1]);
  return defaultValue;
}

export function useSubmitFormular() {
  const { portalKey } = useAuthenticated();
  const { id: geraetId, name: geraetName } = useGeraet();
  const update = useUpdateFormularData();
  const submitMissionReport = useSubmitMissionReport();

  return useCallback(
    async (data: FormularData, { missionId }: { missionId?: string } = {}) => {
      const submittedAt = Date.now();
      const payload = {
        id: data.id,
        geraet: { id: geraetId, name: geraetName },
        formular: { id: data.formularId, name: data.formularName },
        email: data.email,
        values: data.values,
      };

      try {
        const response = await fetch(
          `${portalServer}/formulare.php?ORG_ACCESSKEY=${portalKey}`,
          {
            method: "post",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(payload),
          },
        );

        if (!response.ok)
          throw new Error(`Fehler ${response.status} ${response.statusText}`);

        const updated = await update(data.id, (c) => ({
          ...c,
          values: data.values,
          submittedAt,
          status: "submitted",
          error: undefined,
        }));

        if (missionId && updated) {
          await submitMissionReport({
            missionId,
            geraetName,
            formularName: data.formularName,
            data: updated,
          });
        }

        return updated;
      } catch (error: any) {
        return update(data.id, (c) => ({
          ...c,
          values: data.values,
          submittedAt,
          status: "failed",
          error: { message: error.message },
        }));
      }
    },
    [geraetId, geraetName, portalKey, submitMissionReport, update],
  );
}

function useSubmitMissionReport() {
  const missionClient = useMissionsClient();

  return useCallback(
    async ({
      missionId,
      geraetName,
      formularName,
      data,
    }: {
      missionId: string;
      geraetName: string;
      formularName: string;
      data: FormularData;
    }) => {
      await missionClient.createMissionReport(missionId, {
        reporter: geraetName,
        text: `Formulardaten eingereicht für "${formularName}" (ID: ${data.id}).`,
        attachments: [],
      });
    },
    [missionClient],
  );
}

function useUpdateFormularData() {
  const setFormularData = useSetRecoilState(formularDataAtom);

  return useCallback(
    async (id: string, updater: (data: FormularData) => FormularData) => {
      const datas = await readStorage();
      const updated = datas.map((c) => (c.id === id ? updater(c) : c));
      await writeStorage(updated);
      setFormularData(updated);
      return updated.find((d) => d.id === id);
    },
    [setFormularData],
  );
}

export function useApplyFeldValues(
  felder: Array<FormularFeld>,
): (values: any) => Array<FeldData> {
  return useCallback(
    (values) =>
      felder.map((feld) => ({
        id: feld.id,
        label: feld.label,
        type: feld.type,
        value: values[feld.id],
      })),
    [felder],
  );
}
