import { portalServer } from "../config";
import { FetchProvider, useData } from "../data-provider";
import { KartenObjekt } from "../types";
import { limiter } from "./demo";
import { minutely, DataProvider, StorageCache } from "./provider";

const id = "kartendetailobjekte";

export const dataProvider: DataProvider<KartenObjekt[]> = ({
  demo,
  portalKey,
}) => ({
  id,
  label: "kartenobjekte:detailobjekte",
  provider: new FetchProvider({
    url: `${portalServer}/get-detailobjects.php?ORG_ACCESSKEY=${encodeURIComponent(
      portalKey,
    )}`,
    transform: (data) =>
      limiter<KartenObjekt>(
        demo,
        100,
      )((data.DETAILOBJEKT_LIST || []).map(parseKartenDetailobjekt)),
  }),
  cache: new StorageCache(id),
  getStatistics: async (value) => ({
    count: value.length,
    totalSize: JSON.stringify(value).length,
  }),
  updateInterval: 15 * minutely,
});

function parseKartenDetailobjekt(entry: any): KartenObjekt {
  return {
    id: entry.DETAIL_ID,
    typ: entry.DETAIL_ICON.replace(/^map_icon_/, ""),
    latitude: parseFloat(entry.DETAIL_GEO_LAT),
    longitude: parseFloat(entry.DETAIL_GEO_LNG),
    name: entry.DETAIL_NAME,
    beschreibung: entry.DETAIL_DESCRIPTION || undefined,
    color: entry.DETAIL_COLOR || undefined,
    polygon: entry.DETAIL_BLOCK ? parsePolygon(entry.DETAIL_BLOCK) : undefined,
  };
}

export function useKartenDetailobjekte() {
  return useData<KartenObjekt[]>(id, []);
}

function parsePolygon(polygon: string): Array<[number, number]> {
  const nodes = polygon.split(/\s*;\s*/);
  return nodes.map(
    (node) => node.split(/\s*,\s*/).map(parseFloat) as [number, number],
  );
}
