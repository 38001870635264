import { lazy, useMemo } from "react";
import { Navigate, Outlet, type RouteObject } from "react-router";
import ModuleContextProvider from "../../../components/ModuleContext";
import type { ModuleInfo } from "../../types";
import { route } from "../config";

const PatientsIndex = lazy(() => import("./PatientsIndex"));
const PatientsMissionLoader = lazy(() => import("./PatientsMissionLoader"));
const NewPatient = lazy(() => import("./NewPatient"));
const PatientDetails = lazy(() => import("./PatientDetails"));
const PatientList = lazy(() => import("./PatientList"));

export function usePatientenRoutes(module: ModuleInfo): Array<RouteObject> {
  return useMemo(
    () => [
      {
        path: route,
        element: (
          <ModuleContextProvider module={module}>
            <Outlet />
          </ModuleContextProvider>
        ),
        children: [
          { index: true, element: <PatientsIndex /> },
          {
            path: ":missionId",
            element: <PatientsMissionLoader />,
            children: [
              { index: true, element: <Navigate to="active" /> },
              { path: "new", element: <NewPatient /> },
              { path: "p/:id", element: <PatientDetails /> },
              { path: ":filter", element: <PatientList /> },
            ],
          },
        ],
      },
    ],
    [module],
  );
}
