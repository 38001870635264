import { Box, Paper } from "@mui/material";
import { type ReactNode } from "react";

export default function Global({ children }: { children?: ReactNode }) {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 2,
      }}
    >
      <Paper sx={{ width: "100%", maxWidth: "20rem", overflow: "hidden" }}>
        {children}
      </Paper>
    </Box>
  );
}
