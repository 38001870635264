import { useTranslation } from "react-i18next";
import { version } from "./config";
import { useDataProviders } from "./data-provider";
import { LicenseData, useLicense } from "./license";
import { useWakeLock, WakeLockContext } from "./wakelock";

export type Diagnostics = {
  version: string;
  language: string;
  lizenz?: LicenseData;
  userAgent: string;
  wakeLock: WakeLockContext;
  caches?: Array<{
    id: string;
    label: string;
    counts?: Array<{ name: string; count: number }>;
    lastUpdate?: Date;
    updateError?: { timestamp: Date; message: string };
  }>;
};

export function useDiagnostics(): Diagnostics {
  const wakeLock = useWakeLock();
  const license = useLicense();
  const { i18n } = useTranslation();
  const { providers } = useDataProviders();

  return {
    version,
    language: i18n.language,
    userAgent: navigator.userAgent,
    wakeLock,
    ...(license && { lizenz: license }),
    caches: providers.map((cache) => ({
      id: cache.id,
      label: cache.label,
      count: cache.statistics?.count,
      lastUpdate: cache.lastUpdate ? new Date(cache.lastUpdate) : undefined,
      updateError: cache.error && {
        timestamp: new Date(cache.error.timestamp),
        message: cache.error.message,
      },
    })),
  };
}
