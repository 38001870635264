import { portalServer } from "../config";
import { FetchProvider, useData } from "../data-provider";
import { Sperrung } from "../types";
import { limiter } from "./demo";
import { DataProvider, hourly, StorageCache } from "./provider";

const id = "sperrungen";

export const dataProvider: DataProvider<Sperrung[]> = ({
  demo,
  portalKey,
}) => ({
  id,
  label: `${id}:label`,
  provider: new FetchProvider({
    url: `${portalServer}/get-blocks.php?ORG_ACCESSKEY=${encodeURIComponent(
      portalKey,
    )}`,
    transform: (data: any) =>
      limiter<Sperrung>(demo, 3)((data.BLOCKS_LIST || []).map(parseSperrung)),
  }),
  cache: new StorageCache(id),
  getStatistics: async (value) => ({
    count: value.length,
    totalSize: JSON.stringify(value).length,
  }),
  updateInterval: hourly,
});

function parseSperrung(entry: any): Sperrung {
  return {
    id: entry.BLOCK_ID,
    latitude: parseFloat(entry.BLOCK_GEO_LAT),
    longitude: parseFloat(entry.BLOCK_GEO_LNG),
    typ: "sperrung",
    sperrungTyp: "Vollsperrung",
    icon: "sperrung",
    color: "#a22717",
    dashArray: "10 10",
    name: entry.BLOCK_TEXT,
    ort: entry.BLOCK_PLACE,
    von: entry.BLOCK_TIME_FROM,
    bis: entry.BLOCK_TIME_TO,
    beschreibung: `${entry.BLOCK_PLACE}\nvon ${entry.BLOCK_TIME_FROM} bis ${entry.BLOCK_TIME_TO}`,
    segments:
      Array.isArray(entry.BLOCK_ARRAY) && entry.BLOCK_ARRAY.length > 1
        ? entry.BLOCK_ARRAY.map((seg: string[]) => seg.map(parseFloat))
        : [],
    ...parseSperrungData(entry.BLOCK_TYPE),
  };
}

function parseSperrungData(typ: string): Partial<Sperrung> {
  switch (typ) {
    case "1":
      return { sperrungTyp: "Teilsperrung", icon: "sperrung" };
    case "2":
      return { sperrungTyp: "Information", icon: "sperrung" };
    case "3":
      return {
        sperrungTyp: "Bedingt Befahrbar",
        icon: "bedingtbefahrbar",
        color: "#3d5afe",
      };
    case "4":
      return {
        sperrungTyp: "Behelfsweg",
        icon: "behelfsweg",
        color: "#4caf50",
      };
    case "5":
      return {
        sperrungTyp: "Befahrbarer Weg",
        icon: "befahrbarerweg",
        color: "#007F0E",
        dashArray: "",
        opacity: 0.5,
      };
    default:
      return {};
  }
}

export function useSperrungen() {
  return useData<Sperrung[]>(id, []);
}
