import { Box, BoxProps, useMediaQuery, useTheme } from "@mui/material";
import { type PropsWithChildren, type ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import ErrorBoundary from "../ErrorBoundary";
import LargeLayout from "./LargeLayout";
import SmallLayout from "./SmallLayout";
import { type LayoutProps } from "./common";

const drawerWidth = 240;

export type Props = PropsWithChildren<
  {
    gutter?: boolean;
    main?: BoxProps;
    title?: ReactNode;
    titleText?: string | Array<string | undefined>;
  } & Pick<LayoutProps, "back">
>;

export default function Layout({
  children,
  gutter,
  main = {},
  title,
  titleText,
  ...props
}: Props) {
  const { t } = useTranslation();
  const product = t("product");
  const theme = useTheme();
  const large = useMediaQuery(theme.breakpoints.up("md"));

  const mainComp = (
    <Box
      component="main"
      {...main}
      sx={{
        flexGrow: 1,
        position: "relative",
        p: gutter ? 1 : 0,
        ...main.sx,
      }}
    >
      <ErrorBoundary>{children}</ErrorBoundary>
    </Box>
  );

  return (
    <>
      <Helmet titleTemplate={`%s • ${product}`} defaultTitle={product}>
        {typeof titleText === "string" ? (
          <title>{titleText}</title>
        ) : Array.isArray(titleText) ? (
          <title>{titleText.filter(Boolean).join(" • ")}</title>
        ) : typeof title === "string" ? (
          <title>{title}</title>
        ) : null}
      </Helmet>
      {large ? (
        <LargeLayout {...props} drawerWidth={drawerWidth}>
          {mainComp}
        </LargeLayout>
      ) : (
        <SmallLayout {...props} drawerWidth={drawerWidth}>
          {mainComp}
        </SmallLayout>
      )}
    </>
  );
}
