import { useCallback } from "react";
import { useSetting } from "../../data";
import { type Resource } from "../../missions/api";

export function useGetResourceTitle(): (
  resource: Pick<Resource, "identifier" | "name">,
) => {
  primary: string;
  secondary: string;
} {
  const deviceLabel = useSetting("deviceLabel");

  return useCallback(
    (resource) =>
      deviceLabel === "identifier"
        ? { primary: resource.identifier, secondary: resource.name }
        : { primary: resource.name, secondary: resource.identifier },
    [deviceLabel],
  );
}
