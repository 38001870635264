import { type ReactNode } from "react";
import { platform } from "../config";
import { CapGoUpdateProvider } from "./capgo/provider";
import { ServiceWorkerUpdateProvider } from "./sw/provider";

export function UpdateProvider(props: { children: ReactNode }) {
  return platform === "web" ? (
    <ServiceWorkerUpdateProvider {...props} />
  ) : (
    <CapGoUpdateProvider {...props} />
  );
}
