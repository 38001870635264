import { SvgIconComponent } from "@mui/icons-material";
import { ButtonProps } from "@mui/material";
import { bool, dict } from "@recoiljs/refine";
import { useCallback } from "react";
import { atom, selector, useRecoilValue, useSetRecoilState } from "recoil";
import { syncEffect } from "recoil-sync";
import { databaseStore } from "../db/recoil";

export type InfoLevel = "error" | "warning" | "info";

export type InfoAction = {
  variant?: ButtonProps["variant"];
  color?: ButtonProps["color"];
  onClick: () => void | Promise<void>;
  label: string;
};

export type InfoData = {
  id: string;
  level: InfoLevel;
  title: string;
  message: string;
  icon?: SvgIconComponent;
  actions?: InfoAction[];
};

export type Info = InfoData & {
  id: string;
  dismiss(): void;
  ignore(): void;
};

const infosAtom = atom<Info[]>({
  key: "infos",
  default: [],
});

const ignoredInfosAtom = atom<{ [id: string]: boolean }>({
  key: "infos/ignored",
  default: {},
  effects: [
    syncEffect({
      refine: dict(bool()),
      storeKey: databaseStore,
      itemKey: "config/ignored-infos",
    }),
  ],
});

const infosSelector = selector<Info[]>({
  key: "infos/filtered",
  get: ({ get }) => {
    const infos = get(infosAtom);
    const ignored = get(ignoredInfosAtom);
    return infos.filter((i) => !ignored[i.id]);
  },
});

export function useAddInfo() {
  const setIgnored = useSetRecoilState(ignoredInfosAtom);
  const setInfos = useSetRecoilState(infosAtom);

  const dismiss = useCallback(
    (id: string) => setInfos((is) => is.filter((i) => i.id !== id)),
    [setInfos],
  );

  const ignore = useCallback(
    (id: string) => {
      setIgnored((previous) => ({ ...previous, [id]: true }));
      dismiss(id);
    },
    [setIgnored, dismiss],
  );

  return useCallback(
    (data: InfoData) => {
      const id = data.id;
      const info = {
        ...data,
        dismiss: () => dismiss(id),
        ignore: () => ignore(id),
      };
      setInfos((is) => [...is.filter((i) => i.id !== id), info]);
      setIgnored((previous) => ({ ...previous, [id]: false }));
      return info;
    },
    [setIgnored, setInfos, dismiss, ignore],
  );
}

export function useInfos() {
  return useRecoilValue(infosSelector);
}
