import { lazy, useMemo } from "react";
import ModuleContextProvider from "../../../components/ModuleContext";
import type { ModuleInfo } from "../../types";
import { route } from "../config";

const Dashboard = lazy(() => import("./Dashboard"));

export function useDashboardRoutes(module: ModuleInfo) {
  return useMemo(
    () => [
      {
        path: route,
        element: (
          <ModuleContextProvider module={module}>
            <Dashboard />
          </ModuleContextProvider>
        ),
      },
    ],
    [module],
  );
}
