import { ErrorLike } from "../types";

export type TaskId = {
  id: string;
  label: string;
};

export type TaskSpec = TaskId & {
  getNextExecutionTimestamp?(lastRun?: number): number | undefined;
  execute(observer: TaskObserver): Promise<any>;
};

export type TaskObserver = {
  taskId: string;
  canceled: boolean;
  setWork(count: number): void;
  setTask(task?: string): void;
  onSuccess(): void;
  onFailed(error?: ErrorLike): void;
};

export type TaskInfo = TaskId & {
  status: "idle" | "running";
  currentRun?: TaskRun;
  lastRun?: TaskRun;
  nextExecutionAt?: number;
};

export type TaskRun = {
  id: string;
  startedAt: number;
  finishedAt?: number;
  canceled: boolean;
  task?: string;
  totalCount: number;
  successCount: number;
  errorCount: number;
  errors: ErrorLike[];
};

export function getNextExecutionTimestamp(
  delay: number,
  enabled: boolean,
): (lastRun: number | undefined) => number | undefined {
  return (lastRun) => {
    if (!enabled) return undefined;
    const next = lastRun ? lastRun + delay : 0;
    return Math.max(next, Date.now() + 5000);
  };
}
