import { useMemo } from "react";
import type { ModuleInfo, ModuleSpec } from "../types";
import menuItem from "./MenuItem";
import { Icon, id, label, route } from "./config";
import { dataProviders } from "./data";
import { useMeldungenRoutes } from "./routes";

export { default as MeldungMapButton } from "./components/MeldungMapButton";

const module: ModuleInfo = {
  id,
  label,
  icon: Icon,
  route,
  trackingContext: "Meldungen",
};

export function useMeldungenModule(): ModuleSpec {
  const routes = useMeldungenRoutes(module);

  return useMemo(
    () => ({
      ...module,
      enabled: (license) =>
        license.module.includes("meldungen") ||
        license.module.includes("meldungenadmin"),
      dataProviders,
      menuItem,
      routes,
      dashboardTiles: (license) =>
        license.module.includes("meldungenadmin")
          ? [{ label, to: route, icon: Icon }]
          : [],
    }),
    [routes],
  );
}
