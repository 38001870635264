import LogT from "logt";
import { logLevel } from "./config";

const log = new LogT(logLevel);

export const logLevels = [
  "silly",
  "verbose",
  "debug",
  "info",
  "warn",
  "error",
] as const;
export type LogLevel = (typeof logLevels)[number];

export type LogMessage = {
  timestamp: Date;
  level: LogLevel;
  tag: string;
  message: unknown;
  parts: Array<unknown>;
};

export const logMessages: Array<LogMessage> = [];

export type Logger = {
  silly: (...args: any) => void;
  verbose: (...args: any) => void;
  debug: (...args: any) => void;
  info: (...args: any) => void;
  warn: (...args: any) => void;
  error: (...args: any) => void;
};

/**
 * Create a logger that prefixes messages with a colorful tag.
 */
export function logger(tag: string): Logger {
  const ret = {} as Logger;

  for (const level of logLevels) {
    ret[level] = (message, ...parts: any) => {
      log[level](tag, message, ...parts);
      logMessages.push({ timestamp: new Date(), level, tag, message, parts });
    };
  }

  return ret;
}
