import { DataProvider } from "../types";

export type FetchProviderOptions<T> = {
  url: RequestInfo;
  fetchOptions?: RequestInit;
  transform?(response: any): T;
};
export class FetchProvider<T> implements DataProvider<T> {
  constructor(private options: FetchProviderOptions<T>) {}

  async getData(): Promise<T> {
    const response = await fetch(this.options.url, this.options.fetchOptions);
    if (!response.ok)
      throw new Error(
        `Error fetching data: ${response.status} ${response.statusText}`,
      );
    const data = await response.json();
    return this.options.transform ? this.options.transform(data) : data;
  }
}
