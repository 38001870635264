import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { mapStackTrace } from "sourcemapped-stacktrace";
import { clearData } from "../db";
import { useDiagnostics } from "../diagnostics";
import { ErrorLike } from "../types";
import Global from "./Global";
import SendDiagnosticsButton from "./SendDiagnosticsButton";
import SubmitButton from "./SubmitButton";

export type Props = {
  error: ErrorLike;
  global?: boolean;
};

export default function GlobalError({ error, global }: Props) {
  const { t } = useTranslation("error");
  const [stack, setStack] = useState<string[] | undefined>(
    error.stack?.split("\n"),
  );
  const [resetting, setResetting] = useState(false);
  const reset = useCallback(async () => {
    setResetting(true);
    await clearData({ keepConfig: true, keepResources: true });
    window.location.reload();
  }, []);

  useEffect(() => {
    if (error.stack) {
      try {
        mapStackTrace(error.stack, setStack);
      } catch (e) {
        console.error("Error mapping stack trace:", e);
      }
    }
  }, [error.stack]);

  const content = (
    <>
      <Typography variant="h4" gutterBottom color="error">
        {t("heading")}
      </Typography>
      <Typography gutterBottom sx={{ whiteSpace: "pre-line" }}>
        {t("info")}
      </Typography>
      <Grid container spacing={1}>
        <Grid item>
          <SubmitButton onClick={reset} loading={resetting}>
            {t("reload-data")}
          </SubmitButton>
        </Grid>
        <Grid item>
          <SendDiagnosticsButton variant="contained" />
        </Grid>
      </Grid>
      {error.stack && (
        <>
          <Typography variant="h5" sx={{ mt: 4 }}>
            {t("error")}
          </Typography>
          <Box
            component="pre"
            sx={{
              overflow: "auto",
              width: 1,
            }}
          >
            {stack ? [error.toString(), ...stack].join("\n") : error.stack}
          </Box>
        </>
      )}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5">{t("diagnostics")}</Typography>
        <Diagnostics />
      </Box>
    </>
  );

  return global ? (
    <Global>
      <Box sx={{ p: 4 }}>{content}</Box>
    </Global>
  ) : (
    <Card>
      <CardContent>{content}</CardContent>
    </Card>
  );
}

function Diagnostics() {
  const { t } = useTranslation("error");
  const { version, lizenz, userAgent, wakeLock, caches } = useDiagnostics();

  return (
    <>
      <Typography>{t("version-info", { version })}</Typography>
      {lizenz && (
        <>
          <Typography>
            {t("license")}: {lizenz.id}
          </Typography>
          {lizenz.gueltigkeit && (
            <Typography>
              {t("valid-until")}: {new Date(lizenz.gueltigkeit).toISOString()}
            </Typography>
          )}
          {lizenz.einheit && (
            <Typography>
              {t("unit")}: {lizenz.einheit.name} ({lizenz.einheit.id})
            </Typography>
          )}
          {lizenz.untereinheit && (
            <Typography>
              {t("subunit")}: {lizenz.untereinheit.name} (
              {lizenz.untereinheit.id})
            </Typography>
          )}
          <Typography>
            {t("device")}: {lizenz.name} ({lizenz.id})
          </Typography>
        </>
      )}
      <Typography>
        {t("user-agent")}: {userAgent}
      </Typography>
      <Typography>
        {t("wake-lock")}: {wakeLock.active ? t("active") : t("inactive")} (
        {wakeLock.type || t("disabled")})
      </Typography>
      {caches && (
        <>
          {caches.map((cache) => (
            <Fragment key={cache.id}>
              <Typography variant="h6">{t(cache.label)}</Typography>
              {cache.counts &&
                cache.counts.map((count) => (
                  <Typography key={count.name}>
                    {t(count.name)}: {count.count}
                  </Typography>
                ))}
              {cache.lastUpdate && (
                <Typography>
                  {t("last-update")}: {cache.lastUpdate.toISOString()}
                </Typography>
              )}
              {cache.updateError && (
                <Typography>
                  {t("error")}: {cache.updateError.timestamp.toISOString()}:{" "}
                  {cache.updateError.message}
                </Typography>
              )}
            </Fragment>
          ))}
        </>
      )}
    </>
  );
}
