import { Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import BackButton from "./BackButton";

export type Props = {
  back?: string | (() => void);
};

export default function Title({ back, children }: PropsWithChildren<Props>) {
  return (
    <Typography variant="h5" sx={{ display: "flex", alignItems: "center" }}>
      {back && <BackButton back={back} />}
      {children}
    </Typography>
  );
}
