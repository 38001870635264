import {
  Info as AboutIcon,
  Public as ExternalPageIcon,
  Apps as RemainingIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
} from "@mui/material";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { useTranslation } from "react-i18next";
import { useAuthenticated, useModulEnabled } from "../../auth";
import { development } from "../../config";
import { MenuItem, useMenuItems } from "../../menu";
import TasksMenuItem from "../../pages/settings/TasksMenuItem";
import DebugMenuItem from "../Debug";
import ListItemLink from "../ListItemLink";
import { useDrawerState } from "./drawer";

export default function MainMenu() {
  const { t } = useTranslation("settings");
  const infoEnabled = useModulEnabled("info");
  const { visible, hidden } = useMenuItems();
  const { externalPages } = useAuthenticated();
  const externalMenuItems = externalPages?.filter((p) => p.showInMenu) ?? [];

  return (
    <>
      <List>
        {visible.map((m) => (
          <m.component key={m.id} />
        ))}
        {hidden.length > 0 && <RemainingListItem items={hidden} />}
        {externalMenuItems.map((item, i) => (
          <ListItemLink
            to={
              item.target === "external"
                ? item.url
                : `/browser?url=${encodeURIComponent(item.url)}`
            }
            key={i}
          >
            <ListItemIcon>
              <ExternalPageIcon />
            </ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItemLink>
        ))}
      </List>
      <List sx={{ mt: "auto" }}>
        <TasksMenuItem />
        {development && <DebugMenuItem />}
        {infoEnabled && (
          <ListItemLink to="/settings/about">
            <ListItemIcon>
              <AboutIcon />
            </ListItemIcon>
            <ListItemText primary={t("about.label")} />
          </ListItemLink>
        )}
        <ListItemLink to="/settings">
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={t("label")} />
        </ListItemLink>
      </List>
    </>
  );
}

function RemainingListItem({ items }: { items: MenuItem[] }) {
  const popupState = usePopupState({
    variant: "popover",
    popupId: "menu-remaining",
  });
  const [drawerOpen] = useDrawerState();

  return (
    <>
      <ListItemButton {...bindTrigger(popupState)}>
        <ListItemIcon>
          <RemainingIcon />
        </ListItemIcon>
        <ListItemText primary="Sonstige" />
      </ListItemButton>
      <Popover
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        {...bindPopover(popupState)}
      >
        <List
          sx={{
            display: "grid",
            gridTemplateColumns: drawerOpen
              ? undefined
              : { xs: "repeat(2, 1fr)", md: "repeat(3, 1fr)" },
            "& .MuiListItemIcon-root": drawerOpen
              ? undefined
              : { minWidth: "auto", p: 1 },
            "& .MuiListItemText-root": drawerOpen
              ? undefined
              : { display: "none" },
          }}
        >
          {items.map((m) => (
            <m.component key={m.id} />
          ))}
        </List>
      </Popover>
    </>
  );
}
