import { useMemo } from "react";
import { useLicense } from "../license";
import { useAtemschutzModule } from "./atemschutz";
import { useChecklistenModule } from "./checklisten";
import { useDashboardModule } from "./dashboard";
import { useDokumenteModule } from "./dokumente";
import { useFormulareModule } from "./formulare";
import { useGefahrgutModule } from "./gefahrgut";
import { useKarteModule } from "./karte";
import { useKontakteModule } from "./kontakte";
import { useMeldungenModule } from "./meldungen";
import { useObjekteModule } from "./objekte";
import { usePatientenModule } from "./patienten";
import { useRettungskartenModule } from "./rettungskarten";
import { useStatisticsModule } from "./statistics";
import { useTableauModule } from "./tableau";
import type { ModuleSpec } from "./types";
import { useWhiteboardModule } from "./whiteboard";

export * from "./types";

const sortOrder = [
  "dashboard",
  "tableau",
  "patienten",
  "checklisten",
  "gefahrgut",
  "rettungskarten",
  "objekte",
  "dokumente",
  "karte",
  "meldungen",
  "kontakte",
  "formulare",
  "atemschutz",
  "statistics",
  "whiteboard",
];

export function useModules(): Array<ModuleSpec> {
  const license = useLicense();
  const modules = [
    useAtemschutzModule(),
    useChecklistenModule(),
    useDashboardModule(),
    useDokumenteModule(),
    useFormulareModule(),
    useGefahrgutModule(),
    useKarteModule(),
    useKontakteModule(),
    useMeldungenModule(),
    useObjekteModule(),
    usePatientenModule(),
    useRettungskartenModule(),
    useStatisticsModule(),
    useTableauModule(),
    useWhiteboardModule(),
  ];

  return useMemo(
    () =>
      license
        ? modules
            .filter((m) => m.enabled(license))
            .sort((a, b) => sortOrder.indexOf(a.id) - sortOrder.indexOf(b.id))
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...modules],
  );
}
