import { useCallback } from "react";
import {
  atom,
  RecoilState,
  selector,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { portalServer } from "../../config";
import { FetchProvider, providerValueAtom } from "../../data-provider";
import { daily, DataProvider, StorageCache } from "../../db/provider";
import { activeMissionAtom } from "../../missions";
import { id, label } from "./config";
import { Checkliste } from "./types";

const dataProvider: DataProvider<Checkliste[]> = ({ demo, portalKey }) => ({
  id,
  label,
  provider: new FetchProvider({
    url: `${portalServer}/get-checklists.php?ORG_ACCESSKEY=${encodeURIComponent(
      portalKey,
    )}`,
    transform: (data) => {
      const checklists: Checkliste[] = (data.CHECKLIST_LIST || []).map(
        (entry: any) => ({
          id: entry.CHECKLIST_ID,
          name: entry.CHECKLIST_NAME,
          eintraege: [],
        }),
      );

      (data.CHECKLIST_ENTRY_LIST || []).forEach((entry: any, i: number) => {
        const checklistId = entry.ENTRY_CHECKLIST_ID;
        const checklist = checklists.find((c) => c.id === checklistId);
        if (checklist)
          checklist.eintraege.push({
            id: `${checklistId}-${i}`,
            titel: entry.ENTRY_TITLE,
            text: entry.ENTRY_TEXT,
            liste: (entry.ENTRY_LIST || "").split(";"),
          });
      });

      return demo ? checklists.slice(0, 3) : checklists;
    },
  }),
  cache: new StorageCache(id),
  getStatistics: async (value) => ({
    count: value.length,
    totalSize: JSON.stringify(value).length,
  }),
  updateInterval: daily,
});

export const dataProviders = [dataProvider];

export const checklistenAtom = providerValueAtom(id) as RecoilState<
  Checkliste[]
>;

const aktiveChecklisteAtom = atom<Record<string, string | undefined>>({
  key: `${id}:aktiv:id`,
  default: {},
});

export const aktiveChecklisteSelector = selector<Checkliste | undefined>({
  key: `${id}:aktiv`,
  get: ({ get }) => {
    const missionId = get(activeMissionAtom)?.missionId ?? "_none_";
    const aktive = get(aktiveChecklisteAtom)[missionId];
    const checklisten = get(checklistenAtom);
    return checklisten && aktive
      ? checklisten.find((c) => c.id === aktive)
      : undefined;
  },
});

export const erledigteChecklistenEintraegeAtom = atom<string[]>({
  key: `${id}:eintraege`,
  default: [],
});

export function useSetAktiveCheckliste() {
  const missionId = useRecoilValue(activeMissionAtom)?.missionId ?? "_none_";
  const setState = useSetRecoilState(aktiveChecklisteAtom);

  return useCallback(
    (id?: string) => setState((s) => ({ ...s, [missionId]: id })),
    [setState, missionId],
  );
}
