import { useCallback } from "react";
import { atom, selector, selectorFamily, useRecoilValue } from "recoil";
import { TaskId, TaskInfo, TaskSpec } from "./api";
import { useTaskManager } from "./provider";

export const tasksAtom = atom<{ [id: string]: TaskInfo }>({
  key: "tasks",
  default: {},
});

const taskIdsSelector = selector<TaskId[]>({
  key: "tasks:ids",
  get: ({ get }) => {
    const tasks = get(tasksAtom);
    return Object.values(tasks).map((task) => ({
      id: task.id,
      label: task.label,
    }));
  },
});

const taskStateSelector = selectorFamily<TaskInfo, string>({
  key: "tasks:state",
  get:
    (taskId: string) =>
    ({ get }) =>
      get(tasksAtom)[taskId],
});

export const taskCountsSelector = selector<{ running: number; total: number }>({
  key: "tasks:counts",
  get({ get }) {
    const tasks = Object.values(get(tasksAtom));
    const running = tasks.filter((task) => task.currentRun).length;
    return { running, total: tasks.length };
  },
});

export function useTasks(): TaskId[] {
  return Object.values(useRecoilValue(taskIdsSelector));
}

export function useTaskState(taskId: string): TaskInfo {
  return useRecoilValue(taskStateSelector(taskId));
}

export function useRegisterTask() {
  const manager = useTaskManager();
  return useCallback((spec: TaskSpec) => manager.register(spec), [manager]);
}

export function useExecuteTask() {
  const manager = useTaskManager();
  return useCallback((taskId: string) => manager.run(taskId), [manager]);
}

export function useCancelTask() {
  const manager = useTaskManager();
  return useCallback(
    (taskId: string, runId: string) => manager.cancel(taskId, runId),
    [manager],
  );
}
