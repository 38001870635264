import { inverse as fromMGRS } from "mgrs";
import qs from "query-string";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  type PropsWithChildren,
} from "react";
import { useTranslation } from "react-i18next";
import { useMap } from "react-leaflet";
import { useLocation } from "react-router";
import { toLatLon as fromUTM } from "utm";
import { useCurrentLocation, useGeraet } from "../../data";
import { type FullMission } from "../../missions";
import type { Koordinaten } from "../../types";

export type CenterType =
  | "einsatz"
  | "location"
  | "homebase"
  | "query"
  | "manual"
  | "free";

export type LocationSpec =
  | { type: "utm"; zone: string; easting: number; northing: number }
  | {
      type: "mgrs";
      zone: string;
      box: string;
      easting: number;
      northing: number;
    }
  | { type: "wgs"; latitude: number; longitude: number }
  | { type: "manual"; latitude: number; longitude: number };

export type MapCenterContext = {
  centerType: CenterType;
  center?: Koordinaten;
  setCenterType(centerType: CenterType): void;
  setCenter(center: LocationSpec): void;
};

const defaultContext: MapCenterContext = {
  centerType: "free",
  setCenterType() {
    throw new Error("Cannot change center type of default map context");
  },
  setCenter() {
    throw new Error("Cannot change center of default map context");
  },
};

const Context = createContext<MapCenterContext>(defaultContext);

export function MapCenterProvider({
  center: defaultCenter,
  mission,
  children,
}: PropsWithChildren<{ center?: LocationSpec; mission?: FullMission }>) {
  const pos = useQueryPosition();
  const { location } = useCurrentLocation();
  const { homebase } = useGeraet();
  const parseLocationSpec = useParseLocationSpec();

  const [centerType, setCenterType] = useState<CenterType>(
    defaultCenter
      ? "manual"
      : pos
        ? "query"
        : mission?.coordinates
          ? "einsatz"
          : "location",
  );
  const currentCenter = useRef<Koordinaten | undefined>();
  const [manualCenter, setManualCenter] = useState<Koordinaten | undefined>(
    defaultCenter ? parseLocationSpec(defaultCenter) : undefined,
  );
  const [center, setMapCenter] = useState<Koordinaten | undefined>(
    getCenter(
      centerType,
      manualCenter,
      mission?.coordinates,
      location,
      homebase,
      pos,
    ),
  );

  const setCenter = useCallback(
    (input: LocationSpec) => {
      setCenterType("manual");
      setManualCenter(parseLocationSpec(input));
    },
    [parseLocationSpec],
  );

  useEffect(() => {
    const center = getCenter(
      centerType,
      manualCenter,
      mission?.coordinates,
      location,
      homebase,
      pos,
    );

    if (center !== currentCenter.current) {
      setMapCenter(center);
      currentCenter.current = center;
    }
  }, [centerType, manualCenter, location, homebase, pos, mission?.coordinates]);

  const context: MapCenterContext = useMemo(() => {
    return { centerType, center, setCenterType, setCenter };
  }, [centerType, center, setCenter]);

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

function useQueryPosition(): Koordinaten | undefined {
  const { search } = useLocation();
  return useMemo(() => {
    const { pos } = qs.parse(search);
    if (!pos) return undefined;
    const parts = (
      Array.isArray(pos) ? (pos[0] as string) : (pos as string)
    ).split(",");
    if (parts.length !== 2) return undefined;
    const latitude = parseFloat(parts[0]);
    const longitude = parseFloat(parts[1]);
    if (isNaN(latitude) || isNaN(longitude)) return undefined;
    return { latitude, longitude };
  }, [search]);
}

export function MapCenterDragListener() {
  const { setCenterType } = useMapCenter();
  const map = useMap();

  useEffect(() => {
    const listener = () => setCenterType("free");
    map.addEventListener("drag", listener);
    return () => {
      map.removeEventListener("drag", listener);
    };
  }, [map, setCenterType]);

  return null;
}

export function MapCenterUpdater() {
  const { center } = useMapCenter();
  const map = useMap();

  useEffect(() => {
    if (center) map.setView([center.latitude, center.longitude]);
  }, [map, center]);

  return null;
}

export function useMapCenter() {
  return useContext(Context);
}

function useParseLocationSpec() {
  const { t } = useTranslation("karte");

  return useCallback(
    (spec: LocationSpec): Koordinaten => {
      if (spec.type === "wgs" || spec.type === "manual") {
        return { latitude: spec.latitude, longitude: spec.longitude };
      }

      if (spec.type === "utm") {
        const match = /^([0-9]{1,2})([a-zA-Z])$/.exec(spec.zone);
        if (!match) throw new Error(t("invalid-utm-zone", { zone: spec.zone }));
        const zoneNumber = parseInt(match[1], 10);
        const zoneLetter = match[2];
        return fromUTM(spec.easting, spec.northing, zoneNumber, zoneLetter);
      }

      if (spec.type === "mgrs") {
        const easting = spec.easting.toString();
        const northing = spec.northing.toString();
        const precision = Math.max(easting.length, northing.length);
        const mgrs = `${spec.zone}${spec.box}${easting.padStart(
          precision,
          "0",
        )}${northing.padStart(precision, "0")}`;
        const [longitude, latitude] = fromMGRS(mgrs);
        return { latitude, longitude };
      }

      throw new Error(t("invalid-coordinates"));
    },
    [t],
  );
}

function getCenter(
  centerType: CenterType,
  manualCenter?: Koordinaten,
  activeMission?: Koordinaten,
  location?: Koordinaten,
  homebase?: Koordinaten,
  pos?: Koordinaten,
): Koordinaten | undefined {
  if (centerType === "manual" && manualCenter) return manualCenter;
  if (centerType === "einsatz" && activeMission) return activeMission;
  if (centerType === "location") return location || homebase;
  if (centerType === "homebase") return homebase;
  if (centerType === "query") return pos;
  return undefined;
}
