import { useMemo } from "react";
import type { ModuleInfo, ModuleSpec } from "../types";
import menuItem from "./MenuItem";
import { Icon, id, label, route } from "./config";
import { useObjekteRoutes } from "./routes";

export { Icon, route } from "./config";

const module: ModuleInfo = {
  id,
  label,
  icon: Icon,
  route,
  trackingContext: "Objekte",
};

export function useObjekteModule(): ModuleSpec {
  const routes = useObjekteRoutes(module);

  return useMemo(
    () => ({
      ...module,
      enabled: (license) => license.module.includes("verzeichnis"),
      menuItem,
      routes,
      dataProviders: [],
      dashboardTiles: [{ label, to: route, icon: Icon }],
    }),
    [routes],
  );
}
