export type Arguments<T> = [T] extends [(...args: infer U) => any]
  ? U
  : [T] extends [void]
    ? []
    : [T];

export interface TypedEvents<Events> {
  on<E extends keyof Events>(event: E, listener: Events[E]): this;
  off<E extends keyof Events>(event: E, listener: Events[E]): this;
}

export interface TypedEventsManager<Events> extends TypedEvents<Events> {
  emit<E extends keyof Events>(
    event: E,
    ...args: Arguments<Events[E]>
  ): boolean;
}

export class EventEmitter<Events> implements TypedEventsManager<Events> {
  private listeners: { [event: string]: Array<any> } = {};

  on<E extends keyof Events>(event: E, listener: Events[E]) {
    const eventName = event as string;
    (this.listeners[eventName] = this.listeners[eventName] || []).push(
      listener,
    );
    return this;
  }

  off<E extends keyof Events>(event: E, listener: Events[E]) {
    const eventName = event as string;
    if (eventName in this.listeners) {
      const listeners = this.listeners[eventName];
      const index = listeners.indexOf(listener);
      if (index >= 0) listeners.splice(index, 1);
    }
    return this;
  }

  emit<E extends keyof Events>(
    event: E,
    ...args: Arguments<Events[E]>
  ): boolean {
    const eventName = event as string;
    const listeners = this.listeners[eventName];
    if (!listeners || !listeners.length) return false;
    listeners.forEach((listener) => listener(...args));
    return true;
  }
}
