import { Badge, ListItemIcon, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import ListItemLink from "../../components/ListItemLink";
import { Icon, label, route } from "./config";
import { useActiveDocument } from "./data";
import { dokumentUrl } from "./utils";

export default function DokumenteMenuItem() {
  const { t } = useTranslation();
  const active = useActiveDocument();

  return (
    <ListItemLink to={active ? dokumentUrl(active) : route}>
      <ListItemIcon>
        <Badge variant="dot" color="secondary" invisible={!active}>
          <Icon />
        </Badge>
      </ListItemIcon>
      <ListItemText primary={t(label)} />
    </ListItemLink>
  );
}
