import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSetting, useSpeak } from "../../data";
import { useMissionsManager, type FullMission } from "../../missions";
import { type Patient, type Report, type Resource } from "../../missions/api";
import {
  MissionsManagerEventListener,
  type MissionsManager,
} from "../../missions/manager";
import { id } from "./config";
import { useGetResourceTitle } from "./utils";

export function useNewMissionAnnouncer() {
  const observer = useMissionsObserver();

  useOnNewMission(observer);
  useOnNewResource(observer);
  useOnUpdatedResource(observer);
  useOnNewReport(observer);
  useOnNewPatient(observer);
}

function useMissionsObserver() {
  const manager = useMissionsManager();
  const observer = useMemo(() => new MissionsObserver(), []);
  useEffect(() => observer.setManager(manager), [observer, manager]);
  return observer;
}

function useOnNewMission(observer: MissionsObserver) {
  const enabled = useSetting("announceNewMission");
  const { t } = useTranslation(id);
  const speak = useSpeak();
  const handler = useCallback(
    (mission: FullMission) => {
      const message = processMessage(
        [mission.keyword, mission.message, mission.address]
          .filter(Boolean)
          .join(" - "),
      );
      speak(t("speak.new-mission", { message }));
    },
    [speak, t],
  );
  useAttachListener(observer, "new-mission", handler, enabled);
}

function useOnNewResource(observer: MissionsObserver) {
  const enabled = useSetting("announceNewMissionResource");
  const getResourceTitle = useGetResourceTitle();
  const { t } = useTranslation(id);
  const speak = useSpeak();
  const handler = useCallback(
    (_: unknown, resource: Resource) => {
      speak(
        t("speak.new-resource", {
          identifier: processMessage(getResourceTitle(resource).primary),
          status: resource.status,
        }),
      );
    },
    [speak, t, getResourceTitle],
  );
  useAttachListener(observer, "new-resource", handler, enabled);
}

function useOnUpdatedResource(observer: MissionsObserver) {
  const enabled = useSetting("announceMissionResourceStatusChange");
  const getResourceTitle = useGetResourceTitle();
  const { t } = useTranslation(id);
  const speak = useSpeak();
  const handler = useCallback(
    (_: unknown, resource: Resource) => {
      speak(
        t("speak.updated-resource", {
          identifier: processMessage(getResourceTitle(resource).primary),
          status: resource.status,
        }),
      );
    },
    [speak, t, getResourceTitle],
  );
  useAttachListener(observer, "updated-resource", handler, enabled);
}

function useOnNewReport(observer: MissionsObserver) {
  const enabled = useSetting("announceNewMissionResource");
  const { t } = useTranslation(id);
  const speak = useSpeak();
  const handler = useCallback(
    (_: unknown, report: Report) => {
      speak(
        t("speak.new-report", {
          reporter: processMessage(report.reporter),
          text: processMessage(report.text),
        }),
      );
    },
    [speak, t],
  );
  useAttachListener(observer, "new-report", handler, enabled);
}

function useOnNewPatient(observer: MissionsObserver) {
  const enabled = useSetting("announceNewMissionPatient");
  const { t } = useTranslation(id);
  const speak = useSpeak();
  const handler = useCallback(
    (patient: Patient) => {
      speak(t("speak.new-patient", { patient }));
    },
    [speak, t],
  );
  useAttachListener(observer, "new-patient", handler, enabled);
}

function useAttachListener<TType extends keyof MissionsObserverEventMap>(
  observer: MissionsObserver,
  type: TType,
  handler: MissionsObserverEventMap[TType],
  enabled: boolean,
) {
  useEffect(() => {
    if (enabled) observer.on(type, handler);
    return () => observer.off(type, handler);
  }, [observer, type, handler, enabled]);
}

type MissionState = {
  resources: Map<string, ResourceState>;
  reports: Set<string>;
};

type ResourceState = {
  status: string;
};

type MissionsObserverEventMap = {
  "new-mission": (mission: FullMission) => void;
  "new-resource": (mission: FullMission, resource: Resource) => void;
  "updated-resource": (
    mission: FullMission,
    resource: Resource,
    previousState: ResourceState,
  ) => void;
  "new-report": (mission: FullMission, report: Report) => void;
  "new-patient": (patient: Patient) => void;
};

type Arguments<T> = [T] extends [(...args: infer U) => any]
  ? U
  : [T] extends [void]
    ? []
    : [T];

class MissionsObserver {
  private readonly startedAt = Date.now();
  private readonly missions = new Map<string, MissionState>();
  private readonly listeners: Record<
    keyof MissionsObserverEventMap,
    Array<any>
  > = {
    "new-mission": [],
    "new-resource": [],
    "updated-resource": [],
    "new-report": [],
    "new-patient": [],
  };

  setManager(manager: MissionsManager): () => void {
    manager.addEventListener("mission_updated", this.onMissionUpdated);

    for (const mission of manager.missions.values()) {
      if (mission.state === "ready") this.handleMission(mission.mission);
    }

    return () => {
      manager.removeEventListener("mission_updated", this.onMissionUpdated);
    };
  }

  private onMissionUpdated: MissionsManagerEventListener<"mission_updated"> = (
    event,
  ) => {
    if (event.state === "ready") {
      this.handleMission(event.mission);
    }
  };

  private handleMission(mission: FullMission) {
    const existing = this.missions.get(mission.id);

    if (existing) {
      this.updateMission(existing, mission);
    } else {
      if (Date.now() - this.startedAt > 10000) {
        this.emit("new-mission", mission);
      }
    }

    this.storeMission(mission);
  }

  private updateMission(existing: MissionState, mission: FullMission) {
    for (const resource of mission.resources) {
      const existingResource = existing.resources.get(resource.id);
      if (existingResource) {
        if (resource.status !== existingResource.status) {
          this.emit("updated-resource", mission, resource, existingResource);
        }
      } else {
        this.emit("new-resource", mission, resource);
      }
    }

    for (const report of mission.reports) {
      if (!existing.reports.has(report.id)) {
        this.emit("new-report", mission, report);
      }
    }
  }

  private storeMission(mission: FullMission) {
    this.missions.set(mission.id, {
      resources: new Map(
        mission.resources.map((r) => [r.id, { status: r.status }]),
      ),
      reports: new Set(mission.reports.map((r) => r.id)),
    });
  }

  on<TType extends keyof MissionsObserverEventMap>(
    type: TType,
    handler: MissionsObserverEventMap[TType],
  ) {
    this.listeners[type].push(handler);
  }

  off<TType extends keyof MissionsObserverEventMap>(
    type: TType,
    handler: MissionsObserverEventMap[TType],
  ) {
    this.listeners[type] = this.listeners[type].filter((h) => h !== handler);
  }

  private emit<TType extends keyof MissionsObserverEventMap>(
    type: TType,
    ...args: Arguments<MissionsObserverEventMap[TType]>
  ) {
    this.listeners[type].forEach((handler) => handler(...args));
  }
}

function processMessage(text: string) {
  return processNumbers(processAcronyms(text));
}

function processAcronyms(text: string) {
  return (
    text
      .replace("KLEMM", "Klemm")
      .replace("DIVERA", "Divera")
      .replace("FLUG", "Flug")
      //.replace(/[A-Z]{2,}/g, (s) => s.split('').join('.') + '.')
      .replace(/Fl\./, "Florian")
  );
}

function processNumbers(text: string) {
  return text.replace(/[/-]/g, ", ");
}
