import {
  Box,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
} from "@mui/material";
import { Field, useFormikContext } from "formik";
import { Select, TextField } from "formik-mui";
import {
  createContext,
  useContext,
  useMemo,
  type PropsWithChildren,
} from "react";
import { useTranslation } from "react-i18next";
import TaktischesZeichenComp, {
  ComponentType,
  einheiten,
  fachaufgaben,
  funktionen,
  GrundzeichenId,
  grundzeichen as grundzeichens,
  organisationen,
  symbole,
  TaktischesZeichen,
  verwaltungsstufen,
} from "taktische-zeichen-react";
import * as yup from "yup";

export const zeichenSchema = yup.object().shape(
  {
    grundzeichen: yup
      .string()
      .oneOf(["", ...grundzeichens.map((g) => g.id)])
      .when("symbol", {
        is: (s: unknown) => s !== undefined,
        then: (s) => s,
        otherwise: (s) =>
          s.required(
            "Sie müssen entweder ein Grundzeichen oder ein Symbol auswählen.",
          ),
      }),
    symbol: yup
      .string()
      .oneOf(["", ...symbole.map((g) => g.id)])
      .when("grundzeichen", {
        is: (s: unknown) => s !== undefined,
        then: (s) => s,
        otherwise: (s) =>
          s.required(
            "Sie müssen entweder ein Grundzeichen oder ein Symbol auswählen.",
          ),
      }),
    fachaufgabe: yup.string().oneOf(["", ...fachaufgaben.map((g) => g.id)]),
    einheit: yup.string().oneOf(["", ...einheiten.map((g) => g.id)]),
    verwaltungsstufe: yup
      .string()
      .oneOf(["", ...verwaltungsstufen.map((g) => g.id)]),
    funktion: yup.string().oneOf(["", ...funktionen.map((g) => g.id)]),
    organisation: yup.string().oneOf(["", ...organisationen.map((g) => g.id)]),
    text: yup.string(),
    name: yup.string(),
    organisationName: yup.string(),
    farbe: yup.string(),
  },
  [["grundzeichen", "symbol"]],
);

type ZeichenValues = yup.InferType<typeof zeichenSchema>;

export const zeichenInitialValues = {
  grundzeichen: "",
  fachaufgabe: "",
  einheit: "",
  verwaltungsstufe: "",
  funktion: "",
  symbol: "",
  organisation: "",
  text: "",
  name: "",
  organisationName: "",
  farbe: "",
} as ZeichenValues;

type GeneratorContext = { prefix?: string };

const Context = createContext<GeneratorContext>({});

export default function TaktischesZeichenGenerator({
  prefix,
}: {
  prefix?: string;
}) {
  const context = useMemo(() => ({ prefix }), [prefix]);

  return (
    <Context.Provider value={context}>
      <Stack direction={{ xs: "column", md: "row" }} spacing={3}>
        <Box
          sx={{
            flex: 1,
            display: "grid",
            gap: 2,
            gridTemplateColumns: { md: "repeat(2, 1fr)" },
          }}
        >
          <ZeichenSelect
            name="grundzeichen"
            options={grundzeichens
              .filter((g) => g.id !== "ohne")
              .sort((a, b) => a.label.localeCompare(b.label))}
          />
          <ZeichenSelect
            name="fachaufgabe"
            options={[...fachaufgaben].sort((a, b) =>
              a.label.localeCompare(b.label),
            )}
            base={{ grundzeichen: "taktische-formation" }}
          />
          <ZeichenSelect
            name="organisation"
            options={[...organisationen].sort((a, b) =>
              a.label.localeCompare(b.label),
            )}
            base={{ grundzeichen: "stelle" }}
          />
          <ZeichenSelect
            name="einheit"
            options={einheiten}
            base={{ grundzeichen: "taktische-formation" }}
          />
          <ZeichenSelect
            name="verwaltungsstufe"
            options={verwaltungsstufen}
            base={{ grundzeichen: "taktische-formation" }}
          />
          <ZeichenSelect
            name="funktion"
            options={funktionen}
            base={{ grundzeichen: "person" }}
          />
          <ZeichenSelect
            name="symbol"
            options={[...symbole].sort((a, b) =>
              a.label.localeCompare(b.label),
            )}
            base={{ grundzeichen: "ohne" }}
          />
          <ZeichenText name="text" />
          <ZeichenText name="name" />
          <ZeichenText name="organisationName" />
          <ZeichenText name="farbe" type="color" />
        </Box>
        <Box sx={{ width: { xs: "auto", md: 200 } }}>
          <ZeichenPreview />
        </Box>
      </Stack>
    </Context.Provider>
  );
}

function ZeichenSelect({
  name,
  options,
  base,
}: {
  name: string;
  options: Array<{ id: string; label: string }>;
  base?: TaktischesZeichen;
}) {
  const { t } = useTranslation("taktisches-zeichen");
  const { isSubmitting } = useFormikContext();

  return (
    <ZeichenField name={name}>
      <Field
        name={useFieldName(name)}
        label={t(`${name}.label`)}
        component={Select}
        fullWidth
        disabled={isSubmitting}
        formControl={{ sx: { width: "100%" } }}
        renderValue={(val: string) =>
          options.find((o) => o.id === val)?.label ?? ""
        }
      >
        <MenuItem key="" value="">
          <ListItemText primary={<em>{t(`${name}.none`)}</em>} inset />
        </MenuItem>
        {options.map((g) => (
          <MenuItem key={g.id} value={g.id}>
            <ListItemIcon>
              <TaktischesZeichenComp
                {...({ ...base, [name]: g.id } as TaktischesZeichen)}
                width={24}
              />
            </ListItemIcon>
            <ListItemText primary={g.label} />
          </MenuItem>
        ))}
      </Field>
    </ZeichenField>
  );
}

function ZeichenText({ name, type }: { name: string; type?: string }) {
  const { t } = useTranslation("taktisches-zeichen");
  const { isSubmitting } = useFormikContext();

  return (
    <ZeichenField name={name}>
      <Field
        name={useFieldName(name)}
        label={t(`${name}.label`)}
        component={TextField}
        type={type}
        fullWidth
        disabled={isSubmitting}
      />
    </ZeichenField>
  );
}

function useFieldName(name: string) {
  const { prefix } = useContext(Context);
  return prefix ? [prefix, name].join(".") : name;
}

function useFormValues(): ZeichenValues {
  const { prefix } = useContext(Context);
  const { values } = useFormikContext<any>();
  return prefix ? values[prefix] : values;
}

function ZeichenField({ name, children }: PropsWithChildren<{ name: string }>) {
  const accepted = useAccepts(name as any);
  return accepted ? (
    <Grid item xs={12} md={6} lg={4}>
      {children}
    </Grid>
  ) : null;
}

function ZeichenPreview() {
  const tz = useTaktischesZeichenValues();
  return tz.grundzeichen || tz.symbol ? (
    <TaktischesZeichenComp {...tz} />
  ) : null;
}

function useTaktischesZeichenValues(): TaktischesZeichen {
  const zeichen = useFormValues();
  const grundzeichen = parseValue<GrundzeichenId>(zeichen, "grundzeichen");

  return {
    grundzeichen,
    fachaufgabe: parseValue(zeichen, "fachaufgabe", grundzeichen),
    organisation: parseValue(zeichen, "organisation", grundzeichen),
    einheit: parseValue(zeichen, "einheit", grundzeichen),
    verwaltungsstufe: parseValue(zeichen, "verwaltungsstufe", grundzeichen),
    funktion: parseValue(zeichen, "funktion", grundzeichen),
    symbol: parseValue(zeichen, "symbol", grundzeichen),
    text: parseValue(zeichen, "text", grundzeichen),
    name: parseValue(zeichen, "name", grundzeichen),
    organisationName: parseValue(zeichen, "organisationName", grundzeichen),
    farbe: parseValue(zeichen, "farbe", grundzeichen),
  };
}

function parseValue<T extends string>(
  values: ZeichenValues,
  type: keyof Omit<TaktischesZeichen, "skipFontRegistration">,
  grundzeichen?: GrundzeichenId,
): T | undefined {
  const value = values[type];
  const compType = getComponentType(type);
  return value === undefined ||
    value === "" ||
    !isAccepted(compType, grundzeichen)
    ? undefined
    : (value as T);
}

function getComponentType(
  type: keyof Omit<TaktischesZeichen, "skipFontRegistration">,
): ComponentType | "grundzeichen" {
  switch (type) {
    case "text":
      return "symbol";
    case "organisationName":
      return "name";
    default:
      return type;
  }
}

function useAccepts(
  type: keyof Omit<TaktischesZeichen, "skipFontRegistration">,
) {
  const { grundzeichen } = useFormValues();
  return isAccepted(getComponentType(type), grundzeichen);
}

function isAccepted(
  type: ComponentType | "grundzeichen",
  grundzeichen?: string,
) {
  if (type === "grundzeichen") return true;
  const grund = grundzeichens.find((g) => g.id === grundzeichen);
  return grund
    ? (grund.accepts?.includes(type) ?? true)
    : ["symbol"].includes(type);
}
