import { type ReactNode } from "react";
import { useServiceWorkerInstallReady } from "./hooks/install";
import { useServiceWorkerRegistration } from "./hooks/registration";

/**
 * Registers the service worker and provides prompts to install and update the application.
 */
export function ServiceWorkerUpdateProvider({
  children,
}: {
  children: ReactNode;
}) {
  useServiceWorkerInstallReady();
  useServiceWorkerRegistration();

  return <>{children}</>;
}
