import L, { Coords, DoneCallback, Util } from "leaflet";

(L.TileLayer as any).BetterWMS = L.TileLayer.WMS.extend({
  createTile: function (coords: Coords, done: DoneCallback) {
    const img = document.createElement("img");
    img.setAttribute("role", "presentation");
    // img.setAttribute('crossorigin', 'anonymous');
    img.onerror = Util.bind(this._tileOnError, this, done, img);
    img.onload = Util.bind(this._tileOnLoad, this, done, img);
    img.src = this.getTileUrl(coords);
    return img;
  },

  onAdd: function (map: L.Map) {
    L.TileLayer.WMS.prototype.onAdd.call(this, map);
  },

  onRemove: function (map: L.Map) {
    L.TileLayer.WMS.prototype.onRemove.call(this, map);
  },
});
