import { useMemo } from "react";
import type { ModuleInfo, ModuleSpec } from "../types";
import menuItem from "./MenuItem";
import { Icon, id, label, route } from "./config";
import { useTableauRoutes } from "./routes";

export { Icon, route } from "./config";
export * from "./routes/icons";

const module: ModuleInfo = {
  id,
  label,
  icon: Icon,
  trackingContext: "Tableau",
  supportsMissions: true,
  route,
};

export function useTableauModule(): ModuleSpec {
  const routes = useTableauRoutes(module);

  return useMemo(
    () => ({
      ...module,
      enabled: (license) => Boolean(license.missionApiKey),
      dataProviders: [],
      menuItem,
      routes,
      dashboardTiles: [{ label, to: route, icon: Icon }],
      experiments: [{ id: `${id}:mission-map-view` }],
    }),
    [routes],
  );
}
