import { portalServer } from "../../../config";
import { FetchProvider, useData } from "../../../data-provider";
import { daily, type DataProvider, StorageCache } from "../../../db/provider";
import type { PatientTriageCategory } from "../../../missions/api";
import { id, label } from "../config";
import type { Algorithm } from "./types";

type AlgorithmSource = {
  algorithms: Record<
    string,
    {
      title: string;
      startingID: number;
      steps: Record<
        string,
        { text?: ReadonlyArray<string> } & (
          | { type: "question"; answers: Record<string, number> }
          | { type: "result"; sk: string }
        )
      >;
    }
  >;
  sk: Record<string, PatientTriageCategory>;
};

export const algorithmsDataProvider: DataProvider<
  ReadonlyArray<Algorithm>
> = () => ({
  id: `${id}:algorithms`,
  label,
  provider: new FetchProvider({
    url: `${portalServer}/algorithms.json`,
    transform: toAlgorithms,
  }),
  cache: new StorageCache(`${id}:algorithms`),
  getStatistics: async (value) => ({
    count: value.length,
    totalSize: JSON.stringify(value).length,
  }),
  updateInterval: daily,
});

function toAlgorithms(data: AlgorithmSource): ReadonlyArray<Algorithm> {
  return Object.entries(data.algorithms).map(([algorithmId, alg]) => ({
    id: parseInt(algorithmId, 10),
    title: alg.title,
    startingID: alg.startingID,
    steps: Object.entries(alg.steps).map(([stepId, step]) => {
      return step.type === "question"
        ? {
            id: parseInt(stepId, 10),
            type: "question",
            text: step.text ?? [],
            answers: step.answers,
          }
        : {
            id: parseInt(stepId, 10),
            type: "result",
            text: step.text ?? [],
            triageCategory: data.sk[step.sk],
          };
    }),
  }));
}

export function useAlgorithms(): ReadonlyArray<Algorithm> {
  return useData(`${id}:algorithms`);
}
