import { lazy, useMemo } from "react";
import { Outlet, type RouteObject } from "react-router";
import ModuleContextProvider from "../../../components/ModuleContext";
import type { ModuleInfo } from "../../types";
import { route } from "../config";
import { useNewMissionAnnouncer } from "../speech";

const TableauDetails = lazy(() => import("./TableauDetails"));
const TableauIndex = lazy(() => import("./TableauIndex"));

export function useTableauRoutes(module: ModuleInfo): Array<RouteObject> {
  return useMemo(
    () => [
      {
        path: route,
        element: (
          <ModuleContextProvider module={module}>
            <TableauHooks />
            <Outlet />
          </ModuleContextProvider>
        ),
        children: [
          { index: true, element: <TableauIndex /> },
          { path: ":id/:sectionId?", element: <TableauDetails /> },
        ],
      },
    ],
    [module],
  );
}

function TableauHooks() {
  useNewMissionAnnouncer();
  return null;
}
