import { useEffect } from "react";
import { useRecoilSnapshot } from "recoil";
import { enableRecoilLogging } from "../config";
import { logger } from "../log";

/**
 * Observe recoil snapshots and print them to the console. Only active in development.
 */
const RecoilObserver = enableRecoilLogging
  ? DevelopmentRecoilObserver
  : ProductionRecoilObserver;
export default RecoilObserver;

const log = logger("recoil");

function ProductionRecoilObserver() {
  return null;
}

function DevelopmentRecoilObserver() {
  const snapshot = useRecoilSnapshot();
  useEffect(() => {
    const nodes = snapshot.getNodes_UNSTABLE({ isModified: true });
    for (let node of nodes) {
      log.silly(node.key, snapshot.getLoadable(node).contents);
    }
  }, [snapshot]);

  return null;
}
