import { useFormikContext } from "formik";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useCapitalize } from "../../i18n";
import CloseButton, { type CloseIntent } from "../CloseButton";
import SubmitButton from "../SubmitButton";

export type Props = {
  form?: string;
  onClose?: () => void;
  allowPristine?: boolean;
  closeIntent?: CloseIntent;
  closeLabel?: ReactNode;
  submitIcon?: ReactNode;
  submitLabel?: ReactNode;
};

export default function FormActions({
  onClose,
  closeLabel,
  closeIntent,
  allowPristine,
  submitIcon,
  submitLabel,
  ...props
}: Props) {
  const { t } = useTranslation();
  const capitalize = useCapitalize();
  const { dirty, isValid, isSubmitting } = useFormikContext();

  return (
    <>
      {onClose ? (
        <CloseButton
          disabled={isSubmitting}
          onClick={onClose}
          intent={closeIntent}
          children={closeLabel}
        />
      ) : undefined}
      <SubmitButton
        icon={submitIcon}
        disabled={(!allowPristine && !dirty) || !isValid}
        loading={isSubmitting}
        {...props}
      >
        {submitLabel ?? capitalize(t("save"))}
      </SubmitButton>
    </>
  );
}
