import { Coords, DoneCallback, TileLayer, Util } from "leaflet";
import { getResource, ResourceParams } from "../../db/resource";

if (!("_cacheConfigured" in TileLayer.prototype)) {
  TileLayer.include({
    _cacheConfigured: true,
    createTile(coords: Coords, done: DoneCallback): HTMLElement {
      const img = document.createElement("img");
      const onError = Util.bind(this._tileOnError, this, done, img);
      img.setAttribute("role", "presentation");
      img.setAttribute("crossorigin", "anonymous");
      img.addEventListener("error", onError);
      img.addEventListener(
        "load",
        Util.bind(this._tileOnLoad, this, done, img),
      );

      getResource(this.getTileUrl(coords), mapTileResourceParams)
        .then((res) => (img.src = res.data))
        .catch(onError);

      return img;
    },
  });
}

export const mapTileResourceParams: ResourceParams = {
  overrideExpiry: 365 * 24 * 60 * 60 * 1000,
};
