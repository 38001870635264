import L from "leaflet";
import { useEffect, useMemo } from "react";
import { useMap } from "react-leaflet";
import { useAuthenticated } from "../../auth";
import { apiEndpoint } from "../../config";
import { WMSMapLayerProvider } from "../../license";
import "./BetterWMS";
import crsConfig from "./crs";

export type Props = {
  provider: WMSMapLayerProvider;
  layerId: string;
  options?: any;
};

export function CustomWMSLayer({ provider, layerId, options }: Props) {
  const { id: deviceId } = useAuthenticated();
  const map = useMap();
  const layerSpec = provider.layers.find((l) => l.id === layerId);
  if (!layerSpec) throw new Error(`Invalid map layer ID: ${layerId}`);

  const layer = useMemo(() => {
    const url = `${apiEndpoint}/wms/${deviceId}/${provider.id}`;
    return new (L.TileLayer as any).BetterWMS(url, {
      format: "image/png",
      transparent: true,
      opacity: 0.5,
      version: "1.3.0",
      crs: getCRS(provider.crs),
      layers: layerSpec.layer,
      ...options,
    });
  }, [provider, layerSpec, deviceId, options]);

  useEffect(() => {
    layer.addTo(map);

    return () => {
      map.removeLayer(layer);
    };
  }, [map, layer]);

  return null;
}

function getCRS(crs?: string): any {
  if (!crs) return crs;
  if (!(crs in crsConfig)) throw new Error(`Invalid CRS: ${crs}`);
  return crsConfig[crs];
}
