import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  type PropsWithChildren,
} from "react";
import {
  atom,
  atomFamily,
  selector,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { Logger, logger } from "../log";
import { Cache, DataProvider } from "./types";

export type TimedError = {
  timestamp: number;
  message: string;
};

export type DataStatistics = {
  count: number;
  totalSize?: number;
};

export type DataProviderConfig<T> = {
  id: string;
  label: string;
  provider: DataProvider<T>;
  cache?: Cache<T>;
  updateInterval?: number;
  getStatistics?(value: T): Promise<DataStatistics>;
};

export type DataProviderState = {
  id: string;
  ready: boolean;
  loading: boolean;
  statistics?: DataStatistics;
  error?: TimedError;
  lastUpdate?: number;
};

export type DataProvidersState = {
  ready: boolean;
  providers: Array<DataProviderState & { label: string }>;
  statistics?: DataStatistics;
};

const providersStateAtom = atom<{
  [id: string]: { label: string; ready: boolean; error?: TimedError };
}>({
  key: "data:providers",
  default: {},
});

const providersStateSelector = selector<DataProvidersState>({
  key: "data:providers-selector",
  get: ({ get }) => {
    const state = get(providersStateAtom);
    const providers = Object.keys(state)
      .sort((a, b) => a.localeCompare(b))
      .map((id) => ({ ...get(providerStateAtom(id)), label: state[id].label }));

    const ready = providers.length > 0 && providers.every((p) => p.ready);

    const statistics = providers.length
      ? providers.reduce(
          (a, b) =>
            b.statistics
              ? {
                  count: a.count + b.statistics.count,
                  totalSize: a.totalSize + (b.statistics.totalSize || 0),
                }
              : a,
          {
            count: 0,
            totalSize: 0,
          },
        )
      : undefined;

    return { providers, ready, statistics };
  },
});

const providerStateAtom = atomFamily<DataProviderState, string>({
  key: "data:provider-state",
  default: (id) => ({ id, ready: false, loading: false }),
});

export const providerValueAtom = atomFamily<any, string>({
  key: "data:provider-value",
  default: undefined,
});

export type OfflineCacheProviderProps = PropsWithChildren<{
  providers: DataProviderConfig<any>[];
}>;

type DataProvidersContext = {
  providers: Array<DataProviderConfig<any>>;
};

const Context = createContext<DataProvidersContext>({ providers: [] });

function useDataProvidersContext() {
  return useContext(Context);
}

export function OfflineCacheProvider({
  providers,
  children,
}: OfflineCacheProviderProps) {
  const setState = useSetRecoilState(providersStateAtom);
  const onInitialized = useCallback(
    (providerId: string, error?: TimedError) => {
      setState((s) => ({
        ...s,
        [providerId]: { ...s[providerId], error, ready: !error },
      }));
    },
    [setState],
  );

  useEffect(() => {
    setState(
      providers.reduce(
        (a, p) => ({ ...a, [p.id]: { label: p.label, ready: false } }),
        {},
      ),
    );
  }, [providers, setState]);

  const context = useMemo(() => ({ providers }), [providers]);

  return (
    <Context.Provider value={context}>
      {providers.map((provider) => (
        <DataProviderContextProvider
          key={provider.id}
          provider={provider}
          onInitialized={onInitialized}
        />
      ))}
      {children}
    </Context.Provider>
  );
}

function useProvider<T>(providerId: string): DataProviderConfig<T> {
  const { providers } = useDataProvidersContext();
  const provider = providers.find((p) => p.id === providerId);
  if (!provider)
    throw new Error(
      `No such provider: ${providerId}, available providers: ${providers
        .map((p) => p.id)
        .sort()
        .join(", ")}`,
    );
  return provider;
}

export function useSetProviderData(providerId: string) {
  const provider = useProvider(providerId);
  const setState = useSetRecoilState(providerStateAtom(provider.id));
  const setValue = useSetRecoilState(providerValueAtom(provider.id));

  return useCallback(
    async (data: any, timestamp: number = Date.now()) => {
      const log = getLogger(provider);
      setValue(data);
      setState((s) => ({
        ...s,
        loading: false,
        error: undefined,
        lastUpdate: timestamp,
      }));

      if (provider.cache) {
        // async!
        provider.cache
          .save({ value: data, timestamp })
          .catch((error) =>
            log.error(`[${provider.id}] error storing value to cache:`, error),
          );
      }

      if (provider.getStatistics) {
        // async!
        provider
          .getStatistics(data)
          .then((statistics) => setState((s) => ({ ...s, statistics })))
          .catch((error) =>
            log.error(`[${provider.id}] error calculating statistics:`, error),
          );
      }
    },
    [provider, setValue, setState],
  );
}

export function useUpdateProvider(providerId: string) {
  const provider = useProvider(providerId);
  const setState = useSetRecoilState(providerStateAtom(provider.id));
  const setData = useSetProviderData(providerId);

  return useCallback(async () => {
    const log = getLogger(provider);
    const timestamp = Date.now();
    log.debug(`[${provider.id}] updating…`);
    setState((s) => ({ ...s, loading: true }));

    try {
      const data = await provider.provider.getData();
      await setData(data, timestamp);
      log.debug(`[${provider.id}] updated.`);
    } catch (error: any) {
      log.warn(`[${provider.id}] error fetching data:`, error);
      await setState((s) => ({
        ...s,
        loading: false,
        error: { timestamp, message: error.message },
      }));
    }
  }, [provider, setState, setData]);
}

function DataProviderContextProvider({
  provider,
  onInitialized,
  children,
}: PropsWithChildren<{
  provider: DataProviderConfig<any>;
  onInitialized(providerId: string, error?: TimedError): void;
}>) {
  const updateTimer = useRef<number>();
  const setState = useSetRecoilState(providerStateAtom(provider.id));
  const setData = useSetProviderData(provider.id);
  const update = useUpdateProvider(provider.id);

  const initialize = useCallback(async () => {
    const log = getLogger(provider);
    log.debug(`[${provider.id}] initializing…`);
    setState((s) => ({ ...s, loading: true }));

    const timestamp = Date.now();

    try {
      let value: any = undefined;
      if (provider.cache) {
        const cached = await provider.cache.load();
        if (cached) value = cached.value;
      }

      if (value === undefined) {
        log.debug(`[${provider.id}] no cached data, fetching initial data…`);
        value = await provider.provider.getData();
        log.debug(`[${provider.id}] initial data ready.`);
      } else {
        log.debug(`[${provider.id}] cached data found, scheduling update`);
        window.setTimeout(update, 0);
      }

      await setData(value, timestamp);
      setState((s) => ({ ...s, loading: false, ready: true }));
      onInitialized(provider.id);
      log.debug(`[${provider.id}] initialized.`);
    } catch (error: any) {
      setState((s) => ({
        ...s,
        loading: false,
        error: { timestamp, message: error.message },
      }));
    }

    if (provider.updateInterval) {
      log.debug(
        `[${provider.id}] scheduling periodic update every ${provider.updateInterval} ms`,
      );
      updateTimer.current = window.setInterval(update, provider.updateInterval);
    }
  }, [provider, setData, setState, update, onInitialized]);

  useEffect(() => {
    initialize();

    return () => {
      const timer = updateTimer.current;
      if (timer) {
        const log = getLogger(provider);
        log.debug(`[${provider.id}] stopping update timer`);
        window.clearInterval(timer);
      }
    };
  }, [initialize, provider]);

  return <>{children}</>;
}

export function useDataProviders() {
  return useRecoilValue(providersStateSelector);
}

export function useDataProviderState(id: string): DataProviderState {
  return useRecoilValue(providerStateAtom(id));
}

export function useData<T>(id: string, defaultValue?: T): T {
  const value = useRecoilValue(providerValueAtom(id));
  if (!value && defaultValue === undefined)
    throw new Error(`No data for provider: ${id}`);
  return value || defaultValue;
}

export function useSetDataCache<T>(id: string) {
  const setState = useSetRecoilState(providerValueAtom(id));
  return useCallback((value: T) => setState(value), [setState]);
}

function getLogger(provider: DataProviderConfig<any>): Logger {
  return logger(`data/${provider.id}`);
}
