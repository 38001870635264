import { Box, Toolbar } from "@mui/material";
import { type ReactNode } from "react";

export default function Content({ children }: { children: ReactNode }) {
  return (
    <>
      <Box sx={{ paddingTop: "env(safe-area-inset-top)" }}>
        <Toolbar />
      </Box>
      {children}
    </>
  );
}
