import { lazy, useMemo } from "react";
import { type RouteObject } from "react-router";
import { useModulEnabled } from "../../../auth";
import ModuleContextProvider from "../../../components/ModuleContext";
import type { ModuleInfo } from "../../types";
import { route } from "../config";

const GefahrgutDetails = lazy(() => import("./GefahrgutDetails"));
const GefahrgutLight = lazy(() => import("./GefahrgutLight"));
const GefahrgutListe = lazy(() => import("./GefahrgutListe"));
const GefahrgutUN = lazy(() => import("./GefahrgutUN"));

export function useGefahrgutRoutes(module: ModuleInfo): Array<RouteObject> {
  const gefahrgutEnabled = useModulEnabled("gefahrgut");

  return useMemo(
    () => [
      {
        path: route,
        element: <ModuleContextProvider module={module} />,
        children: gefahrgutEnabled
          ? [
              { index: true, element: <GefahrgutListe /> },
              { path: ":id", element: <GefahrgutDetails /> },
              { path: "un/:un", element: <GefahrgutUN /> },
            ]
          : [{ index: true, element: <GefahrgutLight /> }],
      },
    ],
    [module, gefahrgutEnabled],
  );
}
