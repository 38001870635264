import { lazy, useMemo } from "react";
import { Navigate, type RouteObject } from "react-router";
import ModuleContextProvider from "../../../components/ModuleContext";
import type { ModuleInfo } from "../../types";
import { route } from "../config";
import StatisticsWrapper from "./StatisticsWrapper";

const Overview = lazy(() => import("./Overview"));
const Map = lazy(() => import("./Map"));
const Resources = lazy(() => import("./Resources"));
const Reports = lazy(() => import("./Reports"));
const Patients = lazy(() => import("./Patients"));

export function useStatisticsRoutes(module: ModuleInfo): Array<RouteObject> {
  return useMemo(
    () => [
      {
        path: route,
        element: (
          <ModuleContextProvider module={module}>
            <StatisticsWrapper />
          </ModuleContextProvider>
        ),
        children: [
          { index: true, element: <Navigate to="overview" /> },
          { path: "overview", element: <Overview /> },
          { path: "map", element: <Map /> },
          { path: "resources", element: <Resources /> },
          { path: "reports", element: <Reports /> },
          { path: "patients", element: <Patients /> },
        ],
      },
    ],
    [module],
  );
}
