import qs from "query-string";
import { apiEndpoint } from "../config";
import { Koordinaten } from "../types";

export async function geocodeAddress({
  address,
}: {
  address: string;
}): Promise<{ formatted: string; location: Koordinaten }> {
  const response = await fetch(
    `${apiEndpoint}/geocode?${qs.stringify({ address })}`,
  );
  if (!response.ok)
    throw new Error(`Fehler: ${response.status} ${response.statusText}`);
  const geocode = await response.json();
  if (geocode.status !== "OK")
    throw new Error("Adresse konnte nicht aufgelöst werden.");
  const {
    formatted_address,
    geometry: {
      location: { lat, lng },
    },
  } = geocode.results[0];

  return {
    formatted: formatted_address,
    location: { latitude: lat, longitude: lng },
  };
}

const what3wordsApiKey = "UCM837WM";

export async function geocodeWhat3words({
  words,
}: {
  words: string;
}): Promise<{ location: Koordinaten }> {
  const url = qs.stringifyUrl({
    url: "https://api.what3words.com/v3/convert-to-coordinates",
    query: { words: words },
  });
  const response = await fetch(url, {
    headers: { "X-Api-Key": what3wordsApiKey },
  });
  const result = await response.json();
  if (result.error) throw new Error(result.error.message);
  const { lat, lng } = result.coordinates;
  return { location: { latitude: lat, longitude: lng } };
}

export async function getWhat3words({
  latitude,
  longitude,
}: Koordinaten): Promise<string> {
  const url = qs.stringifyUrl({
    url: "https://api.what3words.com/v3/convert-to-3wa",
    query: { coordinates: `${latitude},${longitude}`, language: "de" },
  });
  const response = await fetch(url, {
    headers: { "X-Api-Key": what3wordsApiKey },
  });
  const result = await response.json();
  if (result.error) throw new Error(result.error.message);
  return result.words;
}
