import { Alert } from "@mui/material";
import { ErrorLike } from "../types";

export type Props = {
  error: unknown;
  message?: string;
  card?: boolean;
  className?: string;
};

export default function ErrorMessage({
  error,
  message,
  card,
  className,
}: Props) {
  return (
    <Alert
      severity="error"
      variant={card ? "filled" : "standard"}
      className={className}
    >
      {message || (error as ErrorLike).message}
    </Alert>
  );
}
