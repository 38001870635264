import { ListItemButton } from "@mui/material";
import { useLocation, useResolvedPath } from "react-router-dom";
import LinkBehavior from "./LinkBehavior";

export default function ListItemLink({ to, exact, children, ...props }: any) {
  const path = useResolvedPath(to);
  let location = useLocation();
  const active = location.pathname.startsWith(path.pathname);

  return (
    <ListItemButton
      component={LinkBehavior}
      selected={active}
      to={to}
      {...props}
    >
      {children}
    </ListItemButton>
  );
}
