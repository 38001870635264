import { lazy, useMemo } from "react";
import { type RouteObject } from "react-router";
import ModuleContextProvider from "../../../components/ModuleContext";
import type { ModuleInfo } from "../../types";
import { route } from "../config";

const KartePage = lazy(() => import("./KartePage"));

export function useKarteRoutes(module: ModuleInfo): Array<RouteObject> {
  return useMemo(
    () => [
      {
        path: route,
        element: (
          <ModuleContextProvider module={module}>
            <KartePage />
          </ModuleContextProvider>
        ),
      },
    ],
    [module],
  );
}
