import { lazy, useMemo } from "react";
import { Outlet, type RouteObject } from "react-router";
import ModuleContextProvider from "../../../components/ModuleContext";
import type { ModuleInfo } from "../../types";
import { route } from "../config";

const RettungskarteDetails = lazy(() => import("./RettungskarteDetails"));
const RettungskartenHersteller = lazy(
  () => import("./RettungskartenHersteller"),
);
const RettungskartenListe = lazy(() => import("./RettungskartenListe"));

export function useRettungskartenRoutes(
  module: ModuleInfo,
): Array<RouteObject> {
  return useMemo(
    () => [
      {
        path: route,
        element: (
          <ModuleContextProvider module={module}>
            <Outlet />
          </ModuleContextProvider>
        ),
        children: [
          { index: true, element: <RettungskartenListe /> },
          {
            path: ":herstellerId",
            children: [
              { index: true, element: <RettungskartenHersteller /> },
              { path: ":modellId", element: <RettungskarteDetails /> },
            ],
          },
        ],
      },
    ],
    [module],
  );
}
