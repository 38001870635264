import { GlobalCSS } from "@rescuetablet/theme";
import { lazy, Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import { RecoilRoot } from "recoil";
import Delay from "./components/Delay";
import ErrorBoundary from "./components/ErrorBoundary";
import GlobalLoading from "./components/GlobalLoading";
import RecoilObserver from "./components/RecoilObserver";
import DatabaseRecoilStore from "./db/recoil";
import "./i18n";
import { useLicense } from "./license";
import { ThemeProvider } from "./theme";
import { UpdateProvider } from "./update";

const Main = lazy(() => import("./Main"));
const Welcome = lazy(() => import("./pages/welcome"));

const fallback = (
  <Delay>
    <GlobalLoading />
  </Delay>
);

export default function App() {
  return (
    <RecoilRoot>
      <DatabaseRecoilStore>
        <UpdateProvider>
          <ThemeProvider>
            <GlobalCSS />
            <ErrorBoundary global>
              <HelmetProvider>
                <RecoilObserver />
                <Suspense fallback={fallback}>
                  <Content />
                </Suspense>
              </HelmetProvider>
            </ErrorBoundary>
          </ThemeProvider>
        </UpdateProvider>
      </DatabaseRecoilStore>
    </RecoilRoot>
  );
}

function Content() {
  const license = useLicense();
  return license ? <Main /> : <Welcome />;
}
