import { Box, Card, CardContent, Chip, Tab, Tabs } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import PersonnelLabel from "../../../components/PersonnelLabel";
import Title from "../../../components/Title";
import { useCapitalize } from "../../../i18n";
import { useLoadedMissions } from "../../../missions";
import {
  type Mission,
  type Personnel,
  type PersonnelCount,
} from "../../../missions/api";
import { Icon as PatientIcon } from "../../patienten";
import {
  Icon as EinsatzIcon,
  PersonnelIcon,
  ResourceIcon,
} from "../../tableau";
import { id, route } from "../config";

const tabs = [
  { slug: "overview" },
  { slug: "map" },
  { slug: "resources" },
  { slug: "reports" },
  { slug: "patients" },
] as const;

export type TabId = (typeof tabs)[number]["slug"];

export type Props = {
  tab: TabId;
};

export default function Nav({ tab }: Props) {
  const { t } = useTranslation(id);
  const capitalize = useCapitalize();
  const navigate = useNavigate();

  return (
    <Card sx={{ mb: 1 }}>
      <CardContent>
        <Title>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <span>{t("label")}</span>
            <Statistics />
          </Box>
        </Title>
      </CardContent>
      <Tabs
        variant="scrollable"
        value={tab}
        onChange={(_, value) => {
          const selectedTab = tabs.find(({ slug }) => slug === value);
          if (selectedTab) navigate(`${route}/${selectedTab.slug}`);
        }}
      >
        {tabs.map(({ slug }) => (
          <Tab key={slug} value={slug} label={capitalize(t(`tabs.${slug}`))} />
        ))}
      </Tabs>
    </Card>
  );
}

function Statistics() {
  const { t } = useTranslation(id);
  const capitalize = useCapitalize();
  const missions = useLoadedMissions();
  const { active, personnel, resources, patients } =
    useMissionStatistics(missions);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 1,
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <Chip
        icon={<EinsatzIcon />}
        label={`${active.toLocaleString()} ${t("mission.label", {
          count: active,
        })}`}
      />
      <Chip
        icon={<ResourceIcon />}
        label={`${resources.totalCount.toLocaleString()} ${t("resource", {
          count: resources.totalCount,
        })}`}
      />
      {personnel.totalCount > 0 && (
        <Chip
          icon={<PersonnelIcon />}
          label={
            <>
              {capitalize(t("personnel"))}:{" "}
              <PersonnelLabel personnel={personnel} />
            </>
          }
        />
      )}
      <Chip
        icon={<PatientIcon />}
        label={t("patients", { count: patients.totalCount })}
      />
    </Box>
  );
}

type ResourceStatistics = {
  totalCount: number;
};

type PatientStatistics = {
  totalCount: number;
};

type MissionStatistics = {
  active: number;
  personnel: Personnel;
  resources: ResourceStatistics;
  patients: PatientStatistics;
};

function useMissionStatistics(
  missions: ReadonlyArray<Mission>,
): MissionStatistics {
  return useMemo(
    () => ({
      active: missions.length,
      personnel: summarizePersonnel(missions),
      resources: summarizeResources(missions),
      patients: summarizePatients(missions),
    }),
    [missions],
  );
}

function summarizePersonnel(missions: ReadonlyArray<Mission>): Personnel {
  return missions.reduce(
    (p, m) => (m.personnel ? combinePersonnel(p, m.personnel) : p),
    { totalCount: 0 },
  );
}

function combinePersonnel(a: Personnel, b: Personnel): Personnel {
  return {
    totalCount: a.totalCount + b.totalCount,
    counts: combinePersonnelCounts([...(a.counts ?? []), ...(b.counts ?? [])]),
  };
}

function combinePersonnelCounts(
  counts: ReadonlyArray<PersonnelCount>,
): Array<PersonnelCount> {
  const byType = counts.reduce(
    (a, { type, count }) => ({ ...a, [type]: (a[type] ?? 0) + count }),
    {} as Record<string, number>,
  );
  return Object.entries(byType).map(([type, count]) => ({ type, count }));
}

function summarizeResources(
  missions: ReadonlyArray<Mission>,
): ResourceStatistics {
  return missions.reduce(
    (s, m) => ({ totalCount: s.totalCount + m.resources.length }),
    { totalCount: 0 },
  );
}

function summarizePatients(
  missions: ReadonlyArray<Mission>,
): ResourceStatistics {
  return missions.reduce(
    (s, m) => ({ totalCount: s.totalCount + m.patients.length }),
    { totalCount: 0 },
  );
}
