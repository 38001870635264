import { Badge, ListItemIcon, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import ListItemLink from "../../components/ListItemLink";
import { Icon, label, route } from "./config";
import { useAktivesGefahrgut } from "./data";

export default function GefahrgutMenuItem() {
  const { t } = useTranslation();
  const aktivesGefahrgut = useAktivesGefahrgut();

  return (
    <ListItemLink
      to={`${route}${
        aktivesGefahrgut ? `/${aktivesGefahrgut.gefahrgut.id}` : ""
      }`}
    >
      <ListItemIcon>
        <Badge variant="dot" color="secondary" invisible={!aktivesGefahrgut}>
          <Icon />
        </Badge>
      </ListItemIcon>
      <ListItemText primary={t(label)} />
    </ListItemLink>
  );
}
