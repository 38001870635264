import { Cache, CachedData, DataProviderConfig } from "../data-provider";
import { Modul } from "../license";
import { getInstance } from "./db";

export const minutely = 60 * 1000;
export const hourly = 60 * minutely;
export const daily = 24 * hourly;

export const storage = getInstance("data");

export class StorageCache<T> implements Cache<T> {
  constructor(readonly key: string) {}

  async load(): Promise<CachedData<T> | undefined> {
    const data = await (await storage).readData(this.key);
    return typeof data === "string" ? JSON.parse(data) : undefined;
  }

  async save(data: CachedData<T>): Promise<void> {
    await (await storage).writeData(this.key, JSON.stringify(data));
  }
}

export type DataProviderParams = {
  readonly demo: boolean;
  readonly license: string;
  readonly alarmKey: string;
  readonly portalKey: string;
  readonly module: ReadonlyArray<Modul>;
};

export type DataProvider<T> = (
  params: DataProviderParams,
) => DataProviderConfig<T> | undefined;
