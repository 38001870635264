import { useEffect, useRef } from "react";
import { TaskObserver, useRegisterTask } from "../../../background";
import { precacheResources } from "../../../db/resource";
import { useRettungskarten } from "../data";
import { RettungskarteKategorie } from "../types";

export function RettungskartenPrecache() {
  const kategorien = useRef(useRettungskarten());
  const registerTask = useRegisterTask();

  useEffect(() => {
    registerTask({
      id: "rettungskarten",
      label: "Rettungskarten",
      execute: (observer: TaskObserver) =>
        precacheRettungskarten(kategorien.current, observer),
    });
  }, [registerTask]);

  return null;
}

async function precacheRettungskarten(
  kategorien: RettungskarteKategorie[],
  observer: TaskObserver,
) {
  const urls = kategorien.flatMap((k) =>
    k.hersteller.flatMap((hersteller) => [
      hersteller.logoUrl,
      hersteller.logoUrldark,
      ...hersteller.modelle.flatMap((modell) => modell.url),
    ]),
  );
  await precacheResources(urls, observer);
}
