import { BugReport as DebugIcon } from "@mui/icons-material";
import {
  Checkbox,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { type ChangeEvent, useCallback } from "react";
import { atom, useRecoilState } from "recoil";
import { useAuthenticated } from "../auth";
import Dialog from "../components/dialog";
import { type Modul, moduleOptions, useSetLicense } from "../license";
import CloseButton from "./CloseButton";

const debugDialogAtom = atom({
  key: "debug:dialog",
  default: { open: false },
});

export default function DebugMenuItem() {
  const [dialog, setDialog] = useRecoilState(debugDialogAtom);

  return (
    <>
      <ListItemButton onClick={() => setDialog({ open: true })}>
        <ListItemIcon>
          <DebugIcon />
        </ListItemIcon>
        <ListItemText primary="Debug" />
      </ListItemButton>
      <DebugDialog
        open={dialog.open}
        onClose={() => setDialog({ open: false })}
      />
    </>
  );
}

function DebugDialog({ open, onClose }: { open: boolean; onClose(): void }) {
  const license = useAuthenticated();
  const setLicense = useSetLicense();

  const toggleModule = useCallback(
    (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const modul = e.target.name as Modul;
      const licenseModule = license.module.filter((m) => m !== modul);
      setLicense({
        ...license,
        module: checked ? [...licenseModule, modul] : licenseModule,
      });
    },
    [license, setLicense],
  );

  return (
    <Dialog title="Debug" open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <FormControl component="fieldset">
          <FormLabel component="legend">Module</FormLabel>
          <FormGroup>
            {[...moduleOptions].sort().map((modul) => (
              <FormControlLabel
                key={modul}
                label={modul}
                control={
                  <Checkbox
                    name={modul}
                    checked={license.module.includes(modul)}
                    onChange={toggleModule}
                  />
                }
              />
            ))}
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <CloseButton onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
}
