import { Badge, ListItemIcon, ListItemText } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import ListItemLink from "../../components/ListItemLink";
import { Icon, label, route } from "./config";
import { formularDataAtom } from "./formular-data";

export default function FormulareMenuItem() {
  const datas = useRecoilValue(formularDataAtom);
  const count = useMemo(
    () => datas?.filter((d) => d.status !== "submitted").length ?? 0,
    [datas],
  );
  const { t } = useTranslation();

  return (
    <ListItemLink to={route}>
      <ListItemIcon>
        <Badge color="secondary" badgeContent={count}>
          <Icon />
        </Badge>
      </ListItemIcon>
      <ListItemText primary={t(label)} />
    </ListItemLink>
  );
}
