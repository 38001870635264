import { GetApp as InstallIcon } from "@mui/icons-material";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAddInfo } from "../../../data";

export function useServiceWorkerInstallReady() {
  const listener = useListener();

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", listener);
    return () => window.removeEventListener("beforeinstallprompt", listener);
  }, [listener]);
}

declare global {
  interface BeforeInstallPromptEvent extends Event {
    platforms: ReadonlyArray<string>;
    prompt(): void;
  }

  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}

function useListener() {
  const addInfo = useAddInfo();
  const { t } = useTranslation("cache");

  return useCallback(
    (event: BeforeInstallPromptEvent) => {
      event.preventDefault();

      addInfo({
        id: "install-prompt",
        level: "info",
        title: t("install.title"),
        message: t("install.message"),
        icon: InstallIcon,
        actions: [
          {
            label: t("install.action"),
            variant: "contained",
            color: "primary",
            onClick: () => event.prompt(),
          },
        ],
      });
    },
    [addInfo, t],
  );
}
