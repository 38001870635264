import { Brush, Edit, FormatPaint } from "@mui/icons-material";
import { nanoid } from "nanoid";
import { useCallback } from "react";
import { type CanvasPath } from "react-sketch-canvas";
import {
  atom,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  type RecoilState,
} from "recoil";
import { providerValueAtom } from "../../data-provider";
import { storage, type DataProvider } from "../../db/provider";
import { id, label } from "./config";

export type WhiteboardData = Array<CanvasPath>;

export type Whiteboard = {
  id: string;
  strokeColor: string;
  strokeWidth: number;
  data: Array<CanvasPath>;
};

type Whiteboards = { boards: Array<Whiteboard> };

export const strokes = [
  { id: "2", strokeWidth: 2, icon: Edit },
  { id: "8", strokeWidth: 8, icon: Brush },
  { id: "24", strokeWidth: 24, icon: FormatPaint },
];

const defaults: Omit<Whiteboard, "id"> = {
  strokeColor: "black",
  strokeWidth: strokes[0].strokeWidth,
  data: [],
};

async function readStorage(): Promise<Whiteboards> {
  const data = await (await storage).readData(id);
  if (typeof data === "string") {
    const result = JSON.parse(data);
    if ("boards" in result) return result;
  }
  return { boards: [] };
}

async function writeStorage(data: Whiteboards) {
  await (await storage).writeData(id, JSON.stringify(data));
}

const dataProvider: DataProvider<Whiteboards> = () => ({
  id,
  label,
  provider: { getData: readStorage },
  getStatistics: async (value) => ({
    count: value.boards.length,
    totalSize: JSON.stringify(value).length,
  }),
});

export const dataProviders = [dataProvider];

const createBoardId = nanoid;

function createWhiteboard(id: string = createBoardId()): Whiteboard {
  return { ...defaults, id };
}

const whiteboardsAtom = providerValueAtom(id) as RecoilState<Whiteboards>;

export function useWhiteboardIds(): Array<string> {
  const { boards } = useRecoilValue(whiteboardsAtom);
  return boards.map((b) => b.id);
}

export function useWhiteboard(boardId: string): Whiteboard {
  const { boards } = useRecoilValue(whiteboardsAtom);
  return boards.find((b) => b.id === boardId) || createWhiteboard(boardId);
}

export function useUpdateWhiteboard(): (
  boardId: string,
  updater: (current: Whiteboard) => Whiteboard,
) => void {
  const setData = useSetRecoilState(whiteboardsAtom);

  return useCallback(
    (boardId, updater) => {
      setData((current) => {
        const nextData: Whiteboards = {
          ...current,
          boards: setWhiteboardData(current.boards, boardId, updater),
        };
        writeStorage(nextData);
        return nextData;
      });
    },
    [setData],
  );
}

function setWhiteboardData(
  boards: Array<Whiteboard>,
  boardId: string,
  updater: (current: Whiteboard) => Whiteboard,
): Array<Whiteboard> {
  const index = boards.findIndex((b) => b.id === boardId);
  return index < 0
    ? [...boards, updater(createWhiteboard(boardId))]
    : boards.map((b) => (b.id === boardId ? updater(b) : b));
}

export function useSetWhiteboardData(): (
  boardId: string,
  data: WhiteboardData,
) => void {
  const update = useUpdateWhiteboard();
  return useCallback(
    (boardId, data) => update(boardId, (current) => ({ ...current, data })),
    [update],
  );
}

export function useAddWhiteboard(): () => string {
  const setWhiteboard = useSetWhiteboardData();

  return useCallback(() => {
    const id = createBoardId();
    setWhiteboard(id, []);
    return id;
  }, [setWhiteboard]);
}

export function useRemoveWhiteboard(): (boardId: string) => string {
  const [{ boards }, setData] = useRecoilState(whiteboardsAtom);

  return useCallback(
    (boardId) => {
      const index = boards.findIndex((b) => b.id === boardId);
      if (index < 0) return boards[0].id;

      const nextBoards = boards.filter((_, i) => i !== index);

      if (!nextBoards.length) {
        nextBoards.push(createWhiteboard());
      }

      setData((current) => {
        const nextData: Whiteboards = { ...current, boards: nextBoards };
        writeStorage(nextData);
        return nextData;
      });

      return nextBoards[Math.min(index, nextBoards.length - 1)].id;
    },
    [boards, setData],
  );
}

const currentWhiteboardAtom = atom<string | undefined>({
  key: `${id}:current`,
  default: undefined,
});

export function useCurrentWhiteboard() {
  return useRecoilState(currentWhiteboardAtom);
}
