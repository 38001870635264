import { useEffect } from "react";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { logger } from "./log";
import NoSleep from "./nosleep";

const log = logger("wakelog");

export type WakeLockType = "wake-lock-api" | "nosleep";
export type WakeLockContext =
  | { active: false; type?: WakeLockType }
  | { active: true; type: WakeLockType };

const wakeLockAtom = atom<WakeLockContext>({
  key: "wakelock",
  default: { active: false },
});

export function WakeLockProvider() {
  const setState = useSetRecoilState(wakeLockAtom);

  useEffect(() => {
    function acquireWakeLock() {
      return (navigator as any).wakeLock
        .request("screen")
        .then((wakeLog: any) => {
          log.info("Wake log is active.");
          wakeLog.addEventListener("release", () => {
            log.info("Wake log was released.");
            setState({ active: false, type: "wake-lock-api" });
          });
          setState({ active: true, type: "wake-lock-api" });
        })
        .catch((error: Error) => {
          log.info("Failed to acquire wake lock: %s", error.message);
          setState({ active: false, type: "wake-lock-api" });
        });
    }

    if (
      "wakeLock" in navigator &&
      typeof (navigator as any).wakeLock.request === "function"
    ) {
      log.info("Requesting wake lock via Wake Lock API");
      acquireWakeLock();
      document.addEventListener("visibilitychange", (evt) => {
        if ((evt.target as any).visibilityState === "visible") {
          log.info("Page re-gained focus, re-enabling wake lock.");
          acquireWakeLock();
        }
      });
      return;
    }

    log.info(
      "Wake Lock API not available or failed, falling back to nosleep.js",
    );
    setState({ active: false, type: "nosleep" });

    const nosleep = new NoSleep("rescueTABLET")
      .on("enabled", () => {
        log.info("nosleep.js enabled");
        setState({ active: true, type: "nosleep" });
      })
      .on("error", (error) => {
        log.info("nosleep.js failed:", error);
        setState({ active: false, type: "nosleep" });
      });

    const events = ["click", "touch"];

    function enableNoSleep() {
      if (!nosleep.isEnabled) {
        log.info("Activating nosleep.js");
        nosleep.enable();
      }

      events.forEach((event) =>
        document.removeEventListener(event, enableNoSleep, false),
      );
    }

    events.forEach((event) =>
      document.addEventListener(event, enableNoSleep, false),
    );
  }, [setState]);

  return null;
}

export function useWakeLock(): WakeLockContext {
  return useRecoilValue(wakeLockAtom);
}
