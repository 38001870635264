import { Badge, ListItemIcon, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import ListItemLink from "../../components/ListItemLink";
import { Icon, id, route } from "./config";
import { useAktiverAtemschutzEinsatz, useAtemschutzEinsaetze } from "./data";
import { truppPath } from "./paths";

export default function PatientenMenuItem() {
  const einsaetze = useAtemschutzEinsaetze();
  const { einsatzId, truppId } = useAktiverAtemschutzEinsatz();
  const { t } = useTranslation(id);
  const url = einsatzId
    ? truppPath(einsatzId, truppId)
    : einsaetze.length
      ? truppPath(einsaetze[0].id, einsaetze[0].trupps[0]?.id)
      : undefined;

  return (
    <ListItemLink to={url ?? route}>
      <ListItemIcon>
        <Badge variant="dot" color="secondary" invisible={!url}>
          <Icon />
        </Badge>
      </ListItemIcon>
      <ListItemText primary={t("label-menu")} />
    </ListItemLink>
  );
}
