import { useEffect } from "react";
import { registerSW } from "virtual:pwa-register";
import { useCreateUpdateInfo } from "../../hooks";
import { log } from "./log";

const updateInterval = 60 * 60 * 1000;

export function useServiceWorkerRegistration() {
  const createUpdateInfo = useCreateUpdateInfo();

  useEffect(() => {
    log.info("Registering service worker...");

    const updateSW = registerSW({
      onRegisteredSW: (swUrl, reg) => {
        setInterval(() => checkForUpdate(swUrl, reg), updateInterval);
      },
      onNeedRefresh: () => {
        log.info("Application was updated.");
        createUpdateInfo(async () => updateSW());
      },
    });
  }, [createUpdateInfo]);
}

async function checkForUpdate(swUrl: string, reg: ServiceWorkerRegistration) {
  log.info("Checking for updates…");

  if (reg.installing || !navigator) return;
  if ("connection" in navigator && !navigator.onLine) return;

  const resp = await fetch(swUrl, {
    cache: "no-store",
    headers: {
      cache: "no-store",
      "cache-control": "no-cache",
    },
  });

  if (resp.status === 200) {
    await reg.update();
  }
}
