import { useMemo } from "react";
import type { ModuleInfo, ModuleSpec } from "../types";
import menuItem from "./MenuItem";
import { Icon, id, label, route } from "./config";
import { useDashboardRoutes } from "./routes";

export { route };

const module: ModuleInfo = {
  id,
  label,
  icon: Icon,
  route,
  trackingContext: "Dashboard",
};

export function useDashboardModule(): ModuleSpec {
  const routes = useDashboardRoutes(module);

  return useMemo(
    () => ({
      ...module,
      enabled: (license) => license.module.includes("dashboard"),
      dataProviders: [],
      menuItem,
      routes,
    }),
    [routes],
  );
}
