import { CapacitorUpdater } from "@capgo/capacitor-updater";
import { useEffect } from "react";
import { logger } from "../../log";
import { useCreateUpdateInfo } from "../hooks";

const log = logger("capgo");

export function useRegisterCapGo() {
  useEffect(() => {
    registerListeners();

    log.info("notifyAppReady");
    CapacitorUpdater.notifyAppReady()
      .then((result) =>
        log.info("notifyAppReady success: " + JSON.stringify(result)),
      )
      .catch((error) => log.error("notifyAppReady error: " + error.message));
  }, []);
}

export function useUpdateAvailableListener() {
  const createUpdateInfo = useCreateUpdateInfo();

  useEffect(() => {
    CapacitorUpdater.addListener("updateAvailable", (res) => {
      log.info("updateAvailable");
      createUpdateInfo(() => CapacitorUpdater.set(res.bundle));
    });
  }, [createUpdateInfo]);
}

async function registerListeners(): Promise<() => void> {
  const subscriptions = await Promise.all([
    CapacitorUpdater.addListener("download", (event) =>
      log.info("download: " + JSON.stringify(event)),
    ),
    CapacitorUpdater.addListener("noNeedUpdate", (event) =>
      log.info("noNeedUpdate: " + JSON.stringify(event)),
    ),
    CapacitorUpdater.addListener("updateAvailable", (event) =>
      log.info("updateAvailable: " + JSON.stringify(event)),
    ),
    CapacitorUpdater.addListener("downloadComplete", (event) =>
      log.info("downloadComplete: " + JSON.stringify(event)),
    ),
    CapacitorUpdater.addListener("majorAvailable", (event) =>
      log.info("majorAvailable: " + JSON.stringify(event)),
    ),
    CapacitorUpdater.addListener("updateFailed", (event) =>
      log.info("updateFailed: " + JSON.stringify(event)),
    ),
    CapacitorUpdater.addListener("downloadFailed", (event) =>
      log.info("downloadFailed: " + JSON.stringify(event)),
    ),
    CapacitorUpdater.addListener("appReloaded", () => log.info("appReloaded")),
    CapacitorUpdater.addListener("appReady", (event) =>
      log.info("appReady: " + JSON.stringify(event)),
    ),
  ]);

  return async () => Promise.allSettled(subscriptions.map((s) => s.remove()));
}
