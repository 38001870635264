import { portalServer } from "../../config";
import { FetchProvider, useData } from "../../data-provider";
import { DataProvider, minutely, StorageCache } from "../../db/provider";
import { id, label } from "./config";
import { Kontakt } from "./types";

const dataProvider: DataProvider<Kontakt[]> = ({ portalKey }) => ({
  id,
  label,
  provider: new FetchProvider({
    url: `${portalServer}/get-contacts.php?ORG_ACCESSKEY=${encodeURIComponent(
      portalKey,
    )}`,
    transform: (data) =>
      (data.CONTACT_LIST || []).map((c: any) => ({
        id: c.CONTACT_ID,
        name: c.CONTACT_DESCRIPTION,
        text: c.CONTACT_TEXT,
      })),
  }),
  cache: new StorageCache(id),
  getStatistics: async (value) => ({
    count: value.length,
    totalSize: JSON.stringify(value).length,
  }),
  updateInterval: minutely * 30,
});

export const dataProviders = [dataProvider];

export function useKontakte() {
  return useData<Kontakt[]>(id);
}
