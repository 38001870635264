import { addDays, isAfter, parseISO, subDays } from "date-fns";
import { ReactNode } from "react";

export type Identifiable = {
  readonly id: string;
};

export type ErrorLike = {
  readonly message: string;
  readonly code?: string | number;
  readonly stack?: string;
};

export type Koordinaten = {
  readonly latitude: number;
  readonly longitude: number;
};

export type Point = readonly [number | string, number | string];
export type Polygon = ReadonlyArray<Point>;

export type KartenObjektAction = {
  readonly id: string;
  readonly labelKey: string;
  readonly icon?: ReactNode;
  readonly to?: string;
  readonly href?: string;
};

export type KartenObjektLink = {
  readonly url: string;
  readonly label?: string;
};

export type KartenObjekt = {
  readonly id: string;
  readonly typ: string;
  readonly latitude: number;
  readonly longitude: number;
  readonly name: string;
  readonly icon?: string;
  readonly aliase?: string;
  readonly beschreibung?: string;
  readonly link?: string;
  readonly secondaryLink?: KartenObjektLink;
  readonly adresse?: string;
  readonly einsatzplan?: string;
  readonly interneNummer?: string;
  readonly risikoKlasse?: RisikoKlasse;
  readonly owned?: boolean;
  readonly segments?: Array<[number, number]>;
  readonly polygon?: Array<[number, number]>;
  readonly color?: string;
  readonly dashArray?: string;
  readonly opacity?: number;
  readonly actions?: KartenObjektAction[];
};

export type RisikoKlasse = "keine" | "niedrig" | "mittel" | "hoch";

export type VonBis = {
  readonly von: string;
  readonly bis: string;
};

export type Information = Identifiable &
  VonBis & {
    readonly text: string;
  };

export type Sperrung = KartenObjekt &
  VonBis & {
    readonly sperrungTyp?:
      | "Vollsperrung"
      | "Teilsperrung"
      | "Information"
      | "Bedingt Befahrbar"
      | "Behelfsweg"
      | "Befahrbarer Weg";
    readonly name: string;
    readonly ort: string;
  };

export function isVisible(item: VonBis) {
  const now = new Date();
  const von = parseISO(item.von);
  const bis = parseISO(item.bis);
  return isAfter(now, subDays(von, 1)) && isAfter(addDays(bis, 1), now);
}

export type CacheCollection = Identifiable & {
  readonly name: string;
  readonly count: number;
};
