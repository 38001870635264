import { portalServer } from "../config";
import { FetchProvider, useData } from "../data-provider";
import { KartenObjekt } from "../types";
import { limiter } from "./demo";
import { daily, DataProvider, StorageCache } from "./provider";

const id = "hydranten";

export const dataProvider: DataProvider<KartenObjekt[]> = ({
  demo,
  portalKey,
}) => ({
  id,
  label: "kartenobjekte:hydranten",
  provider: new FetchProvider({
    url: `${portalServer}/get-hydrants.php?ORG_ACCESSKEY=${encodeURIComponent(
      portalKey,
    )}`,
    transform: (data) =>
      limiter<KartenObjekt>(
        demo,
        300,
      )((data.HYDRANT_LIST || []).map(parseHydrant)),
  }),
  cache: new StorageCache(id),
  getStatistics: async (value) => ({
    count: value.length,
    totalSize: JSON.stringify(value).length,
  }),
  updateInterval: daily,
});

function parseHydrant(entry: any): KartenObjekt {
  return {
    id: entry.HYDRANT_ID,
    latitude: parseFloat(entry.HYDRANT_GEO_LAT),
    longitude: parseFloat(entry.HYDRANT_GEO_LNG),
    beschreibung: entry.HYDRANT_DESCRIPTION,
    ...(hydrantTypen[entry.HYDRANT_TYPE] || { typ: "hydrant", name: "" }),
  };
}

const hydrantTypen: { [typ: string]: { typ: string; name: string } } = {
  1: { typ: "hydrant", name: "Unterflurhydrant (H80)" },
  2: { typ: "hydrant", name: "Unterflurhydrant (H100)" },
  7: { typ: "hydrant", name: "Unterflurhydrant (H125)" },
  3: { typ: "hydrant", name: "Unterflurhydrant (H150)" },
  8: { typ: "hydrant", name: "Unterflurhydrant (H200)" },
  16: { typ: "hydrant", name: "Unterflurhydrant (H250)" },
  9: { typ: "hydrant", name: "Unterflurhydrant (H300)" },
  18: { typ: "hydrant", name: "Unterflurhydrant (H350)" },
  10: { typ: "hydrant", name: "Unterflurhydrant (H400)" },
  4: { typ: "hydrant_o", name: "Überflurhydrant (H80)" },
  11: { typ: "hydrant_o", name: "Überflurhydrant (H100)" },
  12: { typ: "hydrant_o", name: "Überflurhydrant (H150)" },
  13: { typ: "hydrant_o", name: "Überflurhydrant (H200)" },
  17: { typ: "hydrant_o", name: "Überflurhydrant (H250)" },
  14: { typ: "hydrant_o", name: "Überflurhydrant (H300)" },
  19: { typ: "hydrant_o", name: "Überflurhydrant (H350)" },
  15: { typ: "hydrant_o", name: "Überflurhydrant (H400)" },
  5: { typ: "hydrant", name: "Offenes Gewässer" },
  6: { typ: "hydrant", name: "Zisterne / Sonstiges" },
  666: {
    typ: "hydrant",
    name: "Mess,- Regel- oder Spülhydrant (nicht für Feuerwehr)",
  },
};

export function useHydranten() {
  return useData<KartenObjekt[]>(id, []);
}
