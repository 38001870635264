import { FirebaseFirestore } from "@capacitor-firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { firebaseContextAtom } from "../firebase";
import { notEmpty } from "../utils";
import type {
  Identifiable,
  PatientCreatedEvent,
  PatientDeletedEvent,
  PatientUpdatedEvent,
} from "./api";
import { log, statePrefix } from "./config";
import {
  useMissionsManager,
  type ManagedMission,
  type MissionsManagerEventListener,
} from "./manager";
import type { FullMission } from "./types";

export type ActiveMission = {
  missionId: string;
  sectionId?: string;
  pinnedSectionId?: string;
  pinnedResourceId?: string;
};

export const activeMissionAtom = atom<ActiveMission | undefined>({
  key: `${statePrefix}:activeMission:atom`,
  default: undefined,
});

export function useMissions(): Record<string, ManagedMission> {
  const manager = useMissionsManager();
  const [missions, setMissions] = useState(
    Object.fromEntries(manager.missions),
  );

  useEffect(() => {
    setMissions(Object.fromEntries(manager.missions));

    const addedListener: MissionsManagerEventListener<"mission_added"> = (
      mission,
    ) => setMissions((ms) => ({ ...ms, [mission.id]: mission }));
    manager.addEventListener("mission_added", addedListener);

    const updatedListener: MissionsManagerEventListener<"mission_updated"> = (
      mission,
    ) => setMissions((ms) => ({ ...ms, [mission.id]: mission }));
    manager.addEventListener("mission_updated", updatedListener);

    const removedListener: MissionsManagerEventListener<"mission_removed"> = ({
      id,
    }) =>
      setMissions((ms) =>
        Object.fromEntries(Object.entries(ms).filter(([key]) => key !== id)),
      );
    manager.addEventListener("mission_removed", removedListener);

    return () => {
      manager.removeEventListener("mission_added", addedListener);
      manager.removeEventListener("mission_updated", updatedListener);
      manager.removeEventListener("mission_removed", removedListener);
    };
  }, [manager]);

  return missions;
}

export function useMission(missionId?: string): ManagedMission | undefined {
  const manager = useMissionsManager();
  const [mission, setMission] = useState<ManagedMission | undefined>(
    missionId ? manager.missions.get(missionId) : undefined,
  );

  useEffect(() => {
    if (missionId) {
      setMission(manager.missions.get(missionId));

      const addedListener: MissionsManagerEventListener<"mission_added"> = (
        mission,
      ) => {
        if (mission.id === missionId) setMission(mission);
      };
      manager.addEventListener("mission_added", addedListener);

      const updatedListener: MissionsManagerEventListener<"mission_updated"> = (
        mission,
      ) => {
        if (mission.id === missionId) setMission(mission);
      };
      manager.addEventListener("mission_updated", updatedListener);

      const removedListener: MissionsManagerEventListener<"mission_removed"> = (
        mission,
      ) => {
        if (mission.id === missionId) setMission(undefined);
      };
      manager.addEventListener("mission_removed", removedListener);

      return () => {
        manager.removeEventListener("mission_added", addedListener);
        manager.removeEventListener("mission_updated", updatedListener);
        manager.removeEventListener("mission_removed", removedListener);
      };
    }
  }, [manager, missionId]);

  return mission;
}

export function useActiveMission(): ActiveMission | undefined {
  return useRecoilValue(activeMissionAtom);
}

export function useCurrentMission(): FullMission | undefined {
  const missions = useMissions();
  const active = useActiveMission();
  const mission = active ? missions[active.missionId] : undefined;
  return mission?.state === "ready" ? mission.mission : undefined;
}

export function useLoadedMissions(): ReadonlyArray<FullMission> {
  const missions = useMissions();

  return useMemo(
    () =>
      Object.values(missions)
        .map((m) => (m.state === "ready" ? m.mission : undefined))
        .filter(notEmpty),
    [missions],
  );
}

export function useSetActiveMission() {
  return useSetRecoilState(activeMissionAtom);
}

export function useTrackActiveMission(missionId: string) {
  const setActiveMission = useSetActiveMission();

  useEffect(() => {
    setActiveMission((active) =>
      active?.missionId === missionId ? active : { missionId },
    );
  }, [missionId, setActiveMission]);
}

export type PatientEvent = Identifiable &
  (PatientCreatedEvent | PatientUpdatedEvent | PatientDeletedEvent);

export function usePatientEvents(
  missionId: string,
  patientId: string,
): Array<PatientEvent> {
  const context = useRecoilValue(firebaseContextAtom);
  const [events, setEvents] = useState<Array<PatientEvent>>([]);

  useEffect(() => {
    let callbackIds: Array<string> = [];

    FirebaseFirestore.addCollectionSnapshotListener(
      {
        reference: `missions/${missionId}/patients/${patientId}/events`,
      },
      (snapshot, error) => {
        if (error) {
          log.error(
            "Error subscribing to events of patient %s / %s / %s:",
            context.user.id,
            missionId,
            patientId,
            error,
          );
        }

        setEvents(
          (snapshot?.snapshots ?? []).map(
            (doc) => ({ id: doc.id, ...doc.data }) as PatientEvent,
          ),
        );
      },
    ).then((callbackId) => {
      callbackIds.push(callbackId);
    });

    return () => {
      callbackIds.forEach((callbackId) =>
        FirebaseFirestore.removeSnapshotListener({ callbackId }),
      );
    };
  }, [context, missionId, patientId]);

  return events;
}
