import { Close as CloseIcon } from "@mui/icons-material";
import {
  AppBar,
  Dialog,
  DialogProps,
  IconButton,
  Slide,
  SlideProps,
  Toolbar,
  Typography,
} from "@mui/material";
import { type PropsWithChildren, type ReactNode } from "react";
import { useTranslation } from "react-i18next";

export type Props = PropsWithChildren<
  {
    title: ReactNode;
    onClose(): void;
  } & Omit<
    DialogProps,
    "onClose" | "fullScreen" | "TransitionComponent" | "title"
  >
>;

function SlideUp(props: Omit<SlideProps, "direction">) {
  return <Slide direction="up" {...props} />;
}

export function FullScreenDialog({ children, title, ...props }: Props) {
  const { t } = useTranslation();

  return (
    <Dialog {...props} fullScreen TransitionComponent={SlideUp}>
      <AppBar position="static" sx={{ pt: "env(safe-area-inset-top)" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={props.onClose}
            aria-label={t("close")}
            size="large"
            edge="start"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" color="inherit">
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      {children}
    </Dialog>
  );
}
