import { atom, DefaultValue, selector } from "recoil";
import { objekteAtom } from "../../db/kartenobjekte";
import { KartenObjekt } from "../../types";

export const aktivesObjektAtom = atom<string | undefined>({
  key: "objekte:aktiv:id",
  default: undefined,
});

export const aktivesObjektSelector = selector<KartenObjekt | undefined>({
  key: "objekte:aktiv",
  get: ({ get }) => {
    const id = get(aktivesObjektAtom);
    const objekte = get(objekteAtom);
    return objekte && objekte.find((e) => e.id === id);
  },
  set: ({ set }, newValue) => {
    set(
      aktivesObjektAtom,
      newValue instanceof DefaultValue || !newValue ? undefined : newValue.id,
    );
  },
});
