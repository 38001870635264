import { Badge, ListItemIcon, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import ListItemLink from "../../components/ListItemLink";
import { Icon, label, route } from "./config";
import { useAktiveRettungskarte } from "./data";

export default function RettungskartenMenuItem() {
  const { t } = useTranslation();
  const aktiv = useAktiveRettungskarte();

  return (
    <ListItemLink
      to={`${route}${
        aktiv ? `/${aktiv.hersteller.id}/${aktiv.modell.id}` : ""
      }`}
    >
      <ListItemIcon>
        <Badge variant="dot" color="secondary" invisible={!aktiv}>
          <Icon />
        </Badge>
      </ListItemIcon>
      <ListItemText primary={t(label)} />
    </ListItemLink>
  );
}
