import { lazy, useMemo } from "react";
import { type RouteObject } from "react-router";
import ModuleContextProvider from "../../../components/ModuleContext";
import type { ModuleInfo } from "../../types";
import { route } from "../config";

const EinsatzIndex = lazy(() => import("./EinsatzIndex"));
const EinsatzDetailsRoute = lazy(() => import("./EinsatzDetailsRoute"));

export function useAtemschutzRoutes(module: ModuleInfo): Array<RouteObject> {
  return useMemo(
    () => [
      {
        path: route,
        element: <ModuleContextProvider module={module} />,
        children: [
          { index: true, element: <EinsatzIndex /> },
          { path: "e/:einsatzId", element: <EinsatzDetailsRoute /> },
          { path: "e/:einsatzId/t/:truppId", element: <EinsatzDetailsRoute /> },
        ],
      },
    ],
    [module],
  );
}
