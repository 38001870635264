import * as L from "leaflet";
import iconUrl from "leaflet/dist/images/marker-icon.png";

export const defaultMarkerIcon = L.icon({
  iconUrl,
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
});

L.Marker.prototype.options.icon = defaultMarkerIcon;
