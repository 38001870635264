import { useMemo } from "react";
import type { ModuleInfo, ModuleSpec } from "../types";
import menuItem from "./MenuItem";
import { Icon, id, label, route } from "./config";
import { dataProviders } from "./data";
import { usePatientenRoutes } from "./routes";

export { Icon, route } from "./config";
export * from "./types";

const module: ModuleInfo = {
  id,
  label,
  icon: Icon,
  supportsMissions: true,
  trackingContext: "Patienten",
  route,
};

export function usePatientenModule(): ModuleSpec {
  const routes = usePatientenRoutes(module);

  return useMemo(
    () => ({
      ...module,
      enabled: (license) => license.module.includes("patienten"),
      dataProviders,
      menuItem,
      routes,
      dashboardTiles: [{ label, to: route, icon: Icon }],
    }),
    [routes],
  );
}
