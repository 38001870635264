import { Box, CircularProgress, Paper, type SvgIconProps } from "@mui/material";
import { type ComponentType, type PropsWithChildren } from "react";
import Delay from "./Delay";
import { useModule, useModuleTranslation } from "./ModuleContext";

export type Props = PropsWithChildren<{
  icon?: ComponentType<SvgIconProps>;
}>;

export default function Loading({ icon, children }: Props) {
  const module = useModule();
  const { t } = useModuleTranslation();
  const Icon = icon ?? module?.icon;

  return (
    <Delay>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Paper
          sx={{
            display: "grid",
            gap: (theme) => theme.spacing(2),
            p: 3,
            textAlign: "center",
            maxWidth: "20rem",
          }}
        >
          {Icon && (
            <Box>
              <Icon sx={{ color: "text.secondary", fontSize: "6rem" }} />
            </Box>
          )}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CircularProgress size="2rem" />
            <Box sx={{ ml: 2 }}>{children ?? t("loading")}</Box>
          </Box>
        </Paper>
      </Box>
    </Delay>
  );
}
