import { Box, LinearProgress, Typography } from "@mui/material";
import { Logo, rescueRed } from "@rescuetablet/theme";
import { ReactNode } from "react";
import Global from "./Global";

export type Props = {
  children?: ReactNode;
  progress?: number;
};

export default function GlobalLoading({ children, progress }: Props) {
  return (
    <Global>
      <Box
        sx={{
          backgroundColor: rescueRed[500],
          color: "secondary.contrastText",
          borderTopLeftRadius: (theme) => theme.shape.borderRadius,
          borderTopRightRadius: (theme) => theme.shape.borderRadius,
          px: 4,
          py: 2,
          width: 1,
        }}
      >
        <Logo />
      </Box>
      <LinearProgress
        color="primary"
        value={progress && progress * 100}
        variant={
          typeof progress === "undefined" ? "indeterminate" : "determinate"
        }
      />
      {children ? (
        <Box sx={{ px: 4, py: 3 }}>
          {typeof children === "string" ? (
            <Typography
              variant="caption"
              sx={{
                mt: 2,
                textAlign: "center",
              }}
            >
              {children}
            </Typography>
          ) : (
            children
          )}
        </Box>
      ) : null}
    </Global>
  );
}
