import { Badge, ListItemIcon, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import ListItemLink from "../../components/ListItemLink";
import { useActiveMission, useMissions } from "../../missions";
import { Icon, label, route } from "./config";

export default function TableausMenuItem() {
  const { t } = useTranslation();
  const missions = useMissions();
  const activeMission = useActiveMission();

  return (
    <ListItemLink
      to={`${route}${activeMission ? `/${activeMission.missionId}` : ""}`}
    >
      <ListItemIcon>
        <Badge color="secondary" badgeContent={Object.keys(missions).length}>
          <Icon />
        </Badge>
      </ListItemIcon>
      <ListItemText primary={t(label)} />
    </ListItemLink>
  );
}
