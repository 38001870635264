import { nanoid } from "nanoid";
import { getInstance } from "../db";
import { logger } from "../log";
import { ErrorLike } from "../types";
import { TaskInfo, TaskObserver, TaskRun, TaskSpec } from "./api";
import { EventEmitter, TypedEvents } from "./event-emitter";

const log = logger("task");

type Task = {
  spec: TaskSpec;
  state: TaskState;
};

type TaskState = {
  status: "idle" | "running";
  currentRun?: TaskRun;
  lastRun?: TaskRun;
  nextExecutionAt?: number;
};

type PersistedTaskState = Pick<TaskState, "currentRun" | "lastRun">;

export type TaskManagerEvents = {
  "task-registered": (task: TaskInfo) => void;
  "task-scheduled": (task: TaskInfo) => void;
  "task-run-started": (run: TaskRun, task: TaskInfo) => void;
  "task-run-updated": (run: TaskRun, task: TaskInfo) => void;
  "task-run-finished": (run: TaskRun, task: TaskInfo) => void;
  "task-run-canceled": (run: TaskRun, task: TaskInfo) => void;
};

export type ScheduleFn = (task: () => void, executeAt: number) => void;
const defaultScheduleFn: ScheduleFn = (task, executeAt) =>
  window.setTimeout(task, executeAt - Date.now());

export type CreateRunIdFn = () => string;
const defaultCreateRunIdFn: CreateRunIdFn = () => nanoid();

export class TaskManager implements TypedEvents<TaskManagerEvents> {
  private events = new EventEmitter<TaskManagerEvents>();
  private tasks: { [id: string]: Task } = {};
  private scheduleFn: ScheduleFn;
  private createRunId: CreateRunIdFn;

  constructor({
    schedule = defaultScheduleFn,
    createRunId = defaultCreateRunIdFn,
  }: { schedule?: ScheduleFn; createRunId?: CreateRunIdFn } = {}) {
    this.scheduleFn = schedule;
    this.createRunId = createRunId;
  }

  on<E extends keyof TaskManagerEvents>(
    event: E,
    listener: TaskManagerEvents[E],
  ) {
    this.events.on(event, listener);
    return this;
  }

  off<E extends keyof TaskManagerEvents>(
    event: E,
    listener: TaskManagerEvents[E],
  ) {
    this.events.off(event, listener);
    return this;
  }

  async register(spec: TaskSpec) {
    if (spec.id in this.tasks) {
      log.warn(`Duplicate task: ${spec.id}`);
      return;
    }

    log.info(`[${spec.id}] Registering task`);
    const storedState = await getPersistedState(spec.id);

    const task: Task = {
      spec,
      state: {
        lastRun: storedState?.currentRun ?? storedState?.lastRun,
        status: "idle",
        nextExecutionAt: this.getNextExecutionTimestamp(
          spec,
          storedState?.lastRun?.startedAt,
        ),
      },
    };
    this.tasks[spec.id] = task;
    this.emitTask("task-registered", spec.id);
    this.schedule(spec.id);
  }

  private schedule(taskId: string) {
    const { state } = this.getTask(taskId);

    if (state.status !== "idle") {
      log.warn(`[${taskId}] Cannot schedule task in state ${state.status}`);
      return;
    }

    if (state.nextExecutionAt) {
      log.info(
        `[${taskId}] Scheduling next execution at ${new Date(
          state.nextExecutionAt,
        ).toISOString()}`,
      );
      this.scheduleFn(() => this.run(taskId), state.nextExecutionAt);
      this.emitTask("task-scheduled", taskId);
    }
  }

  async run(taskId: string) {
    const { spec, state } = this.getTask(taskId);

    if (state.status !== "idle") {
      log.warn(
        `[${taskId}] Cannot start new execution in state ${state.status}`,
      );
      return;
    }

    const startedAt = Date.now();
    const runId = this.createRunId();
    log.info(`[${taskId}] Starting run: ${runId}`);

    this.updateTask(taskId, (task) => ({
      ...task,
      state: {
        ...task.state,
        status: "running",
        currentRun: {
          id: runId,
          startedAt,
          totalCount: 0,
          successCount: 0,
          errorCount: 0,
          errors: [],
          canceled: false,
        },
      },
    }));
    await this.persistTaskState(taskId);
    this.emitRun("task-run-started", taskId);

    const updateRun = (updater: (run: TaskRun) => TaskRun) => {
      this.updateTask(taskId, (task) => ({
        ...task,
        state: task.state.currentRun
          ? { ...task.state, currentRun: updater(task.state.currentRun) }
          : task.state,
      }));
      this.emitRun("task-run-updated", taskId);
    };

    const isCanceled = () =>
      this.getTask(taskId).state?.currentRun?.canceled === true;

    const observer: TaskObserver = {
      taskId: runId,
      get canceled() {
        return isCanceled();
      },
      setTask: (task: string) => updateRun((run) => ({ ...run, task })),
      setWork: (work: number) =>
        updateRun((run) => ({ ...run, totalCount: work })),
      onSuccess: () =>
        updateRun((run) => ({ ...run, successCount: run.successCount + 1 })),
      onFailed: (error?: ErrorLike) =>
        updateRun((run) => ({
          ...run,
          errorCount: run.errorCount + 1,
          errors: [...run.errors, ...(error ? [error] : [])],
        })),
    };

    try {
      await spec.execute(observer);
    } catch (error: any) {
      log.error(`[${taskId}] Error executing run ${runId}: ${error.message}`);
    }

    log.info(`[${taskId}] Run finished: ${runId}`);
    this.updateTask(taskId, (task) => ({
      ...task,
      state: {
        ...task.state,
        status: "idle",
        currentRun: undefined,
        lastRun: task.state.currentRun,
        nextExecutionAt: this.getNextExecutionTimestamp(spec, startedAt),
      },
    }));
    await this.persistTaskState(taskId);
    this.emitRun("task-run-finished", taskId);

    this.schedule(taskId);
  }

  cancel(taskId: string, runId: string) {
    const { state } = this.getTask(taskId);
    if (
      state.currentRun &&
      state.currentRun.id === runId &&
      !state.currentRun.canceled
    ) {
      log.info(`[${taskId}] canceled`);
      this.updateTask(taskId, (task) => ({
        ...task,
        state: {
          ...task.state,
          currentRun: task.state.currentRun && {
            ...task.state.currentRun,
            canceled: true,
          },
        },
      }));
      this.emitRun("task-run-canceled", taskId);
    }
  }

  private updateTask(taskId: string, updater: (task: Task) => Task) {
    const updated = updater(this.getTask(taskId));
    this.tasks[taskId] = updated;
  }

  private getNextExecutionTimestamp(
    spec: TaskSpec,
    lastRun?: number,
  ): number | undefined {
    const nextExecutionAt = spec.getNextExecutionTimestamp?.(lastRun);
    return nextExecutionAt && Math.max(nextExecutionAt, Date.now());
  }

  private async persistTaskState(taskId: string) {
    const {
      state: { currentRun, lastRun },
    } = this.getTask(taskId);
    await setPersistedState(taskId, { currentRun, lastRun });
  }

  private getTask(taskId: string): Task {
    const task = this.tasks[taskId];
    if (!task) throw new Error(`Unknown task: ${taskId}`);
    return task;
  }

  private emitTask(
    event: "task-registered" | "task-scheduled",
    taskId: string,
  ) {
    const { spec, state } = this.getTask(taskId);
    this.events.emit(event, {
      id: spec.id,
      label: spec.label,
      status: state.status,
      currentRun: state.currentRun,
      lastRun: state.lastRun,
      nextExecutionAt: state.nextExecutionAt,
    });
  }

  private emitRun(
    event:
      | "task-run-started"
      | "task-run-finished"
      | "task-run-updated"
      | "task-run-canceled",
    taskId: string,
  ) {
    const { spec, state } = this.getTask(taskId);
    const run = state.currentRun ?? state.lastRun;
    if (!run) return;

    this.events.emit(event, run, {
      id: spec.id,
      label: spec.label,
      status: state.status,
      currentRun: state.currentRun,
      lastRun: state.lastRun,
      nextExecutionAt: state.nextExecutionAt,
    });
  }
}

async function getPersistedState(
  taskId: string,
): Promise<PersistedTaskState | undefined> {
  const db = await getInstance("config");
  const value = await db.readData(`task:${taskId}`);
  return typeof value === "string" ? JSON.parse(value) : undefined;
}

async function setPersistedState(taskId: string, value: PersistedTaskState) {
  const db = await getInstance("config");
  await db.writeData(`task:${taskId}`, JSON.stringify(value));
}
