import { RequestResult, type Client } from "@hey-api/client-fetch";
import { nanoid } from "nanoid";
import { v1 as uuid } from "uuid";
import {
  createMission,
  createMissionAction,
  createMissionMapElement,
  createMissionPatient,
  createMissionReport,
  createMissionResource,
  createMissionSection,
  endMission,
  getFile,
  getReport,
  removeMissionMapElement,
  removeMissionPatient,
  removeMissionResource,
  removeMissionSection,
  reopenMission,
  requestFileUpload,
  searchReports,
  updateMission,
  updateMissionPatient,
  updateMissionResource,
  updateMissionSection,
  type AddResourceInput,
  type CreateActionInput,
  type CreateMissionInput,
  type CreateReportInput,
  type File,
  type MapElementData,
  type Mission,
  type MissionReportSearchResults,
  type PatientUpdate,
  type ResourceData,
  type SectionData,
  type UpdateMissionInput,
  type UpdateResourceInput,
} from "./api";

export class MissionsClient {
  constructor(private readonly client: Client) {}

  async createMission(values: CreateMissionInput): Promise<Mission> {
    return extractResult(
      createMission({
        client: this.client,
        path: { missionId: uuid() },
        body: values,
      }),
    );
  }

  async reopenMission(missionId: string): Promise<Mission> {
    return extractResult(
      reopenMission({ client: this.client, path: { missionId } }),
    );
  }

  async updateMission(
    missionId: string,
    values: UpdateMissionInput,
  ): Promise<Mission> {
    return extractResult(
      updateMission({
        client: this.client,
        path: { missionId },
        body: values,
      }),
    );
  }

  async createMissionAction(
    missionId: string,
    values: CreateActionInput,
  ): Promise<Mission> {
    return extractResult(
      createMissionAction({
        client: this.client,
        path: { missionId, actionId: nanoid() },
        body: values,
      }),
    );
  }

  async createMissionReport(
    missionId: string,
    values: CreateReportInput,
  ): Promise<Mission> {
    return extractResult(
      createMissionReport({
        client: this.client,
        path: { missionId, reportId: nanoid() },
        body: values,
      }),
    );
  }

  async createMissionResource(
    missionId: string,
    values: AddResourceInput,
  ): Promise<Mission> {
    return extractResult(
      createMissionResource({
        client: this.client,
        path: { missionId, resourceId: nanoid() },
        body: values,
      }),
    );
  }

  async updateMissionResource(
    missionId: string,
    resourceId: string,
    values: UpdateResourceInput,
  ): Promise<Mission> {
    return extractResult(
      updateMissionResource({
        client: this.client,
        path: { missionId, resourceId },
        body: values,
      }),
    );
  }

  async setMissionResource(
    missionId: string,
    resourceId: string,
    values: ResourceData,
  ): Promise<Mission> {
    return extractResult(
      createMissionResource({
        client: this.client,
        path: { missionId, resourceId },
        body: values,
      }),
    );
  }

  async removeMissionResource(
    missionId: string,
    resourceId: string,
  ): Promise<Mission> {
    return extractResult(
      removeMissionResource({
        client: this.client,
        path: { missionId, resourceId },
      }),
    );
  }

  async endMission(missionId: string): Promise<void> {
    return extractResult(
      endMission({
        client: this.client,
        path: { missionId },
      }),
    );
  }

  async createMissionMapElement(
    missionId: string,
    values: MapElementData,
  ): Promise<Mission> {
    return extractResult(
      createMissionMapElement({
        client: this.client,
        path: { missionId, elementId: nanoid() },
        body: values,
      }),
    );
  }

  async updateMissionMapElement(
    missionId: string,
    elementId: string,
    values: MapElementData,
  ): Promise<Mission> {
    return extractResult(
      createMissionMapElement({
        client: this.client,
        path: { missionId, elementId },
        body: values,
      }),
    );
  }

  async deleteMissionMapElement(
    missionId: string,
    elementId: string,
  ): Promise<Mission> {
    return extractResult(
      removeMissionMapElement({
        client: this.client,
        path: { missionId, elementId },
      }),
    );
  }

  async createSection(
    missionId: string,
    sectionId: string,
    values: SectionData,
  ): Promise<Mission> {
    return extractResult(
      createMissionSection({
        client: this.client,
        path: { missionId, sectionId },
        body: values,
      }),
    );
  }

  async updateSection(
    missionId: string,
    sectionId: string,
    values: SectionData,
  ): Promise<Mission> {
    return extractResult(
      updateMissionSection({
        client: this.client,
        path: { missionId, sectionId },
        body: values,
      }),
    );
  }

  async deleteSection(missionId: string, sectionId: string): Promise<Mission> {
    return extractResult(
      removeMissionSection({
        client: this.client,
        path: { missionId, sectionId },
      }),
    );
  }

  async createPatient(
    missionId: string,
    patientId: string,
    values: PatientUpdate,
  ): Promise<Mission> {
    return extractResult(
      createMissionPatient({
        client: this.client,
        path: { missionId, patientId },
        body: values,
      }),
    );
  }

  async updatePatient(
    missionId: string,
    patientId: string,
    values: PatientUpdate,
  ): Promise<Mission> {
    return extractResult(
      updateMissionPatient({
        client: this.client,
        path: { missionId, patientId },
        body: values,
      }),
    );
  }

  async deletePatient(missionId: string, patientId: string): Promise<Mission> {
    return extractResult(
      removeMissionPatient({
        client: this.client,
        path: { missionId, patientId },
      }),
    );
  }

  async uploadFile(missionId: string, file: Blob): Promise<File> {
    const fileId = nanoid();

    const { url } = await extractResult(
      requestFileUpload({
        client: this.client,
        path: { missionId, fileId },
        body: { type: file.type, size: file.size },
      }),
    );

    const response = await fetch(url, { method: "PUT", body: file });
    if (!response.ok) {
      throw new Error(`Error uploading file: ${response.statusText}`);
    }

    return { id: fileId, type: file.type, size: file.size };
  }

  async getFileURL(missionId: string, fileId: string): Promise<string> {
    const { url } = await extractResult(
      getFile({
        client: this.client,
        path: { missionId, fileId },
        query: { noRedirect: true },
      }),
    );

    return url;
  }

  async getReports(
    search: string,
    limit?: number,
  ): Promise<MissionReportSearchResults> {
    return extractResult(
      searchReports({
        client: this.client,
        query: { search, limit },
      }),
    );
  }

  async getReport(
    reportId: string,
    response: "redirect" | "url" | "data" = "data",
    contentType: "application/json" | "application/pdf" | "application/zip",
  ): Promise<unknown> {
    return extractResult(
      getReport({
        client: this.client,
        path: { missionId: reportId },
        query: { response },
        headers: { accept: contentType },
      }),
    );
  }
}

async function extractResult<TData, TError>(
  result: RequestResult<TData, TError, false>,
): Promise<TData> {
  const res = await result;
  if (res.error) throw res.error;
  return res.data!;
}
