import { Badge, ListItemIcon, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import ListItemLink from "../../components/ListItemLink";
import { Icon, label, route } from "./config";
import { aktivesObjektSelector } from "./data";

export default function ObjekteMenuItem() {
  const { t } = useTranslation();
  const aktivesObjekt = useRecoilValue(aktivesObjektSelector);

  return (
    <ListItemLink to={aktivesObjekt ? `${route}/${aktivesObjekt.id}` : route}>
      <ListItemIcon>
        <Badge variant="dot" color="secondary" invisible={!aktivesObjekt}>
          <Icon />
        </Badge>
      </ListItemIcon>
      <ListItemText primary={t(label)} />
    </ListItemLink>
  );
}
