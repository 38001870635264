import { Box } from "@mui/material";
import { ReactNode } from "react";

export default function PopupContent({ children }: { children?: ReactNode }) {
  return (
    <Box
      sx={{
        whiteSpace: "pre-line",
        display: "flex",
        flexDirection: "column",
        gap: (theme) => theme.spacing(1),
        ".MuiTypography-root": { margin: 0 },
        a: { color: "inherit !important" },
      }}
    >
      {children}
    </Box>
  );
}
