import { Typography } from "@mui/material";
import { lineString } from "@turf/helpers";
import calcLength from "@turf/length";
import L from "leaflet";
import qs from "query-string";
import { useCallback, useEffect, useState } from "react";
import { Marker, Popup, useMap } from "react-leaflet";
import { locationRounder } from "../../data/location";
import { getResource } from "../../db/resource";
import { useTrackEvent } from "../../tracking";
import { Koordinaten } from "../../types";
import { defaultMarkerIcon } from "./default-marker-icon";
import PopupContent from "./PopupContent";

const partnerId = "d4cef103b9fcfa8c00c00278f93a5a06";
const coordinatesPrecision = 3;

export type Props = {
  fireboardKey: string;
};

type FireboardIcon = {
  id: number;
  filename: string;
  checksum_md5: string;
  name?: string;
};

type FireboardPOI = {
  name?: string;
  address?: string;
  comment?: string;
  lat: number;
  lng: number;
  icon_id?: number;
};

type FireboardPOIs = {
  status?: "error";
  errors?: Array<string>;
  icon_ids: Array<number>;
  icons: Array<FireboardIcon>;
  pois: Array<FireboardPOI>;
};

type FireboardMarker = FireboardPOI & { icon: L.Icon };

export default function FireboardLayer({ fireboardKey }: Props) {
  const [markers, setMarkers] = useState<FireboardMarker[]>([]);
  const map = useMap();
  const center = useRoundedMapCenter();
  const radius = useMapRadius();

  const trackEvent = useTrackEvent();
  const onOpen = useCallback(() => {
    trackEvent("Open map element popup", { label: "fireboard" });
  }, [trackEvent]);

  const load = useCallback(async () => {
    if (!map || !center || !radius) {
      setMarkers([]);
      return;
    }

    try {
      const url = qs.stringifyUrl({
        url: "https://login.fireboard.net/api",
        query: { authkey: fireboardKey, call: "rescuetablet" },
      });

      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          partnerid: partnerId,
          function: "getPois",
          lat: center.latitude,
          lng: center.longitude,
          limit: 1000,
          distance: radius,
        }),
      });

      if (response.status === 400) {
        setMarkers([]);
        return;
      }

      if (!response.ok) throw new Error(`${response.statusText}`);
      const data: FireboardPOIs = await response.json();
      const icons = await loadIcons(data.icons, fireboardKey);
      setMarkers(
        data.pois.map((poi) => ({
          ...poi,
          icon:
            (poi.icon_id && icons[poi.icon_id.toString()]) || defaultMarkerIcon,
        })),
      );
    } catch (error: unknown) {
      console.error("Error fetching fireboard data:", error);
      setMarkers([]);
    }
  }, [map, fireboardKey, center, radius]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <>
      {markers.map((marker, index) => (
        <Marker
          key={index}
          position={[marker.lat, marker.lng]}
          icon={marker.icon}
        >
          <Popup eventHandlers={{ add: onOpen }}>
            <PopupContent>
              {marker.name && (
                <Typography variant="h6">{marker.name}</Typography>
              )}
              {marker.address && (
                <Typography variant="body2">{marker.address}</Typography>
              )}
              <Typography variant="caption">Quelle: Fireboard</Typography>
            </PopupContent>
          </Popup>
        </Marker>
      ))}
    </>
  );
}

async function loadIcons(
  icons: Array<FireboardIcon>,
  fireboardKey: string,
): Promise<Record<string, L.Icon>> {
  const entries = await Promise.all(
    icons.map(async ({ id, checksum_md5 }) => {
      const url = qs.stringifyUrl({
        url: "https://login.fireboard.net/api",
        query: { authkey: fireboardKey, call: "rescuetablet" },
      });

      const resource = await getResource(url, {
        cacheKey: `fireboard-icons:${id}:${checksum_md5}`,
        overrideExpiry: 1000 * 60 * 60 * 24 * 365,
        requestOptions: {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            partnerid: partnerId,
            function: "getIcon",
            id,
          }),
        },
      });

      return [
        id.toString(),
        L.icon({ iconUrl: resource.data, iconSize: [24, 24] }),
      ];
    }),
  );

  return Object.fromEntries(entries);
}

function useRoundedMapCenter() {
  const map = useMap();
  const rounder = locationRounder(coordinatesPrecision);
  const [coordinates, setCoordinates] = useState<Koordinaten>();

  useEffect(() => {
    if (map) {
      const listener = () => {
        const c = map.getCenter();
        const rounded = rounder({ latitude: c.lat, longitude: c.lng });
        setCoordinates((current) =>
          current?.latitude === rounded.latitude &&
          current?.longitude === rounded.longitude
            ? current
            : rounded,
        );
      };

      map.addEventListener("dragend moveend", listener);
      listener();
      return () => {
        map.removeEventListener("dragend moveend", listener);
      };
    } else {
      setCoordinates(undefined);
    }
  }, [map, rounder]);

  return coordinates;
}

function useMapRadius() {
  const map = useMap();
  const [radius, setRadius] = useState<number>();

  useEffect(() => {
    if (map) {
      const listener = () => {
        const bounds = map.getBounds();
        const nw = bounds.getNorthWest();
        const se = bounds.getSouthEast();
        const diagonal = calcLength(
          lineString([
            [nw.lat, nw.lng],
            [se.lat, se.lng],
          ]),
          { units: "meters" },
        );
        setRadius(Math.ceil((diagonal / 2) * 1.2));
      };

      map.addEventListener("zoomend", listener);
      listener();
      return () => {
        map.removeEventListener("zoomend", listener);
      };
    } else {
      setRadius(undefined);
    }
  }, [map]);

  return radius;
}
