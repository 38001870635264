import { useMemo, type ReactNode } from "react";
import { DataProviderConfig, OfflineCacheProvider } from "../data-provider";
import { useLicense } from "../license";
import { useModules } from "../modules";
import { dataProvider as hydranten } from "./hydranten";
import { dataProvider as informationen } from "./informationen";
import { dataProvider as kartendetailobjekte } from "./kartendetailobjekte";
import { dataProvider as kartenobjekte } from "./kartenobjekte";
import { DataProviderParams } from "./provider";
import { dataProvider as sperrungen } from "./sperrungen";

/**
 * The avilable data providers. Each is only activated if the corresponding module is enabled.
 */
const dataProviders = [
  hydranten,
  informationen,
  kartenobjekte,
  kartendetailobjekte,
  sperrungen,
];

export function DataProviders({ children }: { children: ReactNode }) {
  const providers = useDataProviders();
  return (
    <OfflineCacheProvider providers={providers}>
      {children}
    </OfflineCacheProvider>
  );
}

function useDataProviders(): DataProviderConfig<any>[] {
  const license = useLicense();
  const modules = useModules();

  return useMemo(() => {
    if (!license) return [];

    const params: DataProviderParams = {
      demo: license.demo,
      alarmKey: license.alarmKey,
      portalKey: license.portalKey,
      module: license.module,
      license: license.id,
    };

    const providers = [
      ...dataProviders,
      ...modules.flatMap((m) => m.dataProviders),
    ];
    return providers
      .map((p) => p(params))
      .filter(Boolean) as DataProviderConfig<any>[];
  }, [modules, license]);
}
