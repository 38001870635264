import { Suspense, createContext, useContext, type ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";
import { type ModuleInfo } from "../modules";
import { route as defaultMissionsRoute } from "../modules/tableau/config";
import { TrackingContextProvider } from "../tracking";
import ModuleLoader from "./ModuleLoader";

export type ModuleContext = ModuleInfo;

const Context = createContext<ModuleContext | undefined>(undefined);

export default function ModuleContextProvider({
  module,
  children,
}: {
  module: ModuleContext;
  children?: ReactNode;
}) {
  return (
    <Context.Provider value={module}>
      <TrackingContextProvider category={module.trackingContext}>
        <Suspense fallback={<ModuleLoader />}>
          {children ?? <Outlet />}
        </Suspense>
      </TrackingContextProvider>
    </Context.Provider>
  );
}

export function useModule(): ModuleContext | undefined {
  return useContext(Context);
}

export function useMissionsRoute(): string {
  const module = useModule();
  return (
    (module?.supportsMissions ? module.route : undefined) ??
    defaultMissionsRoute
  );
}

export function useModuleTranslation() {
  return useTranslation(useModule()?.id);
}
