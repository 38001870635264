import { useCallback } from "react";
import { useAuthenticated } from "../auth";
import { locationSubmissionInterval, portalServer } from "../config";
import { useActiveMission } from "../missions";
import { useInterval } from "../utils";
import { useCurrentLocation } from "./location";

const submitUrl = `${portalServer}/set-device-location.php`;

export default function LocationSubmitter() {
  const { positionSenden } = useAuthenticated();
  return positionSenden ? <ActiveLocationSubmitter /> : null;
}

function ActiveLocationSubmitter() {
  const { id: deviceId } = useAuthenticated();
  const activeMission = useActiveMission();
  const { location, accuracy } = useCurrentLocation();

  const submitLocation = useCallback(async () => {
    try {
      if (activeMission && location && accuracy) {
        const response = await fetch(submitUrl, {
          method: "POST",
          headers: {
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify({
            DEVICE_ID: deviceId,
            DEVICE_POSITION_LAT: location.latitude,
            DEVICE_POSITION_LNG: location.longitude,
            DEVICE_POSITION_ACCURACY: accuracy,
          }),
        });
        if (!response.ok)
          throw new Error(`Fehler: ${response.status} ${response.statusText}`);
        const data = await response.json();
        if (!data.success) throw new Error(data.error);
      }
    } catch (error) {
      console.error("Error reporting location:", error);
    }
  }, [deviceId, activeMission, location, accuracy]);

  useInterval(submitLocation, locationSubmissionInterval);

  return null;
}
