import { ArrowBack as BackIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import LinkBehavior from "./LinkBehavior";

export default function BackButton({ back }: { back: string | (() => void) }) {
  if (typeof back === "string") {
    return (
      <IconButton
        component={LinkBehavior}
        href={back}
        color="inherit"
        edge="start"
      >
        <BackIcon />
      </IconButton>
    );
  }

  if (typeof back === "function") {
    return (
      <IconButton color="inherit" edge="start" onClick={back}>
        <BackIcon />
      </IconButton>
    );
  }

  return null;
}
