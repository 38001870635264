import { Button, type ButtonProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCapitalize } from "../i18n";

export type CloseIntent = "close" | "cancel";

export default function CloseButton({
  children,
  intent = "close",
  ...props
}: ButtonProps & { intent?: CloseIntent }) {
  const { t } = useTranslation();
  const capitalize = useCapitalize();

  return (
    <Button type="button" variant="text" color="grey" {...props}>
      {children ?? capitalize(t(intent))}
    </Button>
  );
}
