import { nanoid } from "nanoid";
import { useCallback } from "react";
import { useAuthenticated } from "../../auth";
import { apiEndpoint } from "../../config";
import { FetchProvider, useData, useUpdateProvider } from "../../data-provider";
import { DataProvider, StorageCache } from "../../db/provider";
import { Koordinaten } from "../../types";
import { id, label } from "./config";
import { Meldung } from "./types";

const dataProvider: DataProvider<Meldung[]> = ({ portalKey }) => ({
  id,
  label,
  provider: new FetchProvider({
    url: `${apiEndpoint}/meldungen?ORG_ACCESSKEY=${encodeURIComponent(
      portalKey,
    )}`,
  }),
  cache: new StorageCache(id),
  getStatistics: async (value) => ({
    count: value.length,
    totalSize: JSON.stringify(value).length,
  }),
  updateInterval: 30 * 1000,
});

export const dataProviders = [dataProvider];

export function useMeldungen() {
  return useData<Meldung[]>(id, []);
}

export type MeldungDaten = {
  target: Koordinaten;
  text: string;
};

export function useCreateMeldung() {
  const license = useAuthenticated();
  const update = useUpdateProvider(id);

  return useCallback(
    async (daten: MeldungDaten) => {
      const meldung = {
        id: nanoid(),
        melderId: license.id,
        melderName: license.name,
        latitude: daten.target.latitude,
        longitude: daten.target.longitude,
        text: daten.text,
      };

      const response = await fetch(
        `${apiEndpoint}/meldungen/${
          meldung.id
        }?ORG_ACCESSKEY=${encodeURIComponent(license.portalKey)}`,
        {
          method: "put",
          headers: { "content-type": "application/json" },
          body: JSON.stringify(meldung),
        },
      );
      if (!response.ok)
        throw new Error(`Fehler ${response.status} ${response.statusText}`);

      await update();
    },
    [license, update],
  );
}

export function useDeleteMeldung() {
  const license = useAuthenticated();
  const update = useUpdateProvider(id);

  return useCallback(
    async (id: string) => {
      const response = await fetch(
        `${apiEndpoint}/meldungen/${id}?ORG_ACCESSKEY=${encodeURIComponent(
          license.portalKey,
        )}`,
        {
          method: "delete",
        },
      );
      if (!response.ok)
        throw new Error(`Fehler ${response.status} ${response.statusText}`);
      await update();
    },
    [license, update],
  );
}
