import L from "leaflet";
import { useEffect } from "react";
import { useMap } from "react-leaflet";
import "./BetterWMS";

export type Props = {
  url: string;
  service?: string;
  version?: string;
  attribution: string;
  layers: string;
};

export function WMSLayer({
  url,
  service,
  attribution,
  version,
  layers,
}: Props) {
  const map = useMap();
  useEffect(() => {
    if (map) {
      const options: any = {
        format: "image/png",
        transparent: true,
        opacity: 0.5,
        attribution,
        layers,
      };
      if (service) options.service = service;
      if (version) options.version = version;
      const layer = new (L.TileLayer as any).BetterWMS(url, options);
      layer.addTo(map);

      return () => {
        map.removeLayer(layer);
      };
    }
  }, [map, url, service, attribution, version, layers]);
  return null;
}
