import { lazy, useMemo } from "react";
import ModuleContextProvider from "../../../components/ModuleContext";
import type { ModuleInfo } from "../../types";
import { route } from "../config";

const ChecklisteDetails = lazy(() => import("./ChecklisteDetails"));
const ChecklistenListe = lazy(() => import("./ChecklistenListe"));

export function useChecklistenRoutes(module: ModuleInfo) {
  return useMemo(
    () => [
      {
        path: route,
        element: <ModuleContextProvider module={module} />,
        children: [
          { index: true, element: <ChecklistenListe /> },
          { path: ":id", element: <ChecklisteDetails /> },
        ],
      },
    ],
    [module],
  );
}
