import { type ReactNode } from "react";
import { DefaultValue } from "recoil";
import { RecoilSync, type WriteInterface } from "recoil-sync";
import { getInstance } from "./db";

export const databaseStore = "database";
const defaultDatabase = "config";

export default function DatabaseRecoilStore(props: { children: ReactNode }) {
  return (
    <RecoilSync
      storeKey={databaseStore}
      read={readItem}
      write={writeItems}
      {...props}
    />
  );
}

async function readItem(itemKey: string): Promise<unknown> {
  const { database, key } = parseItemKey(itemKey);
  const instance = await getInstance(database);
  const value = await instance.readData(key);
  return typeof value === "string" ? JSON.parse(value) : new DefaultValue();
}

async function writeItems({ diff }: WriteInterface): Promise<void> {
  const promises: Array<Promise<unknown>> = [];

  for (const [itemKey, value] of diff.entries()) {
    promises.push(writeItem(itemKey, value));
  }

  await Promise.all(promises);
}

async function writeItem(itemKey: string, value: unknown): Promise<void> {
  const { database, key } = parseItemKey(itemKey);
  const instance = await getInstance(database);

  if (value instanceof DefaultValue) {
    await instance.deleteData(key);
  } else {
    await instance.writeData(key, JSON.stringify(value));
  }
}

function parseItemKey(itemKey: string): { database: string; key: string } {
  const parts = itemKey.split("/", 2);
  return parts.length === 1
    ? { database: defaultDatabase, key: itemKey }
    : { database: parts[0], key: parts[1] };
}
