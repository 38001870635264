import {
  Badge,
  CircularProgress,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { taskCountsSelector } from "../../background";
import LinkBehavior from "../../components/LinkBehavior";
import { route } from "./config";

export default function TasksMenuItem() {
  const { t } = useTranslation("settings");
  const { running } = useRecoilValue(taskCountsSelector);

  return running > 0 ? (
    <ListItemButton
      component={LinkBehavior}
      href={`${route}/tasks`}
      title={t("tasks.label")}
    >
      <ListItemIcon>
        <Badge color="secondary" badgeContent={running}>
          <CircularProgress size="1.7142857142857142rem" color="inherit" />
        </Badge>
      </ListItemIcon>
      <ListItemText primary={t("tasks.label")} />
    </ListItemButton>
  ) : null;
}
