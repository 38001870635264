import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  type PropsWithChildren,
} from "react";
import { useLocation } from "react-router";
import { useLicense } from "./license";
import { logger } from "./log";

const log = logger("track");

declare global {
  interface Window {
    gtag(...args: any): void;
  }
}

export function useTracking() {
  useTrackingIdentity();
  usePageTracking();
}

function useTrackingIdentity() {
  const license = useLicense();

  useEffect(() => {
    if (license) {
      window.gtag("set", "user_id", license.id);
      window.gtag("set", "user_properties", {
        demo: license.demo,
        freeversion: license.freeversion,
      });
    }
  }, [license]);
}

function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    window.gtag("event", "page_view");
  }, [location]);
}

type TrackingContext = {
  category?: string;
};

const trackingContext = createContext<TrackingContext>({});

export function TrackingContextProvider({
  children,
  ...context
}: PropsWithChildren<TrackingContext>) {
  return (
    <trackingContext.Provider value={context}>
      {children}
    </trackingContext.Provider>
  );
}

export function useTrackingContext(): TrackingContext {
  return useContext(trackingContext);
}

export function useTrackEvent(): typeof trackEvent {
  const defaults = useTrackingContext();
  return useCallback(
    (action, details) => {
      trackEvent(action, { ...defaults, ...details });
    },
    [defaults],
  );
}

export function trackEvent(
  action: string,
  details: { category?: string; label?: string; value?: number } = {},
) {
  log.silly(action, details);
  window.gtag("event", action, details);
}
