import { Menu as MenuIcon } from "@mui/icons-material";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { preview } from "../../config";
import { useLicense } from "../../license";
import MissionSelectorTitle from "../MissionSelectorTitle";
import { LayoutProps } from "./common";

export type Props = LayoutProps & {
  menuOpen: boolean;
  toggleMenu: () => void;
};

export default function Header({ drawerWidth, menuOpen, toggleMenu }: Props) {
  const license = useLicense();

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        transition: (theme) =>
          theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        ...getBackground(preview ?? (license?.demo ? "DEMO" : undefined)),
        ...(menuOpen && {
          marginLeft: drawerWidth,
          width: `calc(100% - ${drawerWidth}px)`,
          transition: (theme) =>
            theme.transitions.create(["width", "margin"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
        }),
        paddingTop: "env(safe-area-inset-top)",
      }}
    >
      <Toolbar sx={{ display: "flex", alignItems: "center" }}>
        {!menuOpen && (
          <IconButton
            color="inherit"
            onClick={toggleMenu}
            edge="start"
            size="large"
          >
            <MenuIcon />
          </IconButton>
        )}
        <Typography
          variant="h6"
          component="h1"
          sx={{ mr: "auto", whiteSpace: "nowrap" }}
        >
          <MissionSelectorTitle />
        </Typography>
        {license && <Typography noWrap>{license.name}</Typography>}
      </Toolbar>
    </AppBar>
  );
}

function getBackground(info?: string) {
  if (info) {
    const svg = `
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" height="70" width="80">
        <text x="0" y="60" fill="white" opacity=".2" font-size="24" font-family="sans-serif" font-weight="bold" text-anchor="middle" transform="rotate(-45)">
          ${info}
        </text>
      </svg>
    `;
    return { backgroundImage: `url(data:image/svg+xml;base64,${btoa(svg)})` };
  }

  return {};
}
