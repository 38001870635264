import type { SystemStyleObject, Theme } from "@mui/system";
import { useCallback } from "react";

const statusColors: Array<{ status: string; color: string; dark?: string }> = [
  { status: "1", color: "#269a26", dark: "#1C741C" },
  { status: "2", color: "#1c5f00", dark: "#154700" },
  { status: "3", color: "#dfdf20", dark: "#A7A718" },
  { status: "4", color: "#bf3400", dark: "#8F2700" },
  { status: "6", color: "#606060" },
  { status: "7", color: "#333333" },
  { status: "8", color: "#333333" },
];

export const statusse = statusColors.map((s) => s.status);

export function useStatusStyles(): (
  status: string,
) => SystemStyleObject<Theme> {
  return useCallback((status) => {
    const colors = statusColors.find((s) => s.status === status) ?? {
      color: "#333333",
      dark: undefined,
    };

    const color = (theme: Theme) =>
      (theme.palette.mode === "dark" ? colors.dark : colors.color) ??
      colors.color;

    return {
      backgroundColor: color,
      color: (theme) => theme.palette.getContrastText(color(theme)),
      "& .MuiAvatar-root": {
        border: (theme) =>
          `1px solid ${theme.palette.getContrastText(color(theme))}`,
      },
    };
  }, []);
}
