import { ListItemIcon, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import ListItemLink from "../../components/ListItemLink";
import { Icon, label, route } from "./config";

export default function StatisticsMenuItem() {
  const { t } = useTranslation();

  return (
    <ListItemLink to={route}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={t(label)} />
    </ListItemLink>
  );
}
