import Layout from "./Layout";
import Loading from "./Loading";
import { useModule, useModuleTranslation } from "./ModuleContext";

export default function ModuleLoader() {
  const module = useModule();
  const { t } = useModuleTranslation();

  return (
    <Layout title={module ? t(module.label) : undefined}>
      <Loading />
    </Layout>
  );
}
