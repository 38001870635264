import L from "leaflet";
import { useEffect } from "react";
import { useMap } from "react-leaflet";
import fadenkreuz from "./fadenkreuz.png";

const icon = L.icon({
  iconUrl: fadenkreuz,
  iconSize: [32, 32],
  iconAnchor: [16, 16],
});

export function Fadenkreuz() {
  const map = useMap();
  useEffect(() => {
    new CenterCross().addTo(map);
  }, [map]);
  return null;
}

const CenterCross = L.Layer.extend({
  marker: null as L.Marker<any> | null,

  addTo(map: L.Map) {
    this.onAdd(map);
    return this;
  },

  onAdd(map: L.Map) {
    this._map = map;
    this._map.addEventListener("move", this.refresh, this);
    this.refresh();
  },

  onRemove(map: L.Map) {
    map.removeEventListener("move", this.refresh, this);
    if (this.marker) {
      map.removeLayer(this.marker);
      this.marker = null;
    }
  },

  refresh() {
    const pos = this._getCenter();
    if (!pos) return;

    if (!this.marker) {
      this.marker = L.marker(pos, {
        icon,
        draggable: false,
        keyboard: false,
        opacity: 0.8,
        zIndexOffset: 0,
      });
      this.marker.addTo(this._map);
    } else {
      this.marker.setLatLng(pos);
    }
  },

  _getCenter() {
    try {
      return this._map.getCenter();
    } catch (e) {
      return undefined;
    }
  },
});
