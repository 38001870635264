import { Badge, ListItemIcon, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import ListItemLink from "../../components/ListItemLink";
import { Icon, label, route } from "./config";
import { aktiveChecklisteSelector } from "./data";

export default function ChecklisteMenuItem() {
  const { t } = useTranslation();
  const aktiveCheckliste = useRecoilValue(aktiveChecklisteSelector);

  return (
    <ListItemLink
      to={`${route}${aktiveCheckliste ? `/${aktiveCheckliste.id}` : ""}`}
    >
      <ListItemIcon>
        <Badge variant="dot" color="secondary" invisible={!aktiveCheckliste}>
          <Icon />
        </Badge>
      </ListItemIcon>
      <ListItemText primary={t(label)} />
    </ListItemLink>
  );
}
