import { useCallback } from "react";
import {
  atom,
  RecoilState,
  selector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { portalServer } from "../../config";
import { FetchProvider, providerValueAtom, useData } from "../../data-provider";
import { limiter } from "../../db/demo";
import { daily, DataProvider, StorageCache } from "../../db/provider";
import { activeMissionAtom } from "../../missions";
import { Ericard, Gefahrgut } from "./types";

const gefahrgutProvider: DataProvider<Gefahrgut[]> = ({ demo }) => ({
  id: "gefahrgut",
  label: "gefahrgut:label",
  provider: new FetchProvider({
    url: `${portalServer}/hazards.json`,
    transform: (data) =>
      limiter<Gefahrgut>(
        demo,
        20,
      )(
        (data.HAZARDLIST || [])
          .filter((entry: any) => parseInt(entry.HAZARD_UN_NUMBER, 10) >= 1000)
          .map(parseGefahrgut),
      ),
  }),
  cache: new StorageCache("gefahrgut"),
  getStatistics: async (value) => ({
    count: value.length,
    totalSize: JSON.stringify(value).length,
  }),
  updateInterval: daily,
});

const ericardsProvider: DataProvider<Ericard[]> = ({ module }) =>
  module.includes("gefahrgut")
    ? {
        id: "ericards",
        label: "gefahrgut:ericard_other",
        provider: new FetchProvider({
          url: `${portalServer}/ericards.json`,
          transform: (data) =>
            (data.ERICARDS || []).map((row: any) => ({
              id: row.ERICARD_NUMBER,
              headline: row.ERICARD_HEADLINE,
              text: (row.ERICARD_ENTRIES || [])
                .map(
                  (entry: any) =>
                    `${entry.ERICARD_ENTRIE_HEADLINE ? "# " : ""}${
                      entry.ERICARD_ENTRIE_TEXT
                    }`,
                )
                .join("\n\n"),
            })),
        }),
        cache: new StorageCache("ericards"),
        getStatistics: async (value) => ({
          count: value.length,
          totalSize: JSON.stringify(value).length,
        }),
        updateInterval: daily,
      }
    : undefined;

export const dataProviders = [gefahrgutProvider, ericardsProvider];

function parseGefahrgut(entry: any, index: number): Gefahrgut {
  return {
    id: (index + 1).toString(),
    un: entry.HAZARD_UN_NUMBER,
    name: entry.HAZARD_NAME,
    gefahrnummer: entry.HAZARD_DANGEROUSID,
    ericardId: entry.HAZARD_ERICARD,
    adrGefahrzettel: entry.HAZARD_ADR_CHEAT,
    adrKlasse: entry.HAZARD_ADR_CLASS,
    klassifizierungscode: entry.HAZARD_CLASSCODE,
  };
}

export function useGefahrgut() {
  return useData<Gefahrgut[]>("gefahrgut", []);
}

export function useEricards() {
  return useData<Ericard[]>("ericards", []);
}

const gefahrgueterAtom = providerValueAtom("gefahrgut") as RecoilState<
  Gefahrgut[]
>;
const ericardsAtom = providerValueAtom("ericards") as RecoilState<Ericard[]>;

const gefahrgutSucheAtom = atom<string>({
  key: "gefahrgut:suche",
  default: "",
});

export function useGefahrgutSuche() {
  return useRecoilState(gefahrgutSucheAtom);
}

const gefahrgutErgebnisseSelector = selector({
  key: "gefahrgut:ergebnisse",
  get: ({ get }) => {
    const suche = get(gefahrgutSucheAtom);
    let gefahrgueter: Gefahrgut[] = get(gefahrgueterAtom) || [];
    if (!suche.trim().length) return gefahrgueter;
    const lowerSearch = suche.trim().toLocaleLowerCase();
    return gefahrgueter.filter(
      (g) =>
        g.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0 ||
        g.un.indexOf(lowerSearch) >= 0,
    );
  },
});

export function useGefahrgutErgebnisse() {
  return useRecoilValue(gefahrgutErgebnisseSelector);
}

const aktivesGefahrgutAtom = atom<Record<string, string | undefined>>({
  key: "gefahrgut:aktiv",
  default: {},
});

const aktivesGefahrgutSelector = selector<
  { gefahrgut: Gefahrgut; ericard?: Ericard } | undefined
>({
  key: "gefahrgut:aktiv:selector",
  get: ({ get }) => {
    const missionId = get(activeMissionAtom)?.missionId ?? "_none_";
    const gefahrgutId = get(aktivesGefahrgutAtom)[missionId];
    if (!gefahrgutId) return;

    const gefahrgueter = get(gefahrgueterAtom);
    const gefahrgut = gefahrgueter.find((g) => g.id === gefahrgutId);
    if (!gefahrgut) return;

    const ericards = get(ericardsAtom);
    const ericard = ericards.find((e) => e.id === gefahrgut.ericardId);

    return { gefahrgut, ericard };
  },
});

export function useAktivesGefahrgut() {
  return useRecoilValue(aktivesGefahrgutSelector);
}

export function useSetAktivesGefahrgut() {
  const missionId = useRecoilValue(activeMissionAtom)?.missionId ?? "_none_";
  const set = useSetRecoilState(aktivesGefahrgutAtom);

  return useCallback(
    (gefahrgutId?: string) => set((s) => ({ ...s, [missionId]: gefahrgutId })),
    [missionId, set],
  );
}
