import {
  array,
  object,
  string,
  voidable,
  type Checker,
} from "@recoiljs/refine";
import { useCallback, useMemo, type ComponentType } from "react";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { syncEffect } from "recoil-sync";
import { databaseStore } from "./db/recoil";
import { useModules } from "./modules";
import { notEmpty } from "./utils";

export type MenuConfiguration = {
  readonly visibleItems?: ReadonlyArray<string>;
};

const menuConfigurationChecker: Checker<MenuConfiguration> = object({
  visibleItems: voidable(array(string())),
});

const menuConfigAtom = atom<MenuConfiguration>({
  key: "menu:config",
  default: {},
  effects: [
    syncEffect({
      refine: menuConfigurationChecker,
      storeKey: databaseStore,
      itemKey: "config/menu-config",
    }),
  ],
});

function insert<T>(items: Array<T>, item: T, index: number): Array<T> {
  return [...items.slice(0, index), item, ...items.slice(index)];
}

export function useShowMenuItem() {
  const set = useSetRecoilState(menuConfigAtom);

  return useCallback(
    async (itemId: string) => {
      set((current) => {
        if (!current.visibleItems) return current;
        return { ...current, visibleItems: [...current.visibleItems, itemId] };
      });
    },
    [set],
  );
}

export function useHideMenuItem() {
  const allItems = useModuleMenuItems().map((item) => item.id);
  const set = useSetRecoilState(menuConfigAtom);

  return useCallback(
    async (itemId: string) => {
      set((current) => {
        const visibleItems = (current.visibleItems ?? allItems).filter(
          (id) => id !== itemId,
        );
        return { ...current, visibleItems };
      });
    },
    [allItems, set],
  );
}

export function useMoveMenuItem() {
  const allItems = useModuleMenuItems().map((item) => item.id);
  const set = useSetRecoilState(menuConfigAtom);

  return useCallback(
    async (itemId: string, targetIndex: number) => {
      set((current) => {
        const currentItems = (current.visibleItems ?? allItems).filter(
          (id) => id !== itemId,
        );
        const visibleItems = insert(currentItems, itemId, targetIndex);
        return { ...current, visibleItems };
      });
    },
    [allItems, set],
  );
}

export type MenuItem = {
  id: string;
  label: string;
  component: ComponentType;
  icon: ComponentType;
};

function useModuleMenuItems(): Array<MenuItem> {
  const modules = useModules();
  return useMemo(
    () =>
      modules
        .map((module) =>
          module.menuItem
            ? {
                id: module.id,
                label: module.label,
                component: module.menuItem,
                icon: module.icon,
              }
            : null,
        )
        .filter(notEmpty),
    [modules],
  );
}

export function useMenuItems(): { visible: MenuItem[]; hidden: MenuItem[] } {
  const items = useModuleMenuItems();
  const { visibleItems } = useRecoilValue(menuConfigAtom);

  if (!visibleItems) return { visible: items, hidden: [] };

  return {
    visible: visibleItems
      .map((id) => items.find((m) => m.id === id))
      .filter(notEmpty),
    hidden: items.filter((m) => !visibleItems.includes(m.id)),
  };
}
