import { Check, Error, MyLocation } from "@mui/icons-material";
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import {
  Field,
  Form,
  Formik,
  useFormikContext,
  type FormikHelpers,
} from "formik";
import { Switch, TextField } from "formik-mui";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { geocodeAddress } from "../data";
import { useCapitalize } from "../i18n";
import { useCreateMission, useUpdateMissionData } from "../missions";
import { type CreateMissionInput, type Mission } from "../missions/api";
import ErrorMessage from "./ErrorMessage";
import Dialog from "./dialog";
import FormActions from "./dialog/FormActions";

export type Props = {
  mission?: Mission;
  handleSubmit?(mission: Mission): void;
  open: boolean;
  handleClose(): void;
};

export default function MissionForm({ open, ...props }: Props) {
  const { t } = useTranslation("mission-form");

  return (
    <Dialog
      maxWidth="md"
      title={t(`${getI18nPrefix(props.mission)}.title`)}
      open={open}
      onClose={props.handleClose}
    >
      <MissionFormContent {...props} />
    </Dialog>
  );
}

type Values = Omit<CreateMissionInput, "coordinates" | "groupIds"> & {
  latitude?: string;
  longitude?: string;
  disableAutoClose: boolean;
};

function transform(
  {
    latitude,
    longitude,
    externalId,
    keyword,
    message,
    address,
    object,
    objectInfo,
    planNo,
    info,
    reporter,
    commander,
    recorder,
    privileges,
    disableAutoClose,
  }: Values,
  update: boolean,
): CreateMissionInput {
  const daten: CreateMissionInput = { message, editable: true };
  daten.source = "app";
  if (externalId) daten.externalId = externalId.trim();
  if (keyword) daten.keyword = keyword.trim();
  if (address) daten.address = address.trim();
  if (object) daten.object = object.trim();
  if (objectInfo) daten.objectInfo = objectInfo.trim();
  if (planNo) daten.planNo = planNo.trim();
  if (info) daten.info = info.trim();
  if (reporter) daten.reporter = reporter.trim();
  if (commander) daten.commander = commander.trim();
  if (recorder) daten.recorder = recorder.trim();
  if (latitude && longitude)
    daten.coordinates = {
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
    };
  if (privileges !== undefined) daten.privileges = privileges;
  if (!update && disableAutoClose) daten.expiresAfter = -1;
  return daten;
}

function MissionFormContent({
  mission,
  handleSubmit,
  handleClose,
}: Omit<Props, "open">) {
  const { t } = useTranslation("mission-form");
  const capitalize = useCapitalize();
  const createMission = useCreateMission();
  const updateMission = useUpdateMissionData();

  const schema = useMemo(
    () =>
      yup.object({
        externalId: yup.string(),
        keyword: yup.string(),
        message: yup.string().required(t("required-field")),
        address: yup.string(),
        object: yup.string(),
        planNo: yup.string(),
        infos: yup.string(),
        reporter: yup.string(),
        commander: yup.string(),
        recorder: yup.string(),
        privileges: yup.bool().required(),
        latitude: yup.number(),
        longitude: yup.number(),
        disableAutoClose: yup.boolean().required(),
      }),
    [t],
  );

  const initialValues: Values = useMemo(
    () => ({
      externalId: mission?.externalId ?? "",
      keyword: mission?.keyword ?? "",
      message: mission?.message ?? "",
      address: mission?.address ?? "",
      object: mission?.object ?? "",
      objectInfo: mission?.objectInfo ?? "",
      planNo: mission?.planNo ?? "",
      info: mission?.info ?? "",
      reporter: mission?.reporter ?? "",
      commander: mission?.commander ?? "",
      recorder: mission?.recorder ?? "",
      privileges: mission?.privileges ?? false,
      latitude: mission?.coordinates?.latitude?.toString() ?? "",
      longitude: mission?.coordinates?.longitude?.toString() ?? "",
      disableAutoClose: (mission?.expiresAfter ?? 1) <= 0,
    }),
    [mission],
  );

  const onSubmit = useCallback(
    async (
      values: Values,
      { setSubmitting, setStatus }: FormikHelpers<Values>,
    ) => {
      setStatus();
      try {
        const data = transform(values, Boolean(mission));
        const theMission = mission
          ? await updateMission(mission.id, data)
          : await createMission(data);
        handleSubmit?.(theMission);
        handleClose();
      } catch (error) {
        setStatus(error);
      }
      setSubmitting(false);
    },
    [mission, createMission, updateMission, handleSubmit, handleClose],
  );

  const i18nPrefix = getI18nPrefix(mission);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, status, values }) => (
        <>
          <DialogContent>
            <Form noValidate id="create-mission">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    label={t(`mission.privileges.${values.privileges}`)}
                    control={
                      <Field
                        name="privileges"
                        component={Switch}
                        type="checkbox"
                      />
                    }
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Field
                    name="keyword"
                    label={t("keyword")}
                    component={TextField}
                    fullWidth
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Field
                    name="message"
                    label={t("mission.field.message")}
                    component={TextField}
                    fullWidth
                    disabled={isSubmitting}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="externalId"
                    label={t("mission.field.externalId")}
                    component={TextField}
                    fullWidth
                    multiline
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Field
                    name="object"
                    label={t("object")}
                    component={TextField}
                    fullWidth
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Field
                    name="planNo"
                    label={t("feuerwehr-plan")}
                    component={TextField}
                    fullWidth
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="objectInfo"
                    label={t("object-details")}
                    component={TextField}
                    fullWidth
                    multiline
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="info"
                    label={t("mission.info")}
                    component={TextField}
                    fullWidth
                    multiline
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="reporter"
                    label={t("mission.reporter")}
                    component={TextField}
                    fullWidth
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="commander"
                    label={t("commander")}
                    component={TextField}
                    fullWidth
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="recorder"
                    label={t("recorder")}
                    component={TextField}
                    fullWidth
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">{t("address")}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="address"
                    label={t("address")}
                    component={TextField}
                    fullWidth
                    disabled={isSubmitting}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <GeolocateButton />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="latitude"
                    label={t("latitude")}
                    component={TextField}
                    fullWidth
                    disabled={isSubmitting}
                    inputProps={{ inputMode: "numeric" }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="longitude"
                    label={t("longitude")}
                    component={TextField}
                    fullWidth
                    disabled={isSubmitting}
                    inputProps={{ inputMode: "numeric" }}
                  />
                </Grid>
                {!mission && (
                  <Grid item xs={12}>
                    <FormControlLabel
                      label={t(`mission.disableAutoClose`)}
                      control={
                        <Field
                          name="disableAutoClose"
                          component={Switch}
                          type="checkbox"
                        />
                      }
                      disabled={isSubmitting}
                    />
                  </Grid>
                )}
                {status && (
                  <Grid item xs={12}>
                    <ErrorMessage error={status} />
                  </Grid>
                )}
              </Grid>
            </Form>
          </DialogContent>
          <DialogActions>
            <FormActions
              form="create-mission"
              onClose={handleClose}
              submitLabel={capitalize(t(`${i18nPrefix}.action`))}
            />
          </DialogActions>
        </>
      )}
    </Formik>
  );
}

type GeolocateState = "initial" | "loading" | "success" | "error";

function GeolocateButton() {
  const [state, setState] = useState<GeolocateState>("initial");
  const {
    values: { address },
    setFieldValue,
    setFieldError,
  } = useFormikContext<Values>();

  const geolocate = useCallback(async () => {
    if (!address) return;
    setState("loading");

    try {
      const { formatted, location } = await geocodeAddress({ address });
      setFieldValue("address", formatted.replace(", Deutschland", ""));
      setFieldValue("latitude", location.latitude);
      setFieldValue("longitude", location.longitude);
      setState("success");
    } catch (error: any) {
      console.error("Error:", error);
      setFieldError("address", error.message);
      setState("error");
    }
  }, [address, setFieldValue, setFieldError]);

  return (
    <IconButton disabled={state === "loading" || !address} onClick={geolocate}>
      <GeolocateStateIcon state={state} />
    </IconButton>
  );
}

function GeolocateStateIcon({ state }: { state: GeolocateState }) {
  switch (state) {
    case "loading":
      return <CircularProgress size="1em" color="inherit" />;
    case "success":
      return <Check color="success" />;
    case "error":
      return <Error color="error" />;
    default:
      return <MyLocation />;
  }
}

function getI18nPrefix(mission?: Mission) {
  return mission ? "edit-mission" : "create-mission";
}
