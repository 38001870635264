import {
  Check as CheckIcon,
  ErrorOutline as ErrorIcon,
} from "@mui/icons-material";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useEffect, type ReactNode } from "react";
import { useTranslation } from "react-i18next";
import GlobalLoading from "../components/GlobalLoading";
import { useDataProviders } from "../data-provider";
import { logger } from "../log";
import { DataProviders } from "./data-providers";

export * from "./db";

/**
 * Configures all data providers, displaying a loading screen until all data is ready.
 */
export function DbProvider({ children }: { children: ReactNode }) {
  return (
    <DataProviders>
      <LoadingScreen>{children}</LoadingScreen>
    </DataProviders>
  );
}

function LoadingScreen({ children }: { children: ReactNode }) {
  const { t } = useTranslation("data");
  const { ready, providers } = useDataProviders();

  useEffect(() => {
    if (ready) logger("data").info("Providers initialized.");
  }, [ready]);

  return ready ? (
    <>{children}</>
  ) : (
    <GlobalLoading>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box>
          {[...providers]
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((cache) => (
              <Typography
                key={cache.id}
                variant="caption"
                component="p"
                color={
                  cache.ready
                    ? "textSecondary"
                    : cache.error
                      ? "error"
                      : undefined
                }
              >
                {cache.ready ? (
                  <CheckIcon fontSize="inherit" />
                ) : cache.error ? (
                  <ErrorIcon fontSize="inherit" />
                ) : (
                  <CircularProgress size="1em" />
                )}{" "}
                {t(cache.label)}
                {cache.error && (
                  <>
                    : <strong>{cache.error.message}</strong>
                  </>
                )}
              </Typography>
            ))}
        </Box>
        {providers.some((p) => p.error) && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => window.location.reload()}
          >
            {t("retry")}
          </Button>
        )}
      </Box>
    </GlobalLoading>
  );
}
