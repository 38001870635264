import { Component, type PropsWithChildren } from "react";
import type { ErrorLike } from "../types";
import GlobalError from "./GlobalError";

type Props = PropsWithChildren<{
  global?: boolean;
}>;

type State = {
  error?: ErrorLike;
};

export default class ErrorBoundary extends Component<Props, State> {
  state: State = {};

  componentDidCatch(error: ErrorLike) {
    this.setState({ error });
  }

  render() {
    return this.state.error ? (
      <GlobalError error={this.state.error} global={this.props.global} />
    ) : (
      this.props.children || null
    );
  }
}
