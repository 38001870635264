import { Point, Util, type Coords, type TileLayerOptions } from "leaflet";
import { useEffect } from "react";
import { useFeatureEnabled } from "../auth";
import { useRegisterTask, type TaskObserver } from "../background";
import { mapTileResourceParams } from "../components/map/tile-layer-cache";
import { precacheResources } from "../db/resource";
import { useLicense } from "../license";
import type { Koordinaten, Polygon } from "../types";

const zoomLevels = range(14, 19);

type TileLayerSpec = { url: string; options: TileLayerOptions };

const tileLayers: TileLayerSpec[] = [
  {
    url: "https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png",
    options: { maxNativeZoom: 19 },
  },
];

export function MapTilePrecache() {
  const license = useLicense();
  const available = useFeatureEnabled("map-precache");
  const registerTask = useRegisterTask();

  useEffect(() => {
    if (available && license && license.offlineArea) {
      registerTask({
        id: "map-tiles",
        label: "Kartenmaterial",
        execute: async (observer: TaskObserver) =>
          license &&
          license.offlineArea &&
          precacheMapTiles(license.offlineArea, observer),
      });
    }
  }, [available, license, registerTask]);

  return null;
}

async function precacheMapTiles(polygon: Polygon, observer: TaskObserver) {
  const urls = getMapTileURLs(polygon);
  await precacheResources(urls, observer, mapTileResourceParams);
}

function getMapTileURLs(polygon: Polygon): ReadonlyArray<string> {
  if (!polygon.length) return [];
  const latitudes = polygon
    .map((p) => p[0])
    .map((p) => (typeof p === "string" ? parseFloat(p) : p));
  const longitudes = polygon
    .map((p) => p[1])
    .map((p) => (typeof p === "string" ? parseFloat(p) : p));
  const nw = {
    latitude: Math.max(...latitudes),
    longitude: Math.min(...longitudes),
  };
  const se = {
    latitude: Math.min(...latitudes),
    longitude: Math.max(...longitudes),
  };
  const coords = zoomLevels.flatMap((zoom) => getPrecacheTiles(nw, se, zoom));
  return Object.values(tileLayers).flatMap(({ url, options }) => {
    const layer = createTileLayer(url, options);
    return coords.map((c) => layer.getTileUrl(c)).filter(Boolean) as string[];
  });
}

function getPrecacheTiles(
  nw: Koordinaten,
  se: Koordinaten,
  zoom: number,
): Coords[] {
  const topleft = coords2tile(nw, zoom);
  const bottomright = coords2tile(se, zoom);
  const xMin = Math.min(topleft.x, bottomright.x);
  const xMax = Math.max(topleft.x, bottomright.x);
  const yMin = Math.min(topleft.y, bottomright.y);
  const yMax = Math.max(topleft.y, bottomright.y);
  return range(xMin, xMax + 1).flatMap((x) =>
    range(yMin, yMax).map((y) => new CoordsPoint(x, y, zoom)),
  );
}

function createTileLayer(urlPattern: string, options: TileLayerOptions) {
  const subdomains = Array.isArray(options.subdomains)
    ? options.subdomains
    : typeof options.subdomains === "string"
      ? [options.subdomains]
      : ["a", "b", "c"];

  return {
    getTileUrl({ x, y, z }: Coords) {
      if (options.maxNativeZoom && z >= options.maxNativeZoom) return null;
      const data = {
        x,
        y,
        z,
        s: subdomains[Math.abs(x + y) % subdomains.length],
      };
      return Util.template(urlPattern, data);
    },
  };
}

function range(min: number, max: number): number[] {
  return Array.from(Array(max - min + 1).keys()).map((index) => index + min);
}

class CoordsPoint extends Point implements Coords {
  constructor(
    x: number,
    y: number,
    public readonly z: number,
  ) {
    super(x, y, false);
  }
}

type PointLike = {
  x: number;
  y: number;
};

function coords2tile(
  { latitude, longitude }: Koordinaten,
  zoom: number,
): PointLike {
  return { x: lng2tile(longitude, zoom), y: lat2tile(latitude, zoom) };
}

function lng2tile(lon: number, zoom: number) {
  return Math.floor(((lon + 180) / 360) * Math.pow(2, zoom));
}

function lat2tile(lat: number, zoom: number) {
  return Math.floor(
    ((1 -
      Math.log(
        Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180),
      ) /
        Math.PI) /
      2) *
      Math.pow(2, zoom),
  );
}
