import { LinearProgress } from "@mui/material";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useMatches } from "react-router";
import Delay from "../../../components/Delay";
import Layout from "../../../components/Layout";
import { id } from "../config";
import Nav, { type TabId } from "./Nav";

export default function StatisticsWrapper() {
  const { t } = useTranslation(id);
  const matches = useMatches();
  const tab = matches[matches.length - 1].pathname.split("/")[2];

  return (
    <Layout
      title={t("label")}
      gutter
      main={{ sx: { display: "flex", flexDirection: "column" } }}
    >
      <Nav tab={tab as TabId} />
      <Suspense
        fallback={
          <Delay>
            <LinearProgress />
          </Delay>
        }
      >
        <Outlet />
      </Suspense>
    </Layout>
  );
}
