import {
  useEffect,
  useState,
  type PropsWithChildren,
  type ReactNode,
} from "react";

export type Props = PropsWithChildren<{
  wait?: number;
  fallback?: ReactNode;
}>;

export default function Delay({
  children,
  wait = 300,
  fallback = null,
}: Props) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), wait);
    return () => clearTimeout(timer);
  }, [wait, setShow]);

  return show ? <>{children}</> : <>{fallback}</>;
}
