import { Update as UpdateIcon } from "@mui/icons-material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAddInfo } from "../data";

export function useCreateUpdateInfo() {
  const addInfo = useAddInfo();
  const { t } = useTranslation("cache");

  return useCallback(
    (onClick: () => void) =>
      addInfo({
        id: "update-prompt",
        level: "warning",
        title: t("update.title"),
        message: t("update.message"),
        icon: UpdateIcon,
        actions: [
          {
            label: t("update.action"),
            variant: "contained",
            color: "secondary",
            onClick,
          },
        ],
      }),
    [addInfo, t],
  );
}
