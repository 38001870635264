import L from "leaflet";
import { useEffect, useMemo } from "react";
import { useMap } from "react-leaflet";
import { useAuthenticated } from "../../auth";
import { apiEndpoint } from "../../config";
import { ShapeMapLayer } from "../../license";
import { shapefileUrl } from "../../staticFiles";

const cw = (window as any).cw;

(L as any).ShapeLayer = L.GeoJSON.extend({
  initialize(file: string, options: any) {
    /*eslint-disable-next-line no-new-func*/
    const fn = new Function(
      "data",
      "cb",
      `importScripts('${shapefileUrl}'); shp(data).then(cb);`,
    );
    this.worker = cw(fn);
    (L.GeoJSON as any).prototype.initialize.call(
      this,
      { features: [] },
      options,
    );
    this.addFileData(file);
  },

  addFileData(file: string) {
    this.worker.data(cw.makeUrl(file)).then(
      (data: any) => {
        this.addData(data);
        this.worker.close();
      },
      (err: any) =>
        console.error(`Fehler beim Laden der Shape-Daten von ${file}:`, err),
    );
  },
});

export type Props = {
  providerId: string;
  layer: ShapeMapLayer;
  color?: string;
  opacity?: number;
  attribution?: string;
};

export default function ShapeLayer({
  providerId,
  layer,
  color,
  opacity,
  attribution,
}: Props) {
  const { id: deviceId } = useAuthenticated();
  const map = useMap();
  const mapLayer = useMemo(() => {
    const ext = layer.url.replace(/^.*(\.[^.]+)$/, "$1");
    const url = `${apiEndpoint}/shape/${deviceId}/${providerId}/${layer.id}${ext}`;
    const options: any = { attribution, opacity };
    if (color) options.style = () => ({ color });
    return new (L as any).ShapeLayer(url, options);
  }, [providerId, layer, deviceId, color, opacity, attribution]);

  useEffect(() => {
    mapLayer.addTo(map);

    return () => {
      map.removeLayer(mapLayer);
    };
  }, [map, mapLayer]);
  return null;
}
