import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useDiagnostics } from "../diagnostics";

export default function SendDiagnosticsButton(props: any) {
  const { t } = useTranslation("settings");
  const diagnostics = useDiagnostics();
  const subject = "rescueTABLET System-Diagnostik";
  const body = btoa(JSON.stringify(diagnostics));
  const url = `mailto:info@rescuetablet.de?subject=${encodeURIComponent(
    subject,
  )}&body=${encodeURIComponent(body)}`;

  return (
    <Button {...props} href={url} target="_blank" rel="noopener noreferrer">
      {t("send-diagnostics")}
    </Button>
  );
}
