import { bool } from "@recoiljs/refine";
import { atom, useRecoilState } from "recoil";
import { syncEffect } from "recoil-sync";
import { databaseStore } from "../../db/recoil";

const drawerState = atom({
  key: "drawer",
  default: false,
  effects: [
    syncEffect({
      refine: bool(),
      storeKey: databaseStore,
      itemKey: "config/drawerOpen",
    }),
  ],
});

export function useDrawerState(): [boolean, (open: boolean) => void] {
  return useRecoilState(drawerState);
}
