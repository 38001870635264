import { type LatLngBounds, type LatLngExpression } from "leaflet";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  type ComponentType,
  type PropsWithChildren,
} from "react";
import { TileLayer } from "react-leaflet";
import { useAuthenticated } from "../../auth";
import { apiEndpoint } from "../../config";
import { useKartenobjekte } from "../../data";
import { useDeviceLocations } from "../../data/locations";
import { useSperrungen } from "../../db/sperrungen";
import {
  useLicense,
  type Modul,
  type ShapeMapLayer,
  type ShapeMapLayerProvider,
  type WMSMapLayer,
  type WMSMapLayerProvider,
} from "../../license";
import { useMeldungen } from "../../modules/meldungen/data";
import { useTrackEvent } from "../../tracking";
import { isVisible, KartenObjekt } from "../../types";
import { CustomWMSLayer } from "./CustomWMSLayer";
import FireboardLayer from "./FireboardLayer";
import { useMapBoundsFilter } from "./hooks";
import ShapeLayer from "./ShapeLayer";
import { WMSLayer } from "./WMSLayer";

export type KartenobjektFilters = {
  options: KartenobjektFilter[];
  selected: KartenobjektFilterType[];
  toggleSelected(type: KartenobjektFilterType): void;
};

export type KartenobjektFilterFn = (
  objekt: KartenObjekt,
  bounds?: LatLngBounds,
) => boolean;

export type KartenobjektFilterType =
  | "hydranten"
  | "objekte"
  | "detailobjekte"
  | "sperrungen"
  | "geraete"
  | "forst"
  | "railway"
  | "sea"
  | "meldungen"
  | "mission-resources"
  | string;

export type MapType = "einsatz" | "karte";

export type KartenobjektFilterGroup = {
  id: string;
  label?: string;
  labelKey?: string;
  visibleMapTypes?: MapType[];
};

const objectsGroup: KartenobjektFilterGroup = {
  id: "_1",
  labelKey: "filterGroup.map-objects",
};
const missionGroup: KartenobjektFilterGroup = {
  id: "_2",
  labelKey: "filterGroup.mission",
};
const layersGroup: KartenobjektFilterGroup = {
  id: "_3",
  labelKey: "filterGroup.layers",
};
const dwdGroup: KartenobjektFilterGroup = {
  id: "_dwd",
  labelKey: "filterGroup.weather",
  visibleMapTypes: ["karte"],
};

export type KartenobjektFilter = {
  type: KartenobjektFilterType;
  group: KartenobjektFilterGroup;
  label?: string;
  labelKey?: string;
  filter?: KartenobjektFilterFn;
  layer?: ComponentType;
  modul?: Modul;
  visibleMapTypes?: MapType[];
};

const builtinFilters: KartenobjektFilter[] = [
  {
    type: "hydranten",
    group: objectsGroup,
    labelKey: "filter.hydrants",
    filter: (objekt) => objekt.typ.startsWith("hydrant"),
  },
  {
    type: "objekte",
    group: objectsGroup,
    labelKey: "filter.objects",
    filter: (objekt) => objekt.typ === "objekt",
  },
  {
    type: "detailobjekte",
    group: objectsGroup,
    labelKey: "filter.detail-objects",
    filter: (objekt) =>
      objekt.typ.startsWith("objekt_") || objekt.typ.startsWith("gefahr_"),
  },
  {
    type: "sperrungen",
    group: objectsGroup,
    labelKey: "filter.blocks",
    filter: (objekt) => objekt.typ === "sperrung",
  },
  {
    type: "geraete",
    group: objectsGroup,
    labelKey: "filter.devices",
    filter: (objekt) => objekt.typ === "device",
  },
  {
    type: "meldungen",
    group: objectsGroup,
    labelKey: "filter.reports",
    filter: (objekt) => objekt.typ === "meldung",
    modul: "meldungenadmin",
  },
  {
    type: "mission-map-elements",
    group: missionGroup,
    labelKey: "filter.mission-map-elements",
  },
  {
    type: "mission-resources",
    group: missionGroup,
    labelKey: "filter.mission-resources",
  },
  {
    type: "openfiremap",
    labelKey: "filter.openfiremap",
    group: layersGroup,
    layer: () => (
      <TileLayer
        url={`${apiEndpoint}/tiles/openfiremap/{z}/{x}/{y}.png`}
        maxNativeZoom={18}
        maxZoom={21}
        tileSize={256}
        attribution='&copy; <a href="https://openfiremap.org">OpenFireMap</a>'
      />
    ),
    visibleMapTypes: ["karte"],
  },
  {
    type: "forst",
    group: layersGroup,
    labelKey: "filter.forest-rescue-points",
    layer: () => (
      <WMSLayer
        url="https://geoserver.giftthaler.de/geoserver/kwf/wms"
        attribution='Forst: &copy; <a href="http://www.rettungspunkte-forst.de/">KWF-Rettungspunkte</a>'
        layers="KWF-Rettungspunkte_mobil"
      />
    ),
    visibleMapTypes: ["karte"],
  },
  {
    type: "railway",
    labelKey: "filter.railway",
    group: layersGroup,
    layer: () => (
      <TileLayer
        url="https://{s}.tiles.openrailwaymap.org/standard/{z}/{x}/{y}.png"
        maxNativeZoom={19}
        maxZoom={21}
        tileSize={256}
        attribution='Eisenbahn: &copy; <a href="https://openrailwaymap.org">OpenRailwayMap</a>'
      />
    ),
    visibleMapTypes: ["karte"],
  },
  {
    type: "sea",
    labelKey: "filter.sea",
    group: layersGroup,
    layer: () => (
      <TileLayer
        url="https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png"
        maxNativeZoom={19}
        maxZoom={21}
        tileSize={256}
        attribution='Seekarte: &copy; <a href="https://openseamap.org">OpenSeaMap</a>'
      />
    ),
    visibleMapTypes: ["karte"],
  },
  {
    type: "dwd_warn",
    group: dwdGroup,
    labelKey: "filter.dwd-warnings",
    layer: () => (
      <WMSLayer
        url="https://maps.dwd.de/geoproxy_warnungen/service/"
        attribution='Wetter: &copy; <a href="https://www.dwd.de">DWD</a>'
        layers="Warnungen_Gemeinden"
      />
    ),
  },
  {
    type: "dwd_niederschlag",
    group: dwdGroup,
    labelKey: "filter.dwd-precipitation",
    layer: () => (
      <WMSLayer
        url="https://maps.dwd.de/geoserver/ows"
        attribution='Wetter: &copy; <a href="https://www.dwd.de">DWD</a>'
        service="wms"
        version="1.3.0"
        layers="dwd:Niederschlagsradar"
      />
    ),
  },
  {
    type: "dwd_blitze",
    group: dwdGroup,
    labelKey: "filter.lightning",
    layer: () => (
      <WMSLayer
        url="https://maps.dwd.de/geoserver/ows"
        attribution='Wetter: &copy; <a href="https://www.dwd.de">DWD</a>'
        service="wms"
        version="1.3.0"
        layers="dwd:Blitzdichte"
      />
    ),
  },
  {
    type: "pegel",
    group: layersGroup,
    labelKey: "filter.gauges",
    layer: () => (
      <WMSLayer
        url="https://www.pegelonline.wsv.de/webservices/gis/wms/aktuell/mnwmhw"
        layers="PegelonlineWMS"
        version="1.3.0"
        attribution='Pegel: &copy; <a href="https://www.pegelonline.wsv.de/">WSV</a>'
      />
    ),
    visibleMapTypes: ["karte"],
  },
  {
    type: "traffic",
    group: layersGroup,
    labelKey: "filter.traffic",
    layer: () => (
      <TileLayer
        url="https://{s}.google.com/vt/lyrs=traffic&amp;x={x}&amp;y={y}&amp;z={z}&amp;style=5"
        subdomains={["mt0", "mt1", "mt2", "mt3"]}
        maxNativeZoom={21}
        maxZoom={21}
        attribution='Verkehr: &copy; <a href="https://maps.google.com">Google Maps</a>'
      />
    ),
  },
];

const defaultSelected: KartenobjektFilterType[] = [
  "hydranten",
  "objekte",
  "detailobjekte",
  "sperrungen",
  "geraete",
  "fireboard:poi",
  "mission-resources",
  "mission-map-elements",
];

function useCustomMapLayerOptions(): KartenobjektFilter[] {
  const license = useLicense();
  if (!license) return [];

  const mapLayers: KartenobjektFilter[] =
    license.mapLayers?.flatMap((layerProvider) => {
      if (layerProvider.type === "wms") {
        return layerProvider.layers.map((layer) => ({
          type: `${layerProvider.id}:${layer.id}`,
          label: layer.label,
          group: {
            id: `custom:${layerProvider.id}`,
            label: layerProvider.label,
            visibleMapTypes: ["karte"],
          },
          layer: createWMSLayerComp(layerProvider, layer),
        }));
      } else if (layerProvider.type === "shape") {
        return layerProvider.layers.map((layer) => ({
          type: `${layerProvider.id}:${layer.id}`,
          label: layer.label,
          group: {
            id: `custom:${layerProvider.id}`,
            label: layerProvider.label,
            visibleMapTypes: ["karte"],
          },
          layer: createShapeLayerComp(layerProvider, layer),
        }));
      } else {
        return [];
      }
    }) ?? [];

  const fireboard: KartenobjektFilter[] = license.fireboardKey
    ? [
        {
          type: "fireboard:poi",
          labelKey: "fireboard.layers.poi.label",
          group: {
            id: "fireboard",
            labelKey: "fireboard.layerGroup.label",
            visibleMapTypes: ["einsatz", "karte"],
          },
          layer: createFireboardLayerComp(license.fireboardKey),
        },
      ]
    : [];

  return [...mapLayers, ...fireboard];
}

function createWMSLayerComp(
  provider: WMSMapLayerProvider,
  layer: WMSMapLayer,
): ComponentType {
  const options = { attribution: layer.attribution, opacity: layer.opacity };
  return () => (
    <CustomWMSLayer provider={provider} layerId={layer.id} options={options} />
  );
}

function createShapeLayerComp(
  provider: ShapeMapLayerProvider,
  layer: ShapeMapLayer,
): ComponentType {
  return () => (
    <ShapeLayer
      providerId={provider.id}
      layer={layer}
      attribution={layer.attribution}
      color={layer.color}
      opacity={layer.opacity}
    />
  );
}

function createFireboardLayerComp(fireboardKey: string): ComponentType {
  return () => <FireboardLayer fireboardKey={fireboardKey} />;
}

function useKartenobjektFilterOptions(mapType: MapType): KartenobjektFilter[] {
  const customFilters = useCustomMapLayerOptions();
  return useMemo(
    () =>
      [...builtinFilters, ...customFilters].filter(
        (l) =>
          (l.group.visibleMapTypes?.includes(mapType) ?? true) &&
          (l.visibleMapTypes?.includes(mapType) ?? true),
      ),
    [customFilters, mapType],
  );
}

const KartenobjektFilterContext = createContext<KartenobjektFilters>({
  options: [],
  selected: [],
  toggleSelected() {},
});

export function KartenobjektFilterProvider({
  selected: initiallySelected = defaultSelected,
  mapType,
  children,
}: PropsWithChildren<{ selected?: string[]; mapType: MapType }>) {
  const { module } = useAuthenticated();
  const filters = useKartenobjektFilterOptions(mapType);
  const [selected, setSelectedTypes] =
    useState<KartenobjektFilterType[]>(initiallySelected);
  const trackEvent = useTrackEvent();

  const toggleSelected: KartenobjektFilters["toggleSelected"] = useCallback(
    (type) => {
      setSelectedTypes((types) => {
        if (types.includes(type)) {
          trackEvent("Select map layer", { label: type, value: 0 });
          return types.filter((t) => t !== type);
        }

        trackEvent("Select map layer", { label: type, value: 1 });
        return [...types, type];
      });
    },
    [trackEvent],
  );

  const context = useMemo(
    () => ({
      options: filters.filter((f) => !f.modul || module.includes(f.modul)),
      selected,
      toggleSelected,
    }),
    [filters, module, selected, toggleSelected],
  );
  return (
    <KartenobjektFilterContext.Provider value={context}>
      {children}
    </KartenobjektFilterContext.Provider>
  );
}

export function useKartenobjektFilter(): KartenobjektFilters {
  return useContext(KartenobjektFilterContext);
}

export function useFilteredKartenobjekte(
  filter?: KartenobjektFilters,
): Array<KartenObjekt> {
  const inBounds = useKartenObjektMapBoundsFilter();
  const filterFn = useMemo(() => {
    if (!filter || !filter.options.length) return () => true;
    const filters = filter.options
      .filter((f) => f.filter && filter.selected.includes(f.type))
      .map((f) => f.filter) as KartenobjektFilterFn[];
    return (objekt: KartenObjekt) =>
      filters.some((f) => f(objekt)) && inBounds(objekt);
  }, [filter, inBounds]);

  const devices = useDeviceLocations();
  const devicesObjekte: KartenObjekt[] = useMemo(
    () =>
      devices.map((device) => ({
        id: device.id,
        typ: "device",
        latitude: device.koordinaten.latitude,
        longitude: device.koordinaten.longitude,
        name: device.name,
      })),
    [devices],
  );

  const kartenObjekte = useKartenobjekte();
  const sperrungen = useSperrungen();

  const meldungen = useMeldungen();
  const meldungenObjekte: KartenObjekt[] = useMemo(
    () =>
      meldungen.map((meldung) => ({
        id: meldung.id,
        typ: "meldung",
        latitude: meldung.latitude,
        longitude: meldung.longitude,
        name: `Meldung von ${meldung.melderName}`,
        beschreibung: meldung.text,
        actions: [
          {
            id: "meldung",
            labelKey: "details",
            to: `/meldungen/${meldung.id}`,
          },
        ],
      })),
    [meldungen],
  );

  const objekte = useMemo(
    () => [
      ...kartenObjekte,
      ...sperrungen.filter(isVisible),
      ...meldungenObjekte,
      ...devicesObjekte,
    ],
    [kartenObjekte, sperrungen, meldungenObjekte, devicesObjekte],
  );

  return useMemo(() => objekte.filter(filterFn), [objekte, filterFn]);
}

function useKartenObjektMapBoundsFilter(): (objekt: KartenObjekt) => boolean {
  const getCandidates = useCallback((objekt: KartenObjekt) => {
    const candidates: Array<LatLngExpression> = [
      [objekt.latitude, objekt.longitude],
    ];
    if (objekt.segments) candidates.push(...objekt.segments);
    if (objekt.polygon) candidates.push(...objekt.polygon);
    return candidates;
  }, []);
  return useMapBoundsFilter(getCandidates);
}
