import { Geolocation } from "@capacitor/geolocation";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  atom,
  selector,
  selectorFamily,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { accuracyValidThreshold } from "../config";
import { ErrorLike, Koordinaten } from "../types";
import { useInterval } from "../utils";

export type LocationContext =
  | { state: "pending" }
  | {
      state: "available";
      koordinaten: Koordinaten;
      accuracy: number;
      updatedAt: number;
    }
  | { state: "error"; error: ErrorLike; updatedAt: number };

export const locationAtom = atom<LocationContext>({
  key: "location",
  default: { state: "pending" },
});

export const currentLocationSelector = selector<{
  location?: Koordinaten;
  accuracy?: number;
  error?: ErrorLike;
  updatedAt?: number;
}>({
  key: "location:current",
  get: ({ get }) => {
    const location = get(locationAtom);
    return location.state === "available"
      ? {
          location: location.koordinaten,
          accuracy: location.accuracy,
          updatedAt: location.updatedAt,
        }
      : location.state === "error"
        ? { error: location.error, updatedAt: location.updatedAt }
        : {};
  },
});

const roundedLocationSelector = selectorFamily<Koordinaten | undefined, number>(
  {
    key: "location:rounded",
    get:
      (precision: number) =>
      ({ get }) => {
        const { location } = get(currentLocationSelector);
        if (!location) return undefined;
        return locationRounder(precision)(location);
      },
  },
);

export function locationRounder(precision: number) {
  const factor = Math.pow(10, precision);
  const round = (value: number) => Math.round(value * factor) / factor;

  return (location: Koordinaten) => ({
    latitude: round(location.latitude),
    longitude: round(location.longitude),
  });
}

function DeviceLocationProvider() {
  const { t } = useTranslation("location");
  const setLocation = useSetRecoilState(locationAtom);
  const lastLocation = useRef<{
    latitude: number;
    longitude: number;
    accuracy: number;
  }>();

  useEffect(() => {
    const watchId = Geolocation.watchPosition(
      { enableHighAccuracy: true },
      (pos, error) => {
        if (error) {
          console.error("Error getting geolocation:", error);
          setLocation({ state: "error", error, updatedAt: Date.now() });
        } else if (!pos) {
          console.warn("No geolocation resolved");
          setLocation({
            state: "error",
            error: new Error(t("error.unresolved")),
            updatedAt: Date.now(),
          });
        } else {
          const latitude = pos.coords.latitude;
          const longitude = pos.coords.longitude;
          const accuracy = pos.coords.accuracy;

          if (
            !lastLocation.current ||
            lastLocation.current.latitude !== latitude ||
            lastLocation.current.longitude !== longitude ||
            lastLocation.current.accuracy !== accuracy
          ) {
            if (accuracy <= accuracyValidThreshold) {
              setLocation({
                state: "available",
                koordinaten: { latitude, longitude },
                accuracy,
                updatedAt: Date.now(),
              });
            } else {
              setLocation({
                state: "error",
                error: new Error(
                  t("error.inaccurate", { accuracy: Math.round(accuracy) }),
                ),
                updatedAt: Date.now(),
              });
            }

            lastLocation.current = { latitude, longitude, accuracy };
          }
        }
      },
    );

    return () => {
      watchId.then((id) => Geolocation.clearWatch({ id }));
    };
  }, [setLocation, t]);

  return null;
}

function MockLocationProvider() {
  const setLocation = useSetRecoilState(locationAtom);
  const setRandomLocation = useCallback(() => {
    setLocation({
      state: "available",
      koordinaten: {
        latitude: 50.213 + Math.random() * 0.001,
        longitude: 9.101 + Math.random() * 0.001,
      },
      accuracy: 100 * Math.random(),
      updatedAt: Date.now(),
    });
  }, [setLocation]);
  useInterval(setRandomLocation, 5000);

  return null;
}

export const LocationProvider =
  import.meta.env.VITE_MOCK_GEOLOCATION === "true"
    ? MockLocationProvider
    : DeviceLocationProvider;

export function useLocation() {
  return useRecoilValue(locationAtom);
}

export function useCurrentLocation(): {
  location?: Koordinaten;
  accuracy?: number;
  error?: ErrorLike;
  updatedAt?: number;
} {
  return useRecoilValue(currentLocationSelector);
}

export function useRoundedLocation(precision: number): Koordinaten | undefined {
  return useRecoilValue(roundedLocationSelector(precision));
}
