export async function scaleImage(
  dataUrl: string,
  options: { width: number; height: number },
): Promise<Blob> {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d")!;
  context.imageSmoothingQuality = "medium";

  const img = await dataToImg(dataUrl);
  const { width, height } = getDimensions({ actual: img, required: options });
  canvas.width = width;
  canvas.height = height;
  context.drawImage(img, 0, 0, width, height);

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (data) =>
        data ? resolve(data) : reject(new Error("Could not resize image")),
      "image/jpeg",
    );
  });
}

function getDimensions({
  actual,
  required,
}: {
  actual: { width: number; height: number };
  required: { width: number; height: number };
}) {
  if (actual.width <= required.width && actual.height >= required.height)
    return actual;
  const ratio = actual.width / actual.height;
  const width = ratio > 0 ? required.width : required.height / ratio;
  const height = ratio < 0 ? required.height : required.width / ratio;
  return { width, height };
}

function dataToImg(dataUrl: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = document.createElement("img");
    img.src = dataUrl;
    img.addEventListener("load", () => resolve(img));
    img.addEventListener("error", reject);
  });
}

export function blobToDataUrl(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result as string));
    reader.addEventListener("error", reject);
    reader.readAsDataURL(blob);
  });
}
