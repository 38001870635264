import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";
import { Box, Drawer, IconButton, Theme, Toolbar } from "@mui/material";
import { Logo, rescueRed } from "@rescuetablet/theme";
import { useCallback } from "react";
import Content from "./Content";
import Header from "./Header";
import MainMenu from "./MainMenu";
import { LayoutProps } from "./common";
import { useDrawerState } from "./drawer";

export default function LargeLayout(props: LayoutProps) {
  const { children } = props;
  const [menuOpen, setMenuOpen] = useDrawerState();
  const toggleMenu = useCallback(
    (target: boolean) => () => setMenuOpen(target),
    [setMenuOpen],
  );

  const drawerWidth = menuOpen
    ? props.drawerWidth
    : (theme: Theme) => `calc(${theme.spacing(7)} + 1px)`;
  const transition = (theme: Theme) =>
    theme.transitions.create(["width", "left"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    });

  return (
    <>
      <Header
        {...props}
        menuOpen={menuOpen}
        toggleMenu={toggleMenu(!menuOpen)}
      />
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          flexShrink: 0,
          whiteSpace: "nowrap",
          transition,
          width: drawerWidth,
          overflowX: "hidden",
          "& .MuiDrawer-paper": {
            transition,
            width: drawerWidth,
            overflowX: "hidden",
          },
        }}
      >
        <Box
          sx={{
            bgcolor: rescueRed[500],
            color: "secondary.contrastText",
            paddingTop: "env(safe-area-inset-top)",
          }}
        >
          <Toolbar
            disableGutters
            sx={{ display: "flex", alignItems: "center" }}
          >
            <IconButton
              color="inherit"
              onClick={toggleMenu(false)}
              size="large"
            >
              <ChevronLeftIcon />
            </IconButton>
            <Box sx={{ pr: 2, display: "flex" }}>
              <Logo width="100%" />
            </Box>
          </Toolbar>
        </Box>
        <MainMenu />
      </Drawer>
      <Box
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          bottom: 0,
          display: "flex",
          flexDirection: "column",
          backgroundColor: "background.default",
          left: drawerWidth,
          transition,
        }}
      >
        <Content>{children}</Content>
      </Box>
    </>
  );
}
