import { nanoid } from "nanoid";
import { useCallback, useEffect, useState } from "react";
import { useRecoilCallback } from "recoil";
import { useAuthenticated } from "../auth";
import { useGeraet } from "../data";
import { ResourceState } from "../db/resource";
import { scaleImage } from "../images";
import { patientAtom } from "../modules/patienten/data";
import { useTrackEvent } from "../tracking";
import type {
  AddResourceInput,
  CreateActionInput,
  CreateMissionInput,
  CreateReportInput,
  MapElementData,
  Mission,
  PatientData,
  Personnel,
  ResourceData,
  SectionData,
  UpdateMissionInput,
} from "./api";
import {
  useAddMission,
  useMissionsClient,
  useRemoveMission,
  useUpdateMissionById,
} from "./firebase";
import { MissionsClient } from "./missions";

export function useCreateMission() {
  const client = useMissionsClient();
  const addMission = useAddMission();
  const trackEvent = useTrackEvent();

  return useCallback(
    async (data: CreateMissionInput) => {
      trackEvent("Create mission");
      const mission = await client.createMission(data);
      addMission(mission);
      return mission;
    },
    [client, trackEvent, addMission],
  );
}

export function useEndMission(missionId: string) {
  const client = useMissionsClient();
  const removeMission = useRemoveMission();
  const trackEvent = useTrackEvent();
  const createMissionReport = useCreateMissionReport(missionId);

  return useCallback(async () => {
    trackEvent("End mission");
    await createMissionReport({ text: "Einsatz manuell geschlossen." });
    await client.endMission(missionId);
    removeMission(missionId);
  }, [trackEvent, client, createMissionReport, missionId, removeMission]);
}

export function useUpdateMissionData() {
  const trackEvent = useTrackEvent();
  const updater = useMissionUpdater();

  return useCallback(
    (id: string, data: UpdateMissionInput) => {
      trackEvent("Update mission");
      return updater(id, (client) => client.updateMission(id, data));
    },
    [updater, trackEvent],
  );
}

export function useUpdateMissionResource(missionId: string) {
  const updater = useMissionUpdater();
  const trackEvent = useTrackEvent();

  return useCallback(
    (
      resourceId: string,
      values: Partial<{
        identifier: string;
        name: string;
        personnel: string;
        personnelCounts: Personnel;
        status: string;
        sections?: Array<string>;
      }>,
    ) => {
      trackEvent("Update mission resource");
      return updater(missionId, (client) =>
        client.updateMissionResource(missionId, resourceId, values),
      );
    },
    [updater, trackEvent, missionId],
  );
}

export function useSetMissionResource(missionId: string) {
  const updater = useMissionUpdater();
  const trackEvent = useTrackEvent();

  return useCallback(
    (resourceId: string, values: ResourceData) => {
      trackEvent("Update mission resource");
      return updater(missionId, (client) =>
        client.setMissionResource(missionId, resourceId, values),
      );
    },
    [updater, trackEvent, missionId],
  );
}

export function useCreateMissionResource(missionId: string) {
  const updater = useMissionUpdater();
  const trackEvent = useTrackEvent();

  return useCallback(
    (values: AddResourceInput) => {
      trackEvent("Create mission resource");
      return updater(missionId, (client) =>
        client.createMissionResource(missionId, values),
      );
    },
    [updater, trackEvent, missionId],
  );
}

export function useRemoveMissionResource(missionId: string) {
  const updater = useMissionUpdater();
  const trackEvent = useTrackEvent();

  return useCallback(
    (resourceId: string) => {
      trackEvent("Remove mission resource");
      return updater(missionId, (client) =>
        client.removeMissionResource(missionId, resourceId),
      );
    },
    [updater, trackEvent, missionId],
  );
}

export function useCreateMissionReport(missionId: string) {
  const updater = useMissionUpdater();
  const trackEvent = useTrackEvent();
  const { name: reporter } = useAuthenticated();

  return useCallback(
    async (
      values: Omit<CreateReportInput, "reporter" | "attachments"> & {
        reporter?: string;
        attachments?: Array<string>;
      },
    ) => {
      return updater(missionId, async (client) => {
        trackEvent("Create mission report");
        trackEvent("Mission report attachments", {
          value: values.attachments?.length ?? 0,
        });

        const attachmentIds = await Promise.all(
          (values.attachments ?? []).map(async (file) => {
            const img = await scaleImage(file, { width: 1024, height: 1024 });
            const { id } = await client.uploadFile(missionId, img);
            return id;
          }),
        );

        return client.createMissionReport(missionId, {
          ...values,
          reporter: values.reporter ?? reporter,
          attachments: attachmentIds,
        });
      });
    },
    [updater, trackEvent, missionId, reporter],
  );
}

export function useCreateMissionAction(missionId: string) {
  const updater = useMissionUpdater();
  const trackEvent = useTrackEvent();

  return useCallback(
    (values: CreateActionInput) => {
      trackEvent("Create mission action");
      return updater(missionId, (client) =>
        client.createMissionAction(missionId, values),
      );
    },
    [updater, trackEvent, missionId],
  );
}

export function useCreateMissionMapElement() {
  const updater = useMissionUpdater();
  const trackEvent = useTrackEvent();

  return useCallback(
    (missionId: string, data: MapElementData) => {
      trackEvent("Create mission map element");
      return updater(missionId, (client) =>
        client.createMissionMapElement(missionId, data),
      );
    },
    [updater, trackEvent],
  );
}

export function useUpdateMissionMapElement() {
  const updater = useMissionUpdater();
  const trackEvent = useTrackEvent();

  return useCallback(
    (missionId: string, id: string, data: MapElementData) => {
      trackEvent("Update mission map element");
      return updater(missionId, (client) =>
        client.updateMissionMapElement(missionId, id, data),
      );
    },
    [updater, trackEvent],
  );
}

export function useDeleteMissionMapElement() {
  const updater = useMissionUpdater();
  const trackEvent = useTrackEvent();

  return useCallback(
    (missionId: string, elementId: string) => {
      trackEvent("Delete mission map element");
      return updater(missionId, (client) =>
        client.deleteMissionMapElement(missionId, elementId),
      );
    },
    [updater, trackEvent],
  );
}

export function useCreateMissionSection(missionId: string) {
  const updater = useMissionUpdater();
  const trackEvent = useTrackEvent();

  return useCallback(
    async (values: SectionData) => {
      trackEvent("Create mission section");
      const id = nanoid();
      await updater(missionId, (client) =>
        client.createSection(missionId, id, values),
      );
      return { id };
    },
    [updater, trackEvent, missionId],
  );
}

export function useUpdateMissionSection(missionId: string) {
  const updater = useMissionUpdater();
  const trackEvent = useTrackEvent();

  return useCallback(
    (id: string, values: SectionData) => {
      trackEvent("Update mission section");
      return updater(missionId, (client) =>
        client.updateSection(missionId, id, values),
      );
    },
    [updater, trackEvent, missionId],
  );
}

export function useDeleteMissionSection(missionId: string) {
  const updater = useMissionUpdater();
  const trackEvent = useTrackEvent();

  return useCallback(
    (id: string) => {
      trackEvent("Delete mission section");
      return updater(missionId, (client) =>
        client.deleteSection(missionId, id),
      );
    },
    [updater, trackEvent, missionId],
  );
}

export function useMissionFile(
  missionId: string,
  fileId: string,
): ResourceState {
  const [state, setState] = useState<ResourceState>({ state: "loading" });
  const client = useMissionsClient();

  useEffect(() => {
    client
      .getFileURL(missionId, fileId)
      .then((data) => setState({ state: "ready", data }))
      .catch((error) => setState({ state: "error", error }));
  }, [client, fileId, missionId]);

  return state;
}

function useMissionUpdater() {
  const client = useMissionsClient();
  const updateMission = useUpdateMissionById();

  return useCallback(
    async (
      missionId: string,
      updater: (client: MissionsClient) => Promise<Mission>,
      setOptimisticResult?: (mission: Mission) => Mission,
    ) => {
      if (setOptimisticResult) {
        updateMission(missionId, setOptimisticResult);
      }

      const mission = await updater(client);
      updateMission(missionId, () => mission);
      return mission;
    },
    [client, updateMission],
  );
}

export function useCreateMissionPatient(missionId: string) {
  const updater = useMissionUpdater();
  const trackEvent = useTrackEvent();

  return useRecoilCallback(
    ({ set }) =>
      async (data: PatientData = {}) => {
        trackEvent("Create mission patient");
        const id = nanoid();
        set(patientAtom({ missionId, patientId: id }), {
          id,
          createdAt: Date.now(),
          updatedAt: Date.now(),
        });
        await updater(missionId, async (client) =>
          client.createPatient(missionId, id, data),
        );
        return { id };
      },
    [updater, trackEvent, missionId],
  );
}

export function useUpdateMissionPatient(missionId: string) {
  const updater = useMissionUpdater();
  const trackEvent = useTrackEvent();
  const { name: reporter } = useGeraet();

  return useRecoilCallback(
    ({ set }) =>
      (id: string, values: PatientData) => {
        trackEvent("Update mission patient");
        set(patientAtom({ missionId, patientId: id }), (current) => ({
          ...current,
          ...values,
        }));
        return updater(
          missionId,
          async (client) =>
            client.updatePatient(missionId, id, { ...values, reporter }),
          (mission) => ({
            ...mission,
            patients: mission.patients.map((p) =>
              p.id === id ? { ...p, ...values } : p,
            ),
          }),
        );
      },
    [updater, trackEvent, missionId, reporter],
  );
}
