import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import { Field, Form, Formik, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { useCallback, useEffect, useId, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { type TaktischesZeichen } from "taktische-zeichen-react";
import * as yup from "yup";
import { useCapitalize } from "../../i18n";
import SubmitButton from "../SubmitButton";
import Dialog from "../dialog";
import TaktischesZeichenGenerator, {
  zeichenInitialValues,
  zeichenSchema,
} from "./TaktischesZeichenGenerator";

export type TaktischesZeichenData = {
  label?: string;
  zeichen: TaktischesZeichen;
};

export type Props = {
  label?: string;
  zeichen?: TaktischesZeichen;
  onSave: (values: TaktischesZeichenData) => Promise<unknown>;
  open: boolean;
  onClose: () => void;
};

const schema = yup.object({
  label: yup.string(),
  zeichen: zeichenSchema.required(),
});

type Values = yup.InferType<typeof schema>;

function useInitialValues(label?: string, zeichen?: TaktischesZeichen): Values {
  return useMemo(
    () => ({
      label: label ?? "",
      zeichen: (zeichen as Values["zeichen"]) ?? zeichenInitialValues,
    }),
    [label, zeichen],
  );
}

export default function TaktischesZeichenDialog({
  label,
  zeichen,
  onSave,
  open,
  onClose,
}: Props) {
  const { t } = useTranslation("karte");
  const capitalize = useCapitalize();
  const prefix = zeichen
    ? "edit-mission-map-element"
    : "add-mission-map-element";
  const initialValues = useInitialValues(label, zeichen);

  const onSubmit = useCallback(
    async ({ label, zeichen }: Values) => {
      await onSave({ label, zeichen: zeichen as TaktischesZeichen });
      onClose();
    },
    [onClose, onSave],
  );

  const formId = useId();

  return (
    <Dialog
      title={t(`${prefix}.title`)}
      open={open}
      onClose={onClose}
      maxWidth="md"
    >
      <Formik
        initialValues={initialValues}
        initialTouched={
          { zeichen: { grundzeichen: true, symbol: true } } as any
        }
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid }) => (
          <>
            <DialogContent>
              <Form id={formId}>
                <Stack spacing={3}>
                  <Typography>{t(`${prefix}.description`)}</Typography>
                  <Field
                    component={TextField}
                    name="label"
                    label={t(`${prefix}.label`)}
                    fullWidth
                  />
                  <TaktischesZeichenGenerator prefix="zeichen" />
                </Stack>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                type="reset"
                variant="text"
                color="grey"
                onClick={onClose}
                disabled={isSubmitting}
              >
                {capitalize(t("close"))}
              </Button>
              <SubmitButton
                type="submit"
                form={formId}
                loading={isSubmitting}
                disabled={!isValid}
              >
                {capitalize(t(`${prefix}.action`))}
              </SubmitButton>
            </DialogActions>
            <Revalidator />
          </>
        )}
      </Formik>
    </Dialog>
  );
}

function Revalidator() {
  const { values, validateForm } = useFormikContext();
  useEffect(() => {
    validateForm(values);
  }, [values, validateForm]);
  return null;
}
