import { portalServer } from "../config";
import { FetchProvider, useData } from "../data-provider";
import { Information } from "../types";
import { limiter } from "./demo";
import { DataProvider, hourly, StorageCache } from "./provider";

const id = "informationen";

export const dataProvider: DataProvider<Information[]> = ({
  demo,
  portalKey,
}) => ({
  id,
  label: `${id}:label`,
  provider: new FetchProvider({
    url: `${portalServer}/get-informations.php?ORG_ACCESSKEY=${encodeURIComponent(
      portalKey,
    )}`,
    transform: (data) =>
      limiter<Information>(
        demo,
        3,
      )((data.INFORMATION_LIST || []).map(parseInformation)),
  }),
  cache: new StorageCache(id),
  getStatistics: async (value) => ({
    count: value.length,
    totalSize: JSON.stringify(value).length,
  }),
  updateInterval: hourly,
});

function parseInformation(i: any): Information {
  return {
    id: i.INFORMATION_ID,
    text: i.INFORMATION_TEXT,
    von: i.INFORMATION_VALID_FROM,
    bis: i.INFORMATION_VALID_TO,
  };
}

export function useInformationen() {
  return useData<Information[]>(id);
}
