import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { Capacitor } from "@capacitor/core";
import { Client } from "@hey-api/client-fetch";
import { initializeApp } from "firebase/app";
import {
  CACHE_SIZE_UNLIMITED,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from "firebase/firestore";
import { atom } from "recoil";
import { asyncEffect } from "../lib/recoil";
import { licenseAtom } from "../license";
import {
  type UserInfo,
  getFirebaseConfig,
  user as getUser,
} from "../missions/api";
import { createMissionsApi } from "../missions/client";
import { log } from "./config";

export type FirebaseContext = {
  apiKey: string;
  user: UserInfo;
};

export const firebaseContextAtom = atom<FirebaseContext>({
  key: "firebase/context",
  effects: [
    asyncEffect(async ({ getPromise, setSelf }) => {
      const license = await getPromise(licenseAtom);
      if (!license) throw new Error("Unauthorized");
      const { missionApiKey } = license;

      if (!missionApiKey) {
        throw new Error(
          "License does not have a mission API key, can't initialize Firestore context.",
        );
      }

      log.info(
        `Establishing Firestore context with API key '${missionApiKey}'...`,
      );

      const context = await connectFirebase(missionApiKey);
      log.info("Firebase context established.");
      setSelf(context);
    }),
  ],
});

async function connectFirebase(apiKey: string): Promise<FirebaseContext> {
  const client = createMissionsApi(apiKey);
  const [user] = await Promise.all([
    loadUser(client),
    initializeFirebase(client),
  ]);
  return { user, apiKey };
}

async function loadUser(client: Client): Promise<UserInfo> {
  const { data, error } = await getUser({ client });
  if (error) throw error;
  return data!;
}

async function initializeFirebase(client: Client): Promise<void> {
  const { data, error } = await getFirebaseConfig({ client });
  if (error) throw error;
  const { config, token } = data!;

  if (Capacitor.isNativePlatform()) {
    log.info("Running on native");
  } else {
    log.info("Running on web");
    const firebase = initializeApp(config, {
      automaticDataCollectionEnabled: false,
    });

    initializeFirestore(firebase, {
      localCache: persistentLocalCache({
        cacheSizeBytes: CACHE_SIZE_UNLIMITED,
        tabManager: persistentMultipleTabManager(),
      }),
    });
  }

  log.info("Signing in to Firebase…");
  await FirebaseAuthentication.signInWithCustomToken({ token });
  log.info("Firebase signed in.");
}
