import React, { useCallback, useEffect } from "react";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { useGeraet } from ".";
import { useAuthenticated } from "../auth";
import { locationsUpdateInterval, portalServer } from "../config";
import { Koordinaten } from "../types";
import { useInterval } from "../utils";

export type DeviceLocation = {
  id: string;
  name: string;
  koordinaten: Koordinaten;
  accuracy: number;
};

const devicesAtom = atom<DeviceLocation[]>({
  key: "devices",
  default: [],
});

export function LocationsProvider() {
  const { positionSehen } = useAuthenticated();
  return positionSehen ? <ActiveLocationsProvider /> : null;
}

function ActiveLocationsProvider() {
  const setDevices = useSetRecoilState(devicesAtom);
  const { id: geraetId } = useGeraet();

  const updateLocations = useCallback(async () => {
    const url = `${portalServer}/get-device-location.php?DEVICE_ID=${encodeURIComponent(
      geraetId,
    )}`;
    const response = await fetch(url);
    if (!response.ok)
      console.error(
        "Error getting device locations: {} {}",
        response.status,
        response.statusText,
      );
    const data = await response.json();
    setDevices(
      (data.DEVICE_LIST || [])
        .filter((d: any) => d.DEVICE_ID !== geraetId)
        .map((d: any) => ({
          id: d.DEVICE_ID,
          name: d.DEVICE_POSITION_NAME,
          koordinaten: {
            latitude: parseFloat(d.DEVICE_POSITION_LAT),
            longitude: parseFloat(d.DEVICE_POSITION_LNG),
          },
          accuracy: parseFloat(d.DEVICE_POSITION_ACCURACY),
        }))
        .filter(
          (d: DeviceLocation) =>
            d.koordinaten.latitude && d.koordinaten.longitude,
        ),
    );
  }, [geraetId, setDevices]);

  useInterval(updateLocations, locationsUpdateInterval);
  useEffect(() => {
    updateLocations();
  }, [updateLocations]);

  return null;
}

export function useDeviceLocations() {
  return useRecoilValue(devicesAtom);
}
