import { lazy, useMemo } from "react";
import { Navigate, type RouteObject } from "react-router";
import ModuleContextProvider from "../../../components/ModuleContext";
import type { ModuleInfo } from "../../types";
import { route } from "../config";

const FormularForm = lazy(() => import("./FormularForm"));
const FormularListe = lazy(() => import("./FormularListe"));

export function useFormulareRoutes(module: ModuleInfo): Array<RouteObject> {
  return useMemo(
    () => [
      {
        path: route,
        element: <ModuleContextProvider module={module} />,
        children: [
          { index: true, element: <Navigate to="draft" /> },
          { path: ":filter", element: <FormularListe /> },
          { path: "f/:id", element: <FormularForm /> },
        ],
      },
    ],
    [module],
  );
}
