import { type TFunction } from "i18next";
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  type Context,
} from "react";
import { useTranslation } from "react-i18next";
import { useNow } from "./components/Now";

export type AnyFn = () => any;

export function useInterval(callback: AnyFn, delay: number) {
  const savedCallback = useRef<AnyFn>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current && savedCallback.current();
    }

    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}

export function useRequiredContext<T>(
  maybeContext: Context<T | undefined>,
  name: string,
): T {
  const context = useContext(maybeContext);
  if (!context) throw new Error(`Missing context: ${name}`);
  return context;
}

export function formatDuration(ms: number, t: TFunction) {
  let remaining = Math.floor(Math.abs(ms) / 1000);

  const weeks = Math.floor(remaining / 60 / 60 / 24 / 7);
  remaining -= weeks * 60 * 60 * 24 * 7;
  const weeksS = weeks ? t("duration:weeks", { count: weeks }) : "";

  const days = Math.floor(remaining / 60 / 60 / 24);
  remaining -= days * 60 * 60 * 24;
  const daysS = days ? t("duration:days", { count: days }) : "";

  const hours = Math.floor(remaining / 60 / 60);
  remaining -= hours * 60 * 60;
  const hoursS = hours ? t("duration:hours", { count: hours }) : "";

  const minutes = Math.floor(remaining / 60);
  const minutesS = minutes ? t("duration:minutes", { count: minutes }) : "";

  const seconds = remaining - minutes * 60;
  const secondsS = seconds ? t("duration:seconds", { count: seconds }) : "";

  if (weeks > 1) return weeksS;
  if (weeks) return `${weeksS} ${daysS}`.trim();

  if (days > 1) return daysS;
  if (days) return `${daysS} ${hoursS}`.trim();

  if (hours > 1) return hoursS;
  if (hours) return `${hoursS} ${minutesS}`.trim();

  if (minutes > 1) return minutesS;
  if (minutes) return `${minutesS} ${secondsS}`.trim();

  return secondsS !== "" ? secondsS : t("duration:now");
}

export function useFormattedDuration() {
  const { t } = useTranslation();
  return useCallback((ms: number) => formatDuration(ms, t), [t]);
}

export function useDuration(target: number) {
  return useFormattedDuration()(useNow().getTime() - target);
}

export function useSameDay(target: number) {
  return new Date(target).toDateString() === useNow().toDateString();
}

export async function getServiceWorker() {
  const sw = await navigator.serviceWorker.getRegistration();
  if (!sw || !sw.active) throw new Error("No service worker registered.");
  return sw.active;
}

function hash(string: string) {
  let val = 0;

  /* eslint-disable no-bitwise */
  for (let i = 0; i < string.length; i += 1) {
    val = string.charCodeAt(i) + ((val << 5) - val);
  }

  return val;
}

/**
 * Create a color based on a string value.
 */
export function stringToColor(
  string: string,
  saturation = "50%",
  lightness = "50%",
) {
  /* eslint-disable no-bitwise */
  const hue = hash(string) & 360;
  return `hsl(${hue},${saturation},${lightness})`;
}

export function notEmpty<T>(item: T | undefined | null): item is T {
  return item !== undefined && item !== null;
}

export function useDownload() {
  return useCallback(
    async (url: string, filename: string) =>
      new Promise<void>((resolve) => {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        link.setAttribute("target", "_blank");
        link.className = "download-js-link";
        link.innerHTML = "downloading...";
        link.style.display = "none";

        const onClick = (e: Event) => {
          e.stopPropagation();
          link.removeEventListener("click", onClick);
        };
        link.addEventListener("click", onClick);

        document.body.appendChild(link);
        setTimeout(() => {
          link.click();
          document.body.removeChild(link);
          resolve();
        }, 66);
      }),
    [],
  );
}
