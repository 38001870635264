import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  type ReactNode,
} from "react";
import { useSetRecoilState } from "recoil";
import { useRequiredContext } from "../utils";
import { TaskInfo } from "./api";
import { tasksAtom } from "./hooks";
import { TaskManager } from "./manager";

const Context = createContext<TaskManager | undefined>(undefined);

export function TaskManagerProvider({ children }: { children: ReactNode }) {
  const manager = useMemo(() => new TaskManager(), []);

  const setTasks = useSetRecoilState(tasksAtom);
  const setTaskInfo = useCallback(
    (task: TaskInfo) => setTasks((tasks) => ({ ...tasks, [task.id]: task })),
    [setTasks],
  );

  useEffect(() => {
    manager.on("task-registered", (task) => setTaskInfo(task));
    manager.on("task-scheduled", (task) => setTaskInfo(task));
    manager.on("task-run-started", (_, task) => setTaskInfo(task));
    manager.on("task-run-finished", (_, task) => setTaskInfo(task));
    manager.on("task-run-updated", (_, task) => setTaskInfo(task));
    manager.on("task-run-canceled", (_, task) => setTaskInfo(task));
  }, [manager, setTaskInfo]);

  return <Context.Provider value={manager}>{children}</Context.Provider>;
}

export function useTaskManager() {
  return useRequiredContext(Context, "TaskManager");
}
