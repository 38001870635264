import { useMemo } from "react";
import type { ModuleInfo, ModuleSpec } from "../types";
import menuItem from "./MenuItem";
import { Icon, id, label, route } from "./config";
import { dataProviders } from "./data";
import { useAtemschutzRoutes } from "./routes";

export * from "./types";

const module: ModuleInfo = {
  id,
  icon: Icon,
  label,
  route,
  trackingContext: "ASÜ",
};

export function useAtemschutzModule(): ModuleSpec {
  const routes = useAtemschutzRoutes(module);

  return useMemo(
    () => ({
      ...module,
      enabled: (license) => license.module.includes(id),
      dataProviders,
      menuItem,
      routes,
      dashboardTiles: [{ label: `${id}:label-menu`, to: route, icon: Icon }],
    }),
    [routes],
  );
}
