import { nanoid } from "nanoid";
import { useCallback, useMemo } from "react";
import {
  atom,
  selector,
  useRecoilValue,
  useSetRecoilState,
  type RecoilState,
} from "recoil";
import { useAuthenticated } from "../../auth";
import { portalServer } from "../../config";
import { useGeraet } from "../../data";
import { FetchProvider, providerValueAtom } from "../../data-provider";
import { StorageCache, storage, type DataProvider } from "../../db/provider";
import { useTrackEvent } from "../../tracking";
import { id, label } from "./config";
import type {
  AtemschutzCache,
  AtemschutzData,
  AtemschutzEinsatz,
  AtemschutzEinsatzkraft,
  AtemschutzPerson,
  AtemschutzRemoteData,
  AtemschutzTrupp,
  AtemschutzTruppMeldung,
  AtemschutzTruppMeldungDaten,
} from "./types";

async function readStorage(): Promise<AtemschutzData> {
  const data = await (await storage).readData(id);
  const values = typeof data === "string" ? JSON.parse(data) : [];
  return Array.isArray(values)
    ? { einsaetze: values, cache: {} }
    : { cache: {}, ...values };
}

async function writeStorage(data: AtemschutzData) {
  await (await storage).writeData(id, JSON.stringify(data));
}

const atemschutzDataProvider: DataProvider<AtemschutzData> = () => ({
  id,
  label: `${label}-local`,
  provider: { getData: readStorage },
  getStatistics: async (value) => ({
    count: value.einsaetze.length,
    totalSize: JSON.stringify(value).length,
  }),
});

const atemschutzRemoteDataProvider: DataProvider<AtemschutzRemoteData> = ({
  portalKey,
}) => ({
  id: `${id}-remote`,
  label: `${label}-remote`,
  provider: new FetchProvider({
    url: `${portalServer}/get-respiration.php?ORG_ACCESSKEY=${encodeURIComponent(
      portalKey,
    )}`,
    transform: (data) => {
      const personen: Array<AtemschutzPerson> = (
        data.RESPIRATION_LIST ?? []
      ).map((entry: any) => ({
        id: `remote:${entry.PERSON_KEY}`,
        internalId: entry.PERSON_INTERNAL_ID,
        name: entry.PERSON_NAME,
        unit: entry.PERSON_UNIT,
        type: "remote",
      }));

      return { personen };
    },
  }),
  cache: new StorageCache(`${id}-remote`),
  getStatistics: async (value) => ({
    count: value.personen.length,
    totalSize: JSON.stringify(value).length,
  }),
});

export const dataProviders = [
  atemschutzDataProvider,
  atemschutzRemoteDataProvider,
];

const dataAtom = providerValueAtom(id) as RecoilState<AtemschutzData>;
const remoteDataAtom = providerValueAtom(
  `${id}-remote`,
) as RecoilState<AtemschutzRemoteData>;

function useSetData() {
  const set = useSetRecoilState(dataAtom);

  return useCallback(
    (updater: (data: AtemschutzData) => AtemschutzData) => {
      set((data) => {
        const updated = updater(data);
        writeStorage(updated);
        return updated;
      });
    },
    [set],
  );
}

const atemschutzEinsaetzeSelector = selector<Array<AtemschutzEinsatz>>({
  key: `${id}-einsaetze`,
  get: ({ get }) => get(dataAtom)?.einsaetze ?? [],
});

const aktiverEinsatzAtom = atom<{ einsatzId?: string; truppId?: string }>({
  key: `${id}-aktiv`,
  default: {},
});

export type AtemschutzEinsatzDaten = Pick<AtemschutzEinsatz, "name">;

export function useAtemschutzEinsaetze() {
  return useRecoilValue(atemschutzEinsaetzeSelector);
}

export function useAktiverAtemschutzEinsatz() {
  return useRecoilValue(aktiverEinsatzAtom);
}

export function useSetAktiverAtemschutzEinsatz() {
  const set = useSetRecoilState(aktiverEinsatzAtom);
  return useCallback(
    (einsatz: { einsatzId?: string; truppId?: string } = {}) => set(einsatz),
    [set],
  );
}

export function useCreateAtemschutzEinsatz() {
  const setData = useSetData();
  const track = useTrackEvent();

  return useCallback(
    async (daten: AtemschutzEinsatzDaten = {}) => {
      const gestartetUm = Date.now();
      track("Überwachung begonnen");

      const einsatz: AtemschutzEinsatz = {
        ...daten,
        id: nanoid(),
        gestartetUm,
        trupps: [],
      };

      setData((data) => ({
        ...data,
        einsaetze: [...(data.einsaetze ?? []), einsatz],
      }));
      return einsatz;
    },
    [setData, track],
  );
}

export type AtemschutzTruppDaten = {
  name: string;
  auftrag: string;
  geschaetzteEinsatzdauerMinuten: number;
  einsatzkraefte: AtemschutzEinsatzkraftDaten[];
};

export type AtemschutzEinsatzkraftDaten = {
  person: AtemschutzPerson;
  druck: number;
};

export function useCreateAtemschutzTrupp(einsatzId: string) {
  const updateEinsatz = useUpdateAtemschutzEinsatz(einsatzId);
  const createMeldung = useCreateAtemschutzTruppMeldung(einsatzId);
  const { addCache } = useAtemschutzCache();
  const track = useTrackEvent();
  const { personen } = useRecoilValue(remoteDataAtom);

  return useCallback(
    async ({ einsatzkraefte, auftrag, ...data }: AtemschutzTruppDaten) => {
      const eks = einsatzkraefte.map((e) => ({
        id: nanoid(),
        name: e.person.name,
        personId: e.person.internalId,
      }));

      const trupp: AtemschutzTrupp = {
        ...data,
        id: nanoid(),
        gestartetUm: Date.now(),
        einsatzkraefte: eks,
        zeiterfassung: "gestoppt",
        meldungen: [],
      };

      await updateEinsatz((data) => {
        track("Trupp erzeugt", { value: data.trupps.length + 1 });
        return { ...data, trupps: [...data.trupps, trupp] };
      });

      await createMeldung(trupp.id, {
        auftrag,
        druecke: Object.fromEntries(
          einsatzkraefte.map((e, i) => [eks[i].id, e.druck]),
        ),
      });

      addCache(
        { type: "truppNamen", value: trupp.name },
        { type: "auftraege", value: auftrag },
        ...trupp.einsatzkraefte
          .filter((e) => !personen.some((p) => p.name === e.name))
          .map(
            (e) => ({ type: "namen", value: e.name }) as AtemschutzCacheEntry,
          ),
      );

      return trupp;
    },
    [updateEinsatz, createMeldung, addCache, track, personen],
  );
}

export function useCreateAtemschutzTruppMeldung(einsatzId: string) {
  const updateEinsatz = useUpdateAtemschutzEinsatz(einsatzId);
  const track = useTrackEvent();

  return useCallback(
    async (
      truppId: string,
      { zeitpunktErfassung, ...data }: AtemschutzTruppMeldungDaten,
    ) => {
      const meldung: AtemschutzTruppMeldung = {
        ...data,
        id: nanoid(),
        zeitpunkt: (zeitpunktErfassung
          ? new Date(zeitpunktErfassung)
          : new Date()
        ).getTime(),
      };

      track("Meldung erfasst");

      if (data.druecke) {
        track("Meldung: Drücke");
        Object.values(data.druecke).map((druck) =>
          track("Meldung: Druck", { value: druck }),
        );
      }

      if (data.auftrag) track("Meldung: Auftrag", { label: data.auftrag });
      if (data.ort) track("Meldung: Ort", { label: data.ort });
      if (data.meldung) track("Meldung: Meldung");
      if (data.mayday === true) track("Meldung: Notfall begonnen");
      if (data.mayday === false) track("Meldung: Notfall beendet");
      if (data.zeiterfassung)
        track(`Meldung: Zeiterfassung ${data.zeiterfassung}`);

      await updateEinsatz((data) => ({
        ...data,
        trupps: data.trupps.map((t) =>
          t.id === truppId ? { ...t, meldungen: [...t.meldungen, meldung] } : t,
        ),
      }));
    },
    [updateEinsatz, track],
  );
}

export function useEntlasseAtemschutzTrupp(einsatzId: string) {
  const updateEinsatz = useUpdateAtemschutzEinsatz(einsatzId);
  const track = useTrackEvent();

  return useCallback(
    async (
      truppId: string,
      daten: {
        einsatzkraefte: Record<
          string,
          Pick<
            AtemschutzEinsatzkraft,
            | "beaufschlagungHitze"
            | "beaufschlagungChemie"
            | "geraetNr"
            | "maskeNr"
          >
        >;
      },
    ) => {
      track("Trupp entlassen");
      await updateEinsatz((data) => ({
        ...data,
        trupps: data.trupps.map((t) =>
          t.id === truppId
            ? {
                ...t,
                entlassenUm: Date.now(),
                einsatzkraefte: t.einsatzkraefte.map((e) => ({
                  ...e,
                  ...daten.einsatzkraefte[e.id],
                })),
              }
            : t,
        ),
      }));
    },
    [updateEinsatz, track],
  );
}

function useUpdateAtemschutzEinsatz(id: string) {
  const setData = useSetData();

  return useCallback(
    (updater: (data: AtemschutzEinsatz) => AtemschutzEinsatz) => {
      setData((data) => ({
        ...data,
        einsaetze: data.einsaetze.map((e) => (e.id === id ? updater(e) : e)),
      }));
    },
    [setData, id],
  );
}

export function useSubmitAtemschutzEinsatz(id: string) {
  const { id: deviceId, name: deviceName, portalKey } = useAuthenticated();
  const setData = useSetData();
  const track = useTrackEvent();
  const setAktiverEinsatz = useSetAktiverAtemschutzEinsatz();

  return useCallback(async () => {
    const { einsaetze } = await readStorage();
    const einsatz = einsaetze.find((e) => e.id === id);

    if (einsatz) {
      track("Überwachung beendet");
      const report = {
        ...einsatz,
        device: { id: deviceId, name: deviceName },
        geschlossenUm: Date.now(),
      };
      const response = await fetch(
        `${portalServer}/atemschutz.php?ORG_ACCESSKEY=${portalKey}`,
        {
          method: "post",
          headers: { "content-type": "application/json" },
          body: JSON.stringify(report),
        },
      );

      if (!response.ok)
        throw new Error(`Fehler ${response.status} ${response.statusText}`);

      setData((data) => ({
        ...data,
        einsaetze: data.einsaetze.filter((e) => e.id !== id),
      }));
      setAktiverEinsatz({});
    }
  }, [id, portalKey, deviceId, deviceName, setData, track, setAktiverEinsatz]);
}

export type AtemschutzCacheEntry = {
  type: keyof AtemschutzCache;
  value: string;
};

export type AtemschutzCacheOperations = {
  addCache: (...entries: Array<AtemschutzCacheEntry>) => void;
};

export function useAtemschutzCache(): AtemschutzCache &
  AtemschutzCacheOperations {
  const { cache } = useRecoilValue(dataAtom);
  const setData = useSetData();

  const addCache = useCallback(
    (...entries: Array<{ type: keyof AtemschutzCache; value: string }>) =>
      setData((data) => ({
        ...data,
        cache: entries
          .filter((e) => e.value.trim())
          .reduce(
            (c, { type, value }) => ({
              ...c,
              [type]: [...(c[type] ?? []), value]
                .sort()
                .filter((v, i, a) => a.indexOf(v) === i),
            }),
            data.cache,
          ),
      })),
    [setData],
  );

  return useMemo(() => ({ ...cache, addCache }), [cache, addCache]);
}

export function useAtemschutzPersonen(): Array<AtemschutzPerson> {
  const { personen: remotePersonen } = useRecoilValue(remoteDataAtom);
  const { namen = [] } = useAtemschutzCache();

  return useMemo(
    () =>
      [
        ...remotePersonen,
        ...namen
          .filter((n) => !remotePersonen.some((p) => p.name === n))
          .map(toLocalPerson),
      ].sort((a, b) => a.name.localeCompare(b.name)),
    [namen, remotePersonen],
  );
}

function toLocalPerson(name: string): AtemschutzPerson {
  return { id: `local:${name}`, name, type: "local" };
}

export function useTruppNameSuggestions() {
  const { name } = useGeraet();
  return [`${name} AT`, `${name} WT`, `${name} ST`];
}
