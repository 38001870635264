import { RecoilState } from "recoil";
import { portalServer } from "../config";
import { FetchProvider, providerValueAtom, useData } from "../data-provider";
import { KartenObjekt, KartenObjektLink, RisikoKlasse } from "../types";
import { limiter } from "./demo";
import { minutely, DataProvider, StorageCache } from "./provider";

const id = "kartenobjekte";

export const dataProvider: DataProvider<KartenObjekt[]> = ({
  demo,
  portalKey,
}) => ({
  id,
  label: "kartenobjekte:objekte",
  provider: new FetchProvider({
    url: `${portalServer}/get-objects-v2.php?ORG_ACCESSKEY=${encodeURIComponent(
      portalKey,
    )}`,
    transform: (data) =>
      limiter<KartenObjekt>(
        demo,
        20,
      )((data.OBJEKT_LIST || []).map(parseKartenObjekt)),
  }),
  cache: new StorageCache(id),
  getStatistics: async (value) => ({
    count: value.length,
    totalSize: JSON.stringify(value).length,
  }),
  updateInterval: 15 * minutely,
});

function parseKartenObjekt(entry: any): KartenObjekt {
  return {
    id: entry.OBJEKT_ID,
    typ: "objekt",
    latitude: parseFloat(entry.OBJEKT_GEO_LAT),
    longitude: parseFloat(entry.OBJEKT_GEO_LNG),
    name: entry.OBJEKT_NAME,
    aliase: entry.OBJEKT_ALIASE || undefined,
    beschreibung: entry.OBJEKT_DESCRIPTION || undefined,
    link: entry.OBJEKT_LINK || undefined,
    adresse: entry.OBJEKT_ADRESSE || undefined,
    einsatzplan: entry.OBJEKT_NR_FWEP || undefined,
    interneNummer: entry.OBJEKT_NR_INTERNAL || undefined,
    risikoKlasse: parseRisikoKlasse(entry.OBJEKT_RISIKO),
    owned: entry.OBJEKT_OWN,
    secondaryLink: parseSecondaryLink(entry),
  };
}

const risikoKlassen: RisikoKlasse[] = ["keine", "niedrig", "mittel", "hoch"];

function parseRisikoKlasse(id?: string): RisikoKlasse | undefined {
  if (!id || id === "0") return undefined;
  return risikoKlassen[parseInt(id, 10)];
}

function parseSecondaryLink(entry: any): KartenObjektLink | undefined {
  const url = entry.OBJEKT_LINK2;
  if (!url) return;

  return { url, label: entry.OBJEKT_LINK2DESC || undefined };
}

export const objekteAtom = providerValueAtom(id) as RecoilState<KartenObjekt[]>;

export function useKartenObjekte() {
  return useData<KartenObjekt[]>(id, []);
}
