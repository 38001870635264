import { type ManagedMission } from "./manager";

export function missionComparator(a: ManagedMission, b: ManagedMission) {
  const aCreatedAt = a.state === "ready" && a.mission.createdAt;
  const bCreatedAt = b.state === "ready" && b.mission.createdAt;

  if (aCreatedAt && bCreatedAt) return bCreatedAt - aCreatedAt;
  if (aCreatedAt) return -1;
  if (bCreatedAt) return 1;
  return a.id.localeCompare(b.id);
}
