import { lazy, useMemo } from "react";
import { type RouteObject } from "react-router";
import ModuleContextProvider from "../../../components/ModuleContext";
import type { ModuleInfo } from "../../types";
import { route } from "../config";

const ObjektDetails = lazy(() => import("./ObjektDetails"));
const ObjektListe = lazy(() => import("./ObjektListe"));

export function useObjekteRoutes(module: ModuleInfo): Array<RouteObject> {
  return useMemo(
    () => [
      {
        path: route,
        element: <ModuleContextProvider module={module} />,
        children: [
          { index: true, element: <ObjektListe /> },
          { path: ":id", element: <ObjektDetails /> },
        ],
      },
    ],
    [module],
  );
}
