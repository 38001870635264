import { lazy, useMemo } from "react";
import { type RouteObject } from "react-router";
import ModuleContextProvider from "../../../components/ModuleContext";
import type { ModuleInfo } from "../../types";
import { route } from "../config";

const DocumentView = lazy(() => import("./DocumentView"));
const FolderView = lazy(() => import("./FolderView"));

export function useDokumenteRoutes(module: ModuleInfo): Array<RouteObject> {
  return useMemo(
    () => [
      {
        path: route,
        element: <ModuleContextProvider module={module} />,
        children: [
          { index: true, element: <FolderView /> },
          { path: "f/:id", element: <FolderView /> },
          { path: "d/:id", element: <DocumentView /> },
        ],
      },
    ],
    [module],
  );
}
