import { RecoilState } from "recoil";
import { portalServer } from "../../config";
import { FetchProvider, providerValueAtom } from "../../data-provider";
import { DataProvider, StorageCache, daily } from "../../db/provider";
import { id, label } from "./config";
import { formularDataDataProvider } from "./formular-data";
import { Formular } from "./types";

const formulareDataProvider: DataProvider<Formular[]> = ({ portalKey }) => ({
  id,
  label,
  provider: new FetchProvider({
    url: `${portalServer}/formulare.php?ORG_ACCESSKEY=${encodeURIComponent(
      portalKey,
    )}`,
  }),
  cache: new StorageCache(id),
  getStatistics: async (value) => ({
    count: value.length,
    totalSize: JSON.stringify(value).length,
  }),
  updateInterval: daily,
});

export const dataProviders = [formulareDataProvider, formularDataDataProvider];

export const formulareAtom = providerValueAtom(id) as RecoilState<Formular[]>;
