import { createClient, type Client } from "@hey-api/client-fetch";
import { missionsServer, platform, version } from "../config";

export function createMissionsApi(apiKey?: string): Client {
  return createClient({
    baseUrl: missionsServer,
    headers: {
      "user-agent": `rescuetablet/${platform}/${version}`,
      ...(apiKey ? { authorization: apiKey } : {}),
    },
  });
}
