import { Box, Drawer, Toolbar } from "@mui/material";
import { useCallback, useState } from "react";
import { type LayoutProps } from "./common";
import Content from "./Content";
import Header from "./Header";
import MainMenu from "./MainMenu";

export default function SmallLayout(props: LayoutProps) {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = useCallback(
    (target: boolean) => () => setMenuOpen(target),
    [],
  );
  const { children } = props;

  return (
    <>
      <Header
        {...props}
        drawerWidth={0}
        menuOpen={false}
        toggleMenu={toggleMenu(!menuOpen)}
      />
      <Drawer
        anchor="left"
        open={menuOpen}
        onClose={toggleMenu(false)}
        sx={{
          ".MuiDrawer-paper": {
            width: props.drawerWidth,
            whiteSpace: "nowrap",
            pt: "env(safe-area-inset-top)",
          },
        }}
      >
        <Toolbar />
        <MainMenu />
      </Drawer>
      <Box
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          bottom: 0,
          left: 0,
          display: "flex",
          flexDirection: "column",
          backgroundColor: "background.default",
        }}
      >
        <Content>{children}</Content>
      </Box>
    </>
  );
}
