import { FirebaseFirestore } from "@capacitor-firebase/firestore";
import { useCallback, useMemo } from "react";
import { atomFamily } from "recoil";
import { logger } from "../../log";
import {
  type FullMission,
  useLoadedMissions,
  useMission,
  useUpdateMissionPatient,
} from "../../missions";
import {
  type Mission,
  type Patient,
  type PatientData,
} from "../../missions/api";
import { id } from "./config";
import { algorithmsDataProvider } from "./sichtung/data";

const log = logger(id);

export const dataProviders = [algorithmsDataProvider];

export type Patients = {
  patients: ReadonlyArray<Patient>;
  active: ReadonlyArray<Patient>;
  inactive: ReadonlyArray<Patient>;
};

export const patientAtom = atomFamily<
  Patient,
  { missionId: string; patientId: string }
>({
  key: `${id}:patient`,
  effects: ({ missionId, patientId }) => [
    ({ setSelf, resetSelf }) => {
      const cleanups: Array<() => void> = [];

      log.info(`Connecting to patient ${patientId} of mission ${missionId}`);

      try {
        FirebaseFirestore.addDocumentSnapshotListener(
          { reference: `missions/${missionId}/patients/${patientId}` },
          (snapshot) => {
            if (snapshot?.snapshot.data) {
              setSelf({
                ...snapshot.snapshot.data,
                id: snapshot.snapshot.id,
              } as Patient);
            } else {
              resetSelf();
            }
          },
        ).then((callbackId) =>
          cleanups.push(() => {
            FirebaseFirestore.removeSnapshotListener({ callbackId });
          }),
        );

        cleanups.push(() => {
          resetSelf();
        });
      } catch {
        resetSelf();
      }

      return () => cleanups.forEach((c) => c());
    },
  ],
});

export type MissionPatient = { mission: FullMission; patient: Patient };

export function useAllPatients(): ReadonlyArray<MissionPatient> {
  const missions = useLoadedMissions();

  return useMemo(
    () =>
      Object.values(missions).flatMap((m) =>
        m.patients.map((patient) => ({ mission: m, patient })),
      ),
    [missions],
  );
}

function useMissionPatients(missionId: string): ReadonlyArray<Patient> {
  const mission = useMission(missionId);
  return mission?.state === "ready" ? mission.mission.patients : [];
}

export function usePatients(missionId: string): Patients {
  const patients = useMissionPatients(missionId);

  return useMemo(() => {
    return {
      patients,
      active: patients.filter((p) => p.status === "active"),
      inactive: patients.filter((p) => p.status === "inactive"),
    };
  }, [patients]);
}

export function useUpdatePatient(mission: Mission, patientId: string) {
  const updateMissionPatient = useUpdateMissionPatient(mission.id);

  return useCallback(
    async (updater: (data: PatientData) => PatientData) => {
      const patient = mission.patients.find((p) => p.id === patientId);
      if (!patient) return;
      const { id, createdAt, updatedAt, ...data } = patient;
      const updated = updater(data);
      await updateMissionPatient(patientId, updated);
    },
    [mission.patients, patientId, updateMissionPatient],
  );
}
