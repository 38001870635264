import { useEffect, useState } from "react";
import { TaskObserver } from "../background";
import { logger } from "../log";
import { ErrorLike } from "../types";
import { notEmpty } from "../utils";
import { getInstance } from "./db";

const log = logger("resource");
const db = getInstance("resources");
const defaultDefaultExpiry = 24 * 60 * 60 * 1000;

export type Resource = {
  readonly url: string;
  readonly data: string;
  readonly createdAt: number;
  readonly expiresAt?: number;
};

export type ResourceState =
  | { readonly state: "loading" }
  | { readonly state: "error"; readonly error: ErrorLike }
  | { readonly state: "ready"; readonly data: string };

export type ResourceParams = {
  readonly cacheKey?: string;
  readonly defaultExpiry?: number;
  readonly overrideExpiry?: number;
  readonly requestOptions?: RequestInit;
};

export function useResource(
  url: string,
  params?: ResourceParams,
): ResourceState {
  const [state, setState] = useState<ResourceState>({ state: "loading" });

  useEffect(() => {
    setState({ state: "loading" });
    getResource(url, params)
      .then((data) => setState({ state: "ready", data: data.data }))
      .catch((error) => setState({ state: "error", error }));
  }, [url, params]);

  return state;
}

export async function getResource(
  url: string,
  params?: ResourceParams,
): Promise<Resource> {
  const cacheKey = params?.cacheKey ?? url;
  const stored = await (await db).readData(cacheKey);

  if (stored) {
    if (isExpired(stored)) {
      // return stale data and update asynchronously
      log.debug(`stale: ${url}`);
      fetchResource(url, params);
    }

    log.debug(`hit: ${url}`);
    return stored;
  }

  log.debug(`miss: ${url}`);
  return fetchResource(url, params);
}

function isExpired(res: Resource) {
  return !res.expiresAt || res.expiresAt < Date.now();
}

async function fetchResource(
  url: string,
  params?: ResourceParams,
): Promise<Resource> {
  const createdAt = Date.now();
  const response = await fetch(url, params?.requestOptions);
  if (!response.ok)
    throw new Error(`${response.status} ${response.statusText}`);

  const expiresAt = getExpiry(response.headers, params);
  log.debug(`store: ${url}, expires at ${new Date(expiresAt).toISOString()}`);

  const resource: Resource = {
    url,
    data: await response.blob().then(blob2base64),
    createdAt,
    expiresAt,
  };

  const cacheKey = params?.cacheKey ?? url;

  try {
    await (await db).writeData(cacheKey, resource);
  } catch (error: unknown) {
    log.error(`Failed to write cache key ${cacheKey}:`, error);
  }

  return resource;
}

function getExpiry(headers: Headers, params?: ResourceParams): number {
  if (params?.overrideExpiry) return Date.now() + params.overrideExpiry;

  const expires = headers.get("expires");
  if (expires) return new Date(expires).getTime();

  const cacheControl = headers.get("cache-control");
  if (cacheControl) {
    const cacheControlExpiry = getCacheControlExpiry(cacheControl);
    if (cacheControlExpiry) return cacheControlExpiry;
  }

  return Date.now() + (params?.defaultExpiry ?? defaultDefaultExpiry);
}

function getCacheControlExpiry(value: string): number | undefined {
  const match = value.match(/max-age=(\d+)/);
  if (match) return Date.now() + parseInt(match[1], 10) * 1000;
}

export type ResourceStatistics = {
  count: number;
};

export async function getResourceStatistics(): Promise<ResourceStatistics> {
  const d = await db;
  const files = await d.listFiles();
  return { count: files.length };
}

async function blob2base64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result as string));
    reader.addEventListener("error", reject);
    reader.readAsDataURL(blob);
  });
}

export async function clearResources() {
  await (await db).clear();
}

export async function precacheResources(
  urls: ReadonlyArray<string>,
  observer: TaskObserver,
  params?: ResourceParams,
) {
  const expiredUrls = await getExpiredResources(urls);
  observer.setWork(expiredUrls.length);
  for (const url of expiredUrls) {
    try {
      if (observer.canceled) return;
      await getResource(url, params);
      observer.onSuccess();
    } catch (error: any) {
      observer.onFailed(error);
    }
  }
}

async function getExpiredResources(
  urls: ReadonlyArray<string>,
): Promise<ReadonlyArray<string>> {
  const expired: Array<string | null> = await Promise.all(
    urls.map(async (url) => {
      const stored = await (await db).readData(url);
      if (!stored) return url;
      if (isExpired(stored)) return url;
      return null;
    }),
  );

  return expired.filter(notEmpty);
}
