import { useCallback } from "react";
import {
  atom,
  selector,
  useRecoilValue,
  useSetRecoilState,
  type RecoilState,
} from "recoil";
import { portalServer } from "../../config";
import { FetchProvider, providerValueAtom, useData } from "../../data-provider";
import { daily, DataProvider, StorageCache } from "../../db/provider";
import { id, label } from "./config";
import type { Document, File, Folder } from "./types";

const dataProvider: DataProvider<File[]> = ({ module, portalKey }) =>
  module.includes("dokumente")
    ? {
        id,
        label,
        provider: new FetchProvider({
          url: `${portalServer}/get-documents.php?ORG_ACCESSKEY=${encodeURIComponent(
            portalKey,
          )}`,
          transform: (data) => {
            const folders: Folder[] = (data.FOLDER_LIST || []).map(
              (folder: any) => ({
                id: folder.FOLDER_KEY,
                type: "folder",
                name: folder.FOLDER_NAME,
                parentFolderId: folder.FOLDER_PARENT || "",
              }),
            );

            const files: Document[] = (data.FILE_LIST || []).map(
              (file: any) => ({
                id: file.FILE_ID,
                type: "file",
                name: file.FILE_NAME,
                parentFolderId: file.FILE_FOLDER,
                parentFolder: folders.find((f) => f.id === file.FILE_FOLDER),
                url: `${portalServer}/get-file.php?ORG_ACCESSKEY=${portalKey}&FILE_ACCESSKEY=${file.FILE_PATH}`,
                fileType: file.FILE_TYP,
                fileSize: parseInt(file.FILE_SIZE, 10),
              }),
            );

            folders.forEach((folder) => {
              if (folder.parentFolderId)
                folder.parentFolder = folders.find(
                  (f) => f.id === folder.parentFolderId,
                );
            });

            return [...folders, ...files];
          },
        }),
        cache: new StorageCache(id),
        getStatistics: async (value) => ({
          count: value.length,
          totalSize: JSON.stringify(value).length,
        }),
        updateInterval: daily,
      }
    : undefined;

export const dataProviders = [dataProvider];

export function useDokumente() {
  return useData<File[]>(id, []);
}

export type ActiveDocumentId = Pick<File, "type" | "id">;

const activeDocumentAtom = atom<ActiveDocumentId | undefined>({
  key: `${id}:active`,
  default: undefined,
});

const documentsAtom = providerValueAtom(id) as RecoilState<Array<File>>;

const activeDocumentSelector = selector<File | undefined>({
  key: `${id}:active:selector`,
  get: ({ get }) => {
    const active = get(activeDocumentAtom);
    if (!active) return;

    const documents = get(documentsAtom);
    return documents.find((d) => d.type === active.type && d.id === active.id);
  },
});

export function useActiveDocument() {
  return useRecoilValue(activeDocumentSelector);
}

export function useSetActiveDocument() {
  const set = useSetRecoilState(activeDocumentAtom);
  return useCallback(
    (doc: ActiveDocumentId | undefined) =>
      set(doc ? { id: doc.id, type: doc.type } : undefined),
    [set],
  );
}
